// (C) Copyright 2020 MediaWink, LLC

import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function pretty(string) {
  return capitalizeFirstLetter(string.toLowerCase());
}

function InputTextFieldSelect({ value, options, onChange }) {
  return (
    <Box
      component='form'
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate={true}
      autoComplete='off'
    >
      <div>
        <TextField
          id='outlined-select-currency-native'
          select={true}
          onChange={onChange}
          label={pretty('SELECTION')}
          defaultValue={value}
          SelectProps={{
            native: true,
          }}
          helperText='Please make a selection'
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      </div>
    </Box>
  );
}

InputTextFieldSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
};

InputTextFieldSelect.defaultProps = {
  value: '',
  options: [],
};

export default InputTextFieldSelect;
