// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import {
  selectPage,
} from '../selectors/items';
import {
  setPrivateBreadcrumbs,
  triggerSetShowViewSettingsSaga,
} from '../actions/items';

function TourJoyride({
  context,
  setContext,
}) {
  const navigate = useNavigate();
  const page = useSelector((state) => selectPage(state));
  const dispatch = useDispatch();
  const setShowViewSettings = (data) => dispatch(
    triggerSetShowViewSettingsSaga(data),
  );
  const setBreadcrumbsCall = (data) => dispatch(
    setPrivateBreadcrumbs(data),
  );
  const handleJoyrideCallback = (data) => {
    const {
      action, index, status, type,
    } = data;
    // console.log('joyride callback data', data);
    // console.log('conetxt', context);

    try {
      if (type === EVENTS.STEP_BEFORE) {
        const { target } = data.step;
        if (target === '.home') {
          navigate('/');
        } else if (!target.startsWith('.tour-')) {
          navigate(target.slice(1));
        }

        // Setup
        if (data.index === 0) {
          setShowViewSettings({ id: page.id, showViewSettings: false });
          setBreadcrumbsCall([1]);
        }
        if (context.tour === 'homeschool') {
          if (data.index === 2) {
            setBreadcrumbsCall([1, 2]);
          } else if (data.index === 3) {
            setBreadcrumbsCall([1, 2, 13]);
          } else if (data.index === 4) {
            setBreadcrumbsCall([1, 2, 13, 14]);
          } else if (data.index === 5) {
            setBreadcrumbsCall([1, 2]);
          } else if (data.index === 6) {
            setBreadcrumbsCall([1, 2, 3]);
          }
        }
      } else if (type === EVENTS.TOUR_START) {
        if (context.tour === 'basic') {
          setContext({
            ...context,
            originalHideViewSettings: page.showViewSettings,
          });
        }
      }
    } catch (e) {
      // console.log(e);
    }

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setContext({ ...context, stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      if ([STATUS.FINISHED].includes(status)) {
        if (context.tour === 'basic') {
          // console.log('restore showViewSettings', context.originalHideViewSettings);
          setShowViewSettings({ id: page.id, showViewSettings: context.originalHideViewSettings });
          setBreadcrumbsCall([1]);
        }
      }
      // Need to set our running state to false, so we can restart if we click start again.
      setContext({ ...context, run: false });
    }
  };
  const { run, stepIndex, steps } = context;
  return (
    <Joyride
      callback={handleJoyrideCallback}
      continuous={true}
      debug={false}
      showProgress={true}
      showSkipButton={true}
      run={run}
      stepIndex={stepIndex}
      steps={steps}
    />
  );
}

TourJoyride.propTypes = {
  context: PropTypes.object.isRequired,
  setContext: PropTypes.func.isRequired,
};

export default TourJoyride;
