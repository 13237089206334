// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Box,
  Typography,
} from '@material-ui/core';
import ItemListWithAdd from './ItemListWithAdd';
import {
  VisibilityFilters,
} from '../actions/items';
import {
  selectItemsInGraphArray,
  selectPrivateItems,
  selectVisibilityFilter,
} from '../selectors/items';

const strMapToObj = (strMap) => {
  const obj = Object.create(null);
  strMap.forEach(([k, v]) => {
    // We don’t escape the key '__proto__'
    // which can cause problems on older engines
    obj[k] = v;
  });
  return obj;
};

const getVisibleItems = (items, filter) => {
  // console.log("items", items);
  // console.log("Object.entries", Object.entries(items));
  switch (filter) {
    case VisibilityFilters.SHOW_ALL:
      return items;
    case VisibilityFilters.SHOW_COMPLETED:
      return strMapToObj(Object.entries(items).filter((t) => t[1].completed));
    case VisibilityFilters.SHOW_ACTIVE:
      return strMapToObj(Object.entries(items).filter((t) => !t[1].completed));
    default:
      throw new Error(`Unknown filter: ${filter}`);
  }
};

function ItemListsTable({
  debug,
  hideAddBottom,
  hideAddTop,
  hideDelete,
  hideEdit,
  hideCheckmark,
}) {
  const allItems = useSelector((state) => state.undo.present.private.entities.items);
  const privateItems = useSelector((state) => selectItemsInGraphArray(state)
    .map((i) => selectPrivateItems(state)[i])
    .reduce((acc, cur) => {
      if (cur) {
        acc[cur.id] = cur;
      }
      return acc;
    }, {}));
  // TODO: Add public items
  /*
  const publicItems = useSelector(state => selectItemsInGraphArray(state)
    .map(i => selectPublicItems(state)[i])
    .reduce((acc, cur) => {
      if (cur) {
        acc[cur.id] = cur;
      }
      return acc;
    }, {}));
  */
  const visibilityFilter = useSelector((state) => selectVisibilityFilter(state));
  return (
    <Box display='flex' flexDirection='row' className='tour-table'>
      {privateItems ? Object.entries(privateItems).sort((a, b) => (
        (a[1].order > b[1].order) ? 1 : -1)).map(([key, value]) => (
          <Box
            key={key}
          >
            <Typography><strong>{value.Name.value}</strong></Typography>
            <ItemListWithAdd
              debug={debug}
              item={value}
              publicItems={{}}
              privateItems={getVisibleItems(
                (value.items || [])
                  .map((i) => allItems[i])
                  .reduce((acc, cur) => {
                    if (cur) {
                      acc[cur.id] = cur;
                    }
                    return acc;
                  }, {}),
                visibilityFilter,
              )}
              hideAddBottom={hideAddBottom}
              hideAddTop={hideAddTop}
              hideDelete={hideDelete}
              hideEdit={hideEdit}
              hideCheckmark={hideCheckmark}
            />
          </Box>
      )) : undefined}
    </Box>
  );
}

ItemListsTable.propTypes = {
  debug: PropTypes.bool.isRequired,
  hideAddBottom: PropTypes.bool,
  hideAddTop: PropTypes.bool,
  hideDelete: PropTypes.bool,
  hideEdit: PropTypes.bool,
  hideCheckmark: PropTypes.bool,
};

ItemListsTable.defaultProps = {
  hideAddBottom: false,
  hideAddTop: false,
  hideDelete: false,
  hideEdit: false,
  hideCheckmark: false,
};

export default ItemListsTable;
