// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import PropTypes from 'prop-types';
import { usePromiseTracker } from 'react-promise-tracker';
import {
  Box,
  Modal,
} from '@material-ui/core';

import './spinner.css';

function Spinner(props) {
  const { text } = props;
  const tracker = usePromiseTracker();
  const { promiseInProgress } = tracker;
  // console.log('Spinner props', props);
  // console.log('Spinner tracker', tracker);
  return (
    promiseInProgress && (
      <Modal
        open={true}
        onClose={() => {}}
        style={{
          backgroundColor: 'white',
        }}
      >
        <Box
          align='center'
          width='100%'
          height='100%'
          display='flex'
          flexDirection='column'
        >
          <Box className='spinner'>
            {/*
              <Loader type="Audio" color="#2BAD60" height="100" width="100" />
            */ }
            <img
              alt='Loading'
              src='android-chrome-192x192.png'
              width='300'
              height='300'
            />
          </Box>
          {text}
        </Box>
      </Modal>
    )
  );
}

Spinner.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Spinner;
