// (C) Copyright 2020 MediaWink, LLC

import {
  ADD_DIRTY,
  DELETE_DIRTY,
  SET_DIRTY,
} from '../action-types';

const dirty = (state = null, action = null) => {
  switch (action.type) {
    // Do not add IMPORT_JSON--dirty should not be saved
    case ADD_DIRTY:
      return state.add(action.item);
    case DELETE_DIRTY:
      state.delete(action.item);
      return state;
    case SET_DIRTY:
      return action.data;
    default:
      return state;
  }
};

export default dirty;
