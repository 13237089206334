// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import PropTypes from 'prop-types';
import Image from './Image';
// import ListItem from './ListItem.jsx';

function List({ list }) {
  const l2 = list.map(
    (listItem) => {
      const [k2, v2] = listItem.Name.value.split('=');
      if (v2.startsWith('http')) {
        return (
          <div key={listItem.id}>
            <b>
              {k2}
              :
            </b>
            <Image
              key={listItem.id}
              width={200}
              height={200}
              scale={5.0}
              src={v2}
              alt='ALT TEXT'
              referrerPolicy='no-referrer'
            />
          </div>
        );
      }
      return (
        <li key={listItem.id}>
          <b>{k2}</b>
          :
          {' '}
          {v2}
        </li>
      );
      // return (<li key={listItem.id}>{listItem.Name.value}</li>);
    }
    ,
  );
  return l2;
}

List.propTypes = {
  list: PropTypes.array.isRequired,
};

export default List;
