// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import PropTypes from 'prop-types';

function Image({
  src,
  // width,
  // height,
  scale = 1.0,
}) {
  const img = src.includes('?') ? src?.split('?').slice(0)[0] : src;
  // console.log('Image width, height', width, height);
  const queryParams = new URLSearchParams(src.split('?')[1]);
  const urlHeight = Math.floor(queryParams.get('height') || 0);
  const urlWidth = Math.floor(queryParams.get('width') || 0);
  const offsetTop = queryParams.get('offsetTop');
  const offsetRight = queryParams.get('offsetRight');
  const offsetBottom = queryParams.get('offsetBottom');
  const offsetLeft = queryParams.get('offsetLeft');
  let offsetTopX;
  let offsetRightX;
  let offsetBottomX;
  let offsetLeftX;
  let originalHeight = 0;
  let originalWidth;
  let top;
  let bottom;
  let right;
  let left;
  let widthX;
  let heightX;
  let originalHeightStr;
  let originalWidthStr;
  let val = '';
  let multiplier = 1;
  for (;originalHeight < urlHeight * scale; multiplier += 1) {
    heightX = multiplier * urlHeight;
    offsetTopX = heightX * offsetTop;
    offsetBottomX = heightX * offsetBottom;
    top = offsetTopX < 0 ? 0 : offsetTopX;
    bottom = offsetBottomX < 0 ? 0 : offsetBottomX;
    originalHeight = heightX > 0 ? heightX - offsetTopX - offsetBottomX : null;
    originalHeightStr = heightX > 0 ? `${heightX - offsetTopX - offsetBottomX}px` : null;
    originalWidthStr = widthX > 0 ? `${widthX - offsetRightX - offsetLeftX}px` : null;
  }
  // console.log('=width adjust:');
  multiplier = 1;
  originalWidth = 0;
  for (;originalWidth < urlWidth * scale; multiplier += 1) {
    widthX = multiplier * urlWidth;
    offsetRightX = widthX * offsetRight;
    offsetLeftX = widthX * offsetLeft;
    right = offsetRightX < 0 ? 0 : offsetRightX;
    left = offsetLeftX < 0 ? 0 : offsetLeftX;
    originalWidth = widthX > 0 ? widthX - offsetRightX - offsetLeftX : null;
    originalHeightStr = heightX > 0 ? `${heightX - offsetTopX - offsetBottomX}px` : null;
    originalWidthStr = widthX > 0 ? `${widthX - offsetRightX - offsetLeftX}px` : null;
  }
  val = [...val, (
    <div key={`Image-${src}`}>
      <p
        style={{
          height: originalHeightStr || null,
          width: originalWidthStr || null,
          margin: '.5em 10px .5em 0',
          overflow: 'hidden',
          // border: '1px solid #ccc',
        }}
      >
        <img
          width={widthX > 0 ? widthX : null}
          height={heightX > 0 ? heightX : `${72 * scale}px`}
          src={img}
          alt='1'
          referrerPolicy='no-referrer'
          style={{
            'margin': `-${top}px -${right}px -${bottom}px -${left}px`,
          }}
        />
      </p>
    </div>
  )];

  return val;
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
};
export default Image;
