// (C) Copyright 2020 MediaWink, LLC

import { createSelector } from 'reselect';
import { selectPrivateItems } from './items';

// Given an item, return a suggested name for a new sub-item
export const selectSuggestedNewNameForItem = (state, item) => createSelector(
  selectPrivateItems,
  (items) => {
    try {
      // console.log('selectSuggestedNewNameForItem item', item);
      const lastTwoItems = item.items.map((i) => items[i]).filter((x) => x !== undefined).slice(-2);
      // console.log('lastTwoItems', lastTwoItems);
      if (lastTwoItems.length !== 2) {
        return '';
      }
      const listItems = item.items.map((i) => items[i]);
      // Weekends in list?  Weekdays?
      let weekends = false;
      listItems.filter((x) => x !== undefined).map((i) => {
        const name = i?.Name?.value;
        if (!name) {
          return '';
        }
        if (name?.includes('Sat') || name?.includes('Sun')) {
          weekends = true;
        }
        return false;
      });

      const names = lastTwoItems.map((i) => i?.Name?.value);
      if (!names) {
        return '';
      }
      // console.log('names', names);

      // Compare last two entries
      // Break them into words
      const nameWords = [];
      nameWords[0] = names[0]?.split(' ');
      nameWords[1] = names[1]?.split(' ');
      // console.log('nameWords', nameWords);

      let newName = names[1];
      const oldName = newName;
      // console.log('newname', newName);
      let skippedDays = 0;
      let i = 0;
      const out = [];
      nameWords[1]?.forEach((word1) => {
        let done = false;
        const word0 = nameWords[0][i];
        if (!word0) {
          return;
        }
        // console.log('word0', word0);
        // console.log('word1', word1);
        out[i] = word1;

        if (!done) {
          let DAYS;
          if (word1.length === 3) {
            const WEEKENDS = weekends ? ['Sat', 'Sun'] : [];
            DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', ...WEEKENDS];
          } else {
            const WEEKENDS = weekends ? ['Saturday', 'Sunday'] : [];
            DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', ...WEEKENDS];
          }
          // console.log('DAYS', DAYS);
          DAYS.map((day, d) => {
            if (word1.includes(day)) {
              // console.log('contains a day of the week');
              const newDay = DAYS[d === DAYS.length - 1 ? 0 : d + 1];
              // console.log('day', day);
              // console.log('newDay', newDay);
              // console.log('newname before', newName);
              // newName = newName.replace(day, newDay);
              out[i] = newDay;
              done = true;
              // console.log('newname after', newName);
              if (day.includes('Fri') && newDay.includes('Mon')) {
                skippedDays = 2;
                // console.log('skippedDays',skippedDays);
              }
              // TODO: Add check--is word0's expected day in word0??
            }
            return false;
          });
        }

        if (!done) {
          // *** Dates (e.g. 12/25/2020)
          // const days =
          //   ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturnday', 'Sunday'];
          // const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
          // console.log('word1', word1);
          const d = Date.parse(word1);
          const dateString = new Date(d).toLocaleDateString();
          // console.log('dateString',dateString);
          if (word1 === dateString) {
            // console.log('contains a date');
            const newDate = new Date(d);
            // console.log('newDate', newDate);
            newDate.setDate(newDate.getDate() + 1 + skippedDays);
            const newDateString = newDate.toLocaleDateString();
            // console.log('newDateString', newDateString);
            newName = newName.replace(word1, newDateString);
            out[i] = newDateString;
            done = true;
            // console.log('newName', newName);
          }
        }

        if (!done) {
          // *** Numbers (e.g. (2 or 2.3))
          // https://stackoverflow.com/a/10003709/11962478
          // parseFloat: https://stackoverflow.com/a/28895056/11962478
          const nums = [word0, word1].map((n) => {
            // const m = n[i].match(/\d+/);
            const m = n.match(/[\d.]+/);
            if (m) {
              return parseFloat(m[0]);
            }
            return '';
          });
          const newNum = nums[1] + nums[1] - nums[0];
          if (newNum > nums[1]) {
            // Fix Float: https://stackoverflow.com/a/11832950/11962478
            // e.g. 1.2000000001 => 1.2
            const fixedFloat = Math.round((newNum + Number.EPSILON) * 100) / 100;
            out[i] = word1.replace(nums[1], fixedFloat);
            done = true;
          }
        }

        // console.log('out', out);
        i += 1;
      });

      newName = out.join(' ');
      const ret = newName === oldName ? '' : newName;
      // console.log('ret', ret);
      return ret;
    } catch (e) {
      console.warn(e);
      return '';
    }
  },
)(state);
