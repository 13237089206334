// (C) Copyright 2020 MediaWink, LLC

import {
  IMPORT_JSON,
} from '../action-types';

const version = (state = 1, action = null) => {
  // console.log('action', action);
  const json = action.json ? JSON.parse(action.json).private : undefined;
  switch (action.type) {
    case IMPORT_JSON:
      // console.log('version json', json);
      return json.version ? json.version : null;
    default:
      return state;
  }
};

export default version;
