// (C) Copyright 2020 MediaWink, LLC

import { produce } from 'immer';
import {
  LOGIN,
  LOGOUT,
} from '../action-types/session';

const login = (state = {}, action = null) => {
  switch (action.type) {
    // DO NOT add IMPORT_JSON as this changes who is logged in when someone imports a file
    case LOGIN:
      return {
        ...state,
        ...action.login,
        isLoggedIn: true,
        cache: {
          ...state.cache,
          [action.login.account_email]: action.login,
        },
      };
    case LOGOUT:
    {
      if (!state.cache) {
        return state;
      }
      const keys = Object.keys(state.cache);
      if (keys.length === 0) {
        return state;
      }
      const i = state.cache[keys[0]].account_email === action.logout ? 1 : 0;
      const entryRemoved = produce(state, (draftState) => {
        delete draftState.cache[action.logout];
      });
      if (Object.keys(entryRemoved.cache).length === 0) {
        return {
          cache: {
            ...entryRemoved.cache,
          },
        };
      }
      const newState = {
        ...entryRemoved,
        ...state.cache[keys[i]],
      };
      // console.log('newState', newState);
      return newState;
    }
    default:
      return state;
  }
};

export default login;
