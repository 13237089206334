// (C) Copyright 2020 MediaWink, LLC

import {
  IMPORT_JSON,
  SET_SELECTED_FIELD,
} from '../action-types';

const selectedField = (state = 0, action = null) => {
  switch (action.type) {
    case IMPORT_JSON:
      return null;
    case SET_SELECTED_FIELD:
      return action.field;
    default:
      return state;
  }
};

export default selectedField;
