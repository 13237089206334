// (C) Copyright 2020 MediaWink, LLC

import {
  UNSPLASH_SAGA,
} from '../action-types/unsplash';

// Trigger sagas
export const triggerUnsplashSaga = (data, resolve, reject) => ({
  type: UNSPLASH_SAGA, data, resolve, reject,
});
