// (C) Copyright 2020 MediaWink, LLC

import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box,
  TextField,
} from '@material-ui/core';
import { useParams } from 'react-router';
// import {
//   // pushBreadcrumbSaga,
//   setBreadcrumbsSaga,
// } from '../components/breadcrumb';

import Editor from '../components/Editor';
import { Context } from '../components/Context';
import PlayApp from './PlayApp';
// import ItemsSplitList from '../components/ItemsSplitList';
import {
  triggerSetPublicBreadcrumbsSaga,
  triggerSetPrivateBreadcrumbsSaga,
  triggerPlayAppSaga,
} from '../actions/items';
import ItemLists from '../components/ItemLists';
import Breadcrumbs from '../components/Breadcrumbs';
import ViewSettings from '../components/ViewSettings';
import {
  selectPage,
  selectPageId,
  selectPrivateBreadcrumbsByPath,
  selectPublicBreadcrumbsByPath,
  selectItems,
} from '../selectors';
import EditItem from '../components/EditItem';
import ErrorBoundary from '../components/ErrorBoundary';

function Splat({
  showList,
  setShowList,
  showApp,
  setShowApp,
  debug,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const startApp = (data) => dispatch(
    triggerPlayAppSaga(data, navigate),
  );
  const [input, setInput] = useState('0.00');
  const [context] = useContext(Context);
  const params = useParams();
  const { '*': splat } = params;
  const page = useSelector(selectPage);
  const pageId = useSelector(selectPageId);
  const items = useSelector(selectItems);
  const getPrivateCrumbsByPath = useSelector(
    (state) => (path) => selectPrivateBreadcrumbsByPath(state, path),
  );
  const getPublicCrumbsByPath = useSelector(
    (state) => (path) => selectPublicBreadcrumbsByPath(state, path),
  );
  // const setBreadcrumbsCall = (data) => dispatch(
  //   setPrivateBreadcrumbs(data),
  // );
  const setPrivateBreadcrumbs = (crumbs) => dispatch(
    triggerSetPrivateBreadcrumbsSaga({ breadcrumbs: crumbs }),
  );
  const setPublicBreadcrumbs = (crumbs) => dispatch(
    triggerSetPublicBreadcrumbsSaga({ breadcrumbs: crumbs }),
  );

  // // PREVENT PAGE RELOAD
  // useEffect(() => {
  //   // Prompt confirmation when reload page is triggered
  //   window.onbeforeunload = () => '';
  //   // Unmount the window.onbeforeunload event
  //   return () => { window.onbeforeunload = null; };
  // }, []);

  // https://github.com/remix-run/remix/discussions/5999
  useEffect(() => {
    try {
      console.log({ pageId, splat, page });
      const publicCrumbs = getPublicCrumbsByPath(splat);
      const privateCrumbs = getPrivateCrumbsByPath(splat);
      // eslint-disable-next-line eqeqeq
      // if (pathPrivateCrumbs.length !== currentPrivateCrumbs.length) {
      console.log({ publicCrumbs, privateCrumbs });

      setPublicBreadcrumbs(publicCrumbs);
      setPrivateBreadcrumbs(privateCrumbs);
      // if ((pageId === 1)
      //   || (page.Name.value === splat)) {
      //   console.log('setBreadcrumbs');
      //   setPublicBreadcrumbs(publicCrumbs);
      //   setPrivateBreadcrumbs(privateCrumbs);
      // } else {
      //   console.log('SKIP setBreadcrumbs');
      // }

      // PlayApp??
      const playApp = splat.startsWith('App List')
        && splat !== 'App List';
      // page?.Name?.value === 'App List'
      // || items?.Type?.value === 'App'
      // ||
      console.log({ playApp, splat });
      if (playApp) {
        console.log({
          'StartApp': true,
          dispatch,
          // input,
          // setInput,
          'Page Name': page?.Name?.value,
        });
        const tf = (
          <TextField
            id='value'
            key='tf'
            label='title'
            defaultValue='list'
            // initialValue={input}
            // value={input}
          />
        );
        startApp({
          dispatch, inputObj: { input }, setInput, tfObj: { tf },
        });
        context.setShowList(false);
        context.setShowApp(true);
      }
    } catch (e) {
      console.log('splat useEffect error', e);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);
  return (
    <ErrorBoundary>
      <Box>
        <ViewSettings
          showList={showList}
          setShowList={setShowList}
          showApp={showApp}
          setShowApp={setShowApp}
          className='tour-view-settings'
        />
      </Box>
      <Box display='flex' flexDirection='row' className='tour-breadcrumbs'>
        <Breadcrumbs detect={true} privateParam={false} debug={debug} />
      </Box>
      {/*
      <Box display='flex' flexDirection='row' className='tour-breadcrumbs'>
        <Breadcrumbs detect={false} privateParam={true} debug={debug} />
      </Box>
      <Box display='flex' flexDirection='row' className='tour-breadcrumbs'>
        <Breadcrumbs detect={false} privateParam={false} debug={debug} />
      </Box>
      */}
      {splat !== ''
      && (
      <Box>
        <Editor items={items} pageId={pageId} />
      </Box>
      )}
      <Box>
        {/* <ItemsSplitList debug={debug} /> */}
        {showList && <ItemLists debug={debug} />}
        {!page.hideFields
        && <EditItem id={pageId} />}
      </Box>
      {showApp && (
      <PlayApp
        debug={debug}
        setShowList={setShowList}
        setShowApp={setShowApp}
      />
      )}
    </ErrorBoundary>
  );
}

Splat.propTypes = {
  debug: PropTypes.bool.isRequired,
  showApp: PropTypes.bool.isRequired,
  setShowApp: PropTypes.func.isRequired,
  showList: PropTypes.bool.isRequired,
  setShowList: PropTypes.func.isRequired,
};

export default Splat;
