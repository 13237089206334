// (C) Copyright 2020 MediaWink, LLC

import {
  IMPORT_JSON,
  SET_TOUR,
} from '../action-types';

const tour = (state = 0, action = null) => {
  switch (action.type) {
    case IMPORT_JSON:
      return JSON.parse(action.json).private.tour;
    case SET_TOUR:
      return action.tour;
    default:
      return state;
  }
};

export default tour;
