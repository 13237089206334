// (C) Copyright 2020 MediaWink, LLC

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import {
  Box,
  Checkbox,
  FormGroup,
  // FormControl,
  FormControlLabel,
  // InputLabel,
  // MenuItem,
  // Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Switch } from '@mantine/core';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import Spinner from './spinner/spinner';
import {
  resetPasswordAsync,
  // triggerSetAutomationSettingSaga,
  triggerLogoutSaga,
  triggerDeleteAccountSaga,
  triggerSetBackupSettingSaga,
} from '../actions/session';

function UserSettings() {
  const dispatch = useDispatch();
  const [optIn, setOptIn] = useState(true);
  const { promiseInProgress } = usePromiseTracker();
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const loginState = useSelector((state) => state.undo.present.private.login);
  const settings = useSelector((state) => state.undo.present.private.settings);
  const logout = (data, resolve, reject) => dispatch(
    triggerLogoutSaga(data, resolve, reject),
  );
  const reset = (payload, resolve, reject) => dispatch(
    resetPasswordAsync(payload, resolve, reject),
  );
  const deleteAccount = (payload, resolve, reject) => dispatch(
    triggerDeleteAccountSaga(payload, resolve, reject),
  );
  const setBackup = (payload, resolve, reject) => dispatch(
    triggerSetBackupSettingSaga(payload, resolve, reject),
  );
  /*
  const setAutomation = (payload, resolve, reject) => dispatch(
    triggerSetAutomationSettingSaga(payload, resolve, reject),
  );
  */
  const [message, setMessage] = useState('');
  // const handleSubmit = ({ reset, setMessage }) => (event) => {
  const [deletePressed, setDeletePressed] = useState(false);
  const handleDeleteAccount = (event) => {
    event.preventDefault();
    if (!deletePressed) {
      setDeletePressed(true);
      setMessage(`Do you really want to delete this account?  All data will be deleted.
       Be sure to create and download a backup file first.  To delete the account,
       press Delete Account one more time.`);
    } else {
      const data = {
        password,
        new_password: newPassword,
      };
      trackPromise(
        new Promise((resolve, reject) => {
          deleteAccount(data, resolve, reject);
        })
          .catch((e) => {
            console.error('Delete Account failed.  Try again.', e);
            setMessage('Delete Account failed.  Try again.');
          }),
      );
      let p;
      trackPromise(
        p = new Promise((resolve, reject) => {
          logout(loginState, resolve, reject);
        }),
      );
      p.catch(() => {
      });
    }
  };
  const handleUpdatePassword = (event) => {
    event.preventDefault();
    setMessage('');
    if (newPassword !== newPassword2) {
      setMessage('New passwords must match.');
      return;
    }
    const data = {
      password,
      new_password: newPassword,
    };
    trackPromise(
      new Promise((resolve, reject) => {
        reset(data, resolve, reject);
      })
        .catch(() => {
          setMessage('Incorrect current password.  Try again.');
        }),
    );
  };
  const setBackupsChecked = (data) => {
    trackPromise(
      new Promise((resolve, reject) => {
        setBackup(data, resolve, reject);
      })
        .catch(() => {
          setMessage('Set backup daily failed.');
        }),
    );
  };
  /*
  const setAutomationOption = (data) => {
    // console.log('setAutomationChecked', data);
    console.log('new Promise: UserSettings');
    trackPromise(
      new Promise((resolve, reject) => {
        setAutomation(data, resolve, reject);
      })
        .catch(() => {
          setMessage('Set automation failed.');
        }),
    );
  };
  */
  return (
    <Stack spacing={2}>
      {promiseInProgress && <Spinner text='Saving settings...' />}
      <Typography>
        Settings for
        {' '}
        {loginState.account_first_name}
        {' '}
        &lt;
        {loginState.account_email}
        &gt;
      </Typography>
      <FormGroup>
        <h2 id='text_messages'>Text Messages</h2>
        <Typography>
          You can opt in or out of receiving text messages.
        </Typography>
        <Typography>
          Message and data rates may apply.
        </Typography>
        <Typography>
          Message frequency varies.
        </Typography>
        <Typography>
          Enter the phone number where you would like to receive text notifications.
        </Typography>
        <Typography>
          Switch the Opt-in switch off or on at anytime to opt out or back in
        </Typography>
        <Typography>
          OR reply STOP to opt-out, reply HELP for info.
        </Typography>
        <br />
        <Box>
          <label htmlFor='phone_number'>
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='center'
              padding={2}
            >
              <Typography padding='inherit'>
                Phone Number:
              </Typography>
              &nbsp;
              &nbsp;
              <input id='phone_number' />
              &nbsp;
              &nbsp;
              <Switch
                id='editable'
                label='💬 Opt-in'
                checked={optIn}
                onChange={(event) => setOptIn(event.target.checked)}
              />
            </Box>
          </label>
        </Box>
        <h2 id='backups'>Backups</h2>
        <Box>
          <FormControlLabel
            label='Daily Backups'
            control={
                  (
                    <Checkbox
                      checked={settings?.backups}
                      onChange={(event) => setBackupsChecked(event.target.checked)}
                    />
                  )
              }
          />
        </Box>
        {/*
          <FormControl fullWidth={true}>
            <InputLabel id='demo-simple-select-label'>Automatically add until</InputLabel>
            <Select
              // labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={settings.automation}
              label='Automatically add until'
              onChange={event => setAutomationOption(event.target.value)}
            >
              <MenuItem value={false}>Off</MenuItem>
              <MenuItem value='Today'>Today</MenuItem>
              <MenuItem value='Monday'>Monday</MenuItem>
              <MenuItem value='Tuesday'>Tuesday</MenuItem>
              <MenuItem value='Wednesday'>Wednesday</MenuItem>
              <MenuItem value='Thursday'>Thursday</MenuItem>
              <MenuItem value='Friday'>Friday</MenuItem>
              <MenuItem value='Saturday'>Saturday</MenuItem>
              <MenuItem value='Sunday'>Sunday</MenuItem>
            </Select>
          </FormControl>
          */}
      </FormGroup>
      <br />
      <h2 id='change_password'>Change Password</h2>
      <form onSubmit={handleUpdatePassword}>
        <Box>
          <TextField
            name='password'
            label='Current Password'
                // placeholder='Current password'
            onChange={(e) => setPassword(e.target.value)}
            type='password'
            value={password}
          />
        </Box>
        <Box>
          <TextField
            name='newPassword'
            label='New Password'
                // placeholder='New password'
            onChange={(e) => setNewPassword(e.target.value)}
            type='password'
            value={newPassword}
          />
        </Box>
        <Box>
          <TextField
            name='newPassword2'
            label='New Password Again'
                // placeholder='New password'
            onChange={(e) => setNewPassword2(e.target.value)}
            type='password'
            value={newPassword2}
          />
        </Box>
        <Box>
          <Typography color='secondary'>
            {message}
          </Typography>
        </Box>
        <br />
        <Box>
          <Button
            type='submit'
            color='primary'
            variant='contained'
          >
            Update Password
          </Button>
        </Box>
      </form>
      <br />
      <form onSubmit={handleDeleteAccount}>
        <Box>
          <Button
            type='submit'
            color='error'
            variant='contained'
          >
            Delete Account
          </Button>
        </Box>
      </form>
    </Stack>
  );
}

export default UserSettings;
