// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import {
  Box,
  Typography,
} from '@material-ui/core';

function Welcome() {
  const mailto = `mailto:mediawink@gmail.com?subject=AchieveHub Access Request
    &amp;body=Please give me an account to AchieveHub so I can:`;
  return (
    <Box>
      <Box
        align='center'
      >
        <h1>Welcome to AchieveHub!</h1>
      </Box>
      <h2>Purpose</h2>
      <Typography>
        The purpose of AchieveHub is to help you achieve!
        Achieve what?  That all depends on you!
        AchieveHub is set up as a list of lists.
        Those lists can be whatever you want them to be!
        For example, you could have a list called
        {' '}
        <b>shopping</b>
        .  Under
        {' '}
        <b>shopping</b>
        , you could have a list of items to get,
        or you could have a list of stores, and under
        each store, you could have items.  It is all up to you.
      </Typography>
      <h2>Access</h2>
      <Typography>
        To get an account with AchieveHub, please email
        <a href={mailto}>mediawink@gmail.coms</a>
        {' '}
        and explain what you want to do with your AchieveHub account.  Thanks!
      </Typography>
      <h2>To Learn More</h2>
      <Typography>
        To learn more, click on the help icon at the top of this window.
        There you can take a tour of the features available through AchieveHub.
      </Typography>
      <h2>Signing in</h2>
      <Typography>
        To sign in, click the sign in button at the top of this window.
        It looks like an arrow into a box or door.
      </Typography>
      <h2>Signing in with Google, and your data</h2>
      <Typography>
        To make sign in easier, once you have an AchieveHub account with a GMail user email,
        you can, from the sign in page, simply click the
        {' '}
        <b>Sign in with Google</b>
        {' '}
        button instead of entering your email address and your AchieveHub password.
        All AchieveHub does with your Google information is read your
        email address so you do not have to enter it manually, and
        validate that it is you.  We may use your Google profile
        picture, so if you do not like it, you may want to get a better
        picture for your Google profile.
      </Typography>
      <Typography>
        See our
        <a href='/terms'>Terms</a>
        {' '}
        and
        <a href='/privacy'>Privacy Policy</a>
        {' '}
        pages for more details about the terms of use and our privacy policy.
        Clicking on the home icon at the top of the page will get you back to this home page.
      </Typography>
      <img alt='Wagon Wheel' src='android-chrome-192x192.png' />
    </Box>
  );
}

export default Welcome;
