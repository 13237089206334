// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import PropTypes from 'prop-types';
import './styles/whiteFlash.css';

export function WhiteFlash({ isShowWhiteFlash }) {
  const flashDoTransition = isShowWhiteFlash ? 'do-transition' : '';
  const flashClasses = `${flashDoTransition} normal`;

  return (
    <div id='white-flash' className={flashClasses} />
  );
}

WhiteFlash.propTypes = {
  isShowWhiteFlash: PropTypes.bool.isRequired,
};

export default WhiteFlash;
