// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import {
  Typography,
} from '@material-ui/core';

function LocalhostBanner() {
  let hideDebugText = false;
  if (window.location.hostname === 'localhost') {
    hideDebugText = true;
  }
  return hideDebugText ? (
    <Typography color='secondary'>
      localhost development
    </Typography>
  ) : null;
}

export default LocalhostBanner;
