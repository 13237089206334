// (C) Copyright 2020 MediaWink, LLC

import {
  ADJUST,
  SET_NEXT_ID,
} from '../action-types';

const createNextIdReducer = (itemsType) => (state = 0, action = null) => {
  switch (action.type) {
    case `IMPORT_JSON_${itemsType}`:
      // console.log(`nextid: IMPORT_JSON_${itemsType}, json:`, JSON.parse(action.json));
      if (itemsType === 'PUBLIC') {
        return JSON.parse(action.json).public.nextId;
      }
      return JSON.parse(action.json).private.nextId;
    case SET_NEXT_ID:
      return action.id;
    case ADJUST:
      return (state - 1 === action.removedId ? state - 1 : state);
    default:
      return state;
  }
};

export default createNextIdReducer;
