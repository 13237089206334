// (C) Copyright 2020 MediaWink, LLC

import * as React from 'react';
import Box from '@mui/material/Box';
import { InputAdornment, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import Photo from './Photo';
import { convertImageToText, processReceiptText } from '../utils/ocr';

function InputTextFieldCamera({ label, value, onChange }) {
  const [val, setVal] = React.useState(value);
  const photoHandler = async (image) => {
    // console.log({ photoHandler: image });
    if (image !== 'NOWAY') {
      let left = 20;
      setVal(`Processing... About ${left} seconds left...`);
      const intervalId = window.setInterval(() => {
        if (left > 5) {
          left -= 5;
        } else if (left > 0) {
          left -= 1;
        }
        setVal(`Processing... About ${left} seconds left...`);
      }, 5000);
      const text = await convertImageToText(image);
      const { total } = processReceiptText(text);
      clearInterval(intervalId);
      setVal(total || '');
      onChange({ target: { value: total } });
    }
  };
  const handleChange = (event) => {
    onChange(event);
    console.log(event.target.value);
    setVal(event.target.value);
  };
  return (
    <Box
      component='form'
      sx={{
        '& .MuiTextField-root': { m: 1, width: '100%' },
      }}
      noValidate={true}
      autoComplete='off'
    >
      <>
        <TextField
          id='outlined-select-currency-native'
          onChange={handleChange}
          fullWidth={true}
          label={label}
          InputProps={
            ['Amount', 'Cost'].includes(label) ? {
              startAdornment: <InputAdornment position='start'>$</InputAdornment>,
            } : {}
          }
          defaultValue={val}
          value={val}
        />
        <Photo key='tick' handler={photoHandler} />
      </>
    </Box>
  );
}

InputTextFieldCamera.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};

InputTextFieldCamera.defaultProps = {
  value: '',
};

export default InputTextFieldCamera;
