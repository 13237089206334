// (C) Copyright 2020 MediaWink, LLC

import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

function InputTextField({ label, value, onChange }) {
  return (
    <Box
      component='form'
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate={true}
      autoComplete='off'
    >
      <div>
        <TextField
          id='outlined-select-currency-native'
          onChange={onChange}
          label={label}
          defaultValue={value}
        />
      </div>
    </Box>
  );
}

InputTextField.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};

InputTextField.defaultProps = {
  value: '',
};

export default InputTextField;
