// (C) Copyright 2020 MediaWink, LLC

import { produce } from 'immer';

const migrate = (dataObject) => {
  // console.log('migrate');
  // console.log(dataObject);
  // Version 0 (undefined)
  if (!dataObject) {
    return dataObject;
  }
  if (dataObject && dataObject.version === undefined) {
    return produce((dataIn) => {
      // https://stackoverflow.com/a/44325124/11962478
      const arr = dataIn.todos;
      let itemNumber = -1;
      const todosObject = arr.reduce(
        (obj, item) => {
          itemNumber += 1;
          return Object.assign(obj, {
            [item.key]: {
              ...item.value,
              id: itemNumber,
              Description: item.text,
              Completed: item.completed,
            },
          });
        },
        {},
      );
      /*
      const todosObject = arr.reduce((obj, item) => (obj[item.text] = {
        id: itemNumber += 1,
        Description: item.text,
        Completed: item.completed,
      }, obj), {}); // eslint-disable-line no-sequences
      */
      return {
        version: 1,
        Home: {
          visibilityFilter: dataIn.visibilityFilter,
          breadcrumbs: [],
          items: {
            Contacts: {
              id: 0,
            },
            Tasks: {
              id: 1,
              items: {
                ...todosObject,
              },
            },
          },
        },
      };
    });
  }

  // console.log(`dataObject.version === ${dataObject.version}`);

  // Latest -- Just return
  // Version 7...
  if (dataObject.version === '7') {
    return dataObject;
  }

  // Older, apply ALL migrations, in order
  // Version 1...
  // Version 2...
  // Version 3...
  // Version 4...
  // Version 5...
  // Version 6...
  if (dataObject.version === '6') {
    // api.compress => api._migrate (temp)
  }
  return dataObject;
};

export default migrate;
