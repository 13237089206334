// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import {
  Box,
} from '@material-ui/core';

import UserAdmin from '../components/UserAdmin';

function UserAdminScreen() {
  return (
    <Box>
      <h2>User Admin</h2>
      <Box width={800}>
        <UserAdmin />
      </Box>
    </Box>
  );
}

export default UserAdminScreen;
