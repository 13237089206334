// (C) Copyright 2020 MediaWink, LLC

import {
  IMPORT_SAGA,
  MERGE_SAGA,
} from '../action-types/imports';

// Trigger sagas
export const triggerImportSaga = (data, resolve, reject, navigate) => ({
  type: IMPORT_SAGA, data, resolve, reject, navigate,
});
export const triggerMergeSaga = (data, resolve, reject, navigate) => ({
  type: MERGE_SAGA, data, resolve, reject, navigate,
});
