// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
} from '@material-ui/core';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(/* error */) {
    // Update state so the next render will hide the fallback UI.
    // console.log('getDerivedStateFromError in ERROR BOUNDARY', error);
    return { hasError: true };
  }

  // componentDidCatch(/* error, errorInfo */) {
  // You can also log the error to an error reporting service
  // logErrorToMyService(error, errorInfo);
  // console.log('componentDidCatch in ERROR BOUNDARY');
  // console.log(error, errorInfo);
  // }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <Box>
          <h1>Something went wrong</h1>
          <Typography>
            Please report the steps that were performed to get this error
            so we can fix it, including where you were,
            what browser you are using, and any other information
            you feel would be helpful to resolve this.
          </Typography>
          <Typography>Here are a few things to try:</Typography>
          <ul>
            <li>Try refreshing the browser.</li>
            <li>
              Try going to the
              {' '}
              <a href='/signin'>sign in page</a>
            </li>
            <li>
              If nothing else works, you may need to re-import a
              backup you had exported previously.
            </li>
          </ul>
        </Box>
      );
    }

    /* eslint-disable-next-line react/prop-types */
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.array.isRequired,
};

export default ErrorBoundary;
