// (C) Copyright 2020 MediaWink, LLC

import React, { useContext } from 'react';
import { OpenEyeIcon, ClosedEyeIcon } from '../constants/icons';

import { Context } from './Context';
import * as COLORS from '../constants/colors';

function ViewSettingsEye() {
  const [context, setContext] = useContext(Context);
  return (!context.showViewSettings ? (
    <OpenEyeIcon
      className='tour-eye'
      style={{ fill: COLORS.GRAY40 }}
      onClick={() => setContext({
        ...context,
        showViewSettings: true,
      })}
    />
  ) : (
    <ClosedEyeIcon
      className='tour-eye'
      style={{ fill: COLORS.GRAY40 }}
      onClick={() => setContext({
        ...context,
        showViewSettings: false,
      })}
    />
  ));
}

export default ViewSettingsEye;
