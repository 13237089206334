// (C) Copyright 2020 MediaWink, LLC

import { call, select, takeLatest } from 'redux-saga/effects';

import achievehubAPI from '../apis/achievehubAPI';
import {
  IMPORT_SAGA,
  //  MERGE_SAGA,
  SYNC_FROM_DATABASE_SAGA,
} from '../action-types';
import { syncFromDatabaseSaga } from './session';
import {
  selectLastmodtime,
  selectLogin,
} from '../selectors';

export function* importSaga(action) {
  console.log('importSaga, action:', action);
  const {
    data, resolve, reject, navigate,
  } = action;
  try {
    const lastmodtime = yield select(selectLastmodtime);
    const login = yield select(selectLogin);
    yield call(achievehubAPI.do, 'import_json', {
      ...login,
      json: data,
      lastmodtime,
    });

    console.log('importSaga, (action):', action);
    yield syncFromDatabaseSaga({
      ...action,
      data: login,
      item: 1,
      privateItem: 1,
      publicItem: 1,
      type: SYNC_FROM_DATABASE_SAGA,
    });
    navigate('/importSaga');
    if (resolve) {
      resolve();
    }
  } catch (e) {
    if (reject) {
      reject();
    }
    console.warn('error', e);
  }
}

export function* watchImportSagas() {
  yield takeLatest(IMPORT_SAGA, importSaga);
}
