// (C) Copyright 2020 MediaWink, LLC

import { put, select, takeLatest } from 'redux-saga/effects';
import { saveAs } from 'file-saver';

import {
  EXPORT_SAGA,
} from '../action-types';
import {
  setSettings,
} from '../actions/items';
import {
  selectPresent,
  selectSettings,
} from '../selectors';
import { getNowString } from '../utils/time';
import { setSettingSaga } from './session';

export function* exportSaga(action) {
  // console.log('action', action);
  const { resolve, reject, navigate } = action;
  try {
    const currentJson = yield select(selectPresent);
    // console.log('login');
    const { login, ...exportJson } = currentJson;
    // console.log('blob');
    const blob = new Blob([JSON.stringify(exportJson)], { type: 'text/json;charset=utf-8' });
    // console.log('saving blob', blob);
    saveAs(blob, `${getNowString()}_achieveHub.ah`);
    // console.log('saved');
    const dateString = new Date().toLocaleDateString();
    const settings = yield select(selectSettings);
    const newSettings = { ...settings, 'lastBackup': dateString };
    yield put(setSettings(newSettings));
    yield setSettingSaga({ data: newSettings });
    navigate('/exportSaga');
    if (resolve) {
      resolve();
    }
  } catch (e) {
    console.warn('error', e);
    if (reject) {
      reject();
    }
  }
}

export function* watchExportSagas() {
  yield takeLatest(EXPORT_SAGA, exportSaga);
}
