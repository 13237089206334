// (C) Copyright 2020 MediaWink, LLC

import {
  // IMPORT_JSON,
  SET_LASTMODTIME,
} from '../action-types';

const lastmodtime = (state = '', action = null) => {
  switch (action.type) {
    /*
    case IMPORT_JSON:
      console.log('action', JSON.parse(action.json));
      return JSON.parse(action.json).private.lastmodtime;
    */
    case SET_LASTMODTIME:
      return action.data.data.lastmodtime;
    default:
      return state;
  }
};

export default lastmodtime;
