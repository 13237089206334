// (C) Copyright 2020 MediaWink, LLC

import { call, select, takeLatest } from 'redux-saga/effects';

import achievehubAPI from '../apis/achievehubAPI';
import {
  MERGE_SAGA,
  SYNC_FROM_DATABASE_SAGA,
} from '../action-types';
import { syncFromDatabaseSaga } from './session';
import {
  selectNextId,
  selectLogin,
} from '../selectors';

export function* mergeSaga(action) {
  const {
    data, resolve, reject, navigate,
  } = action;
  const { fromFile, currentState } = data;
  const fromFileObj = JSON.parse(fromFile);
  const allFileItems = fromFileObj?.entities?.items;
  console.log('mergeSaga, action:', action);
  const crumbs = currentState.breadcrumbs;
  console.log('crumbs:', crumbs);
  const allItems = currentState.entities.items;
  const parentId = crumbs.slice(-1)[0];
  console.log('parentId', parentId);

  // Walk breadcrumbs
  let f = 1;
  let item;
  let fileItem;
  let fileId;
  for (let crumbLevel = 1; crumbLevel < crumbs.length; crumbLevel += 1) {
    item = allItems[crumbs[crumbLevel]];
    const name = item?.Name?.value;

    for (let itemsIndex = 0; itemsIndex < allFileItems[f].items.length; itemsIndex += 1) {
      fileId = allFileItems[f].items[itemsIndex];
      fileItem = allFileItems[fileId];
      if (fileItem?.Name?.value === name) {
        f = fileId;
        break;
      }
    }

    // const fileId = allFileItems[f]?.id;
    // fileItem = allFileItems[fileId];
    if (fileItem?.Name?.value !== name) {
      // TODO: Show this to the user:

      const file = fileItem?.Name?.value;
      console.log(`Current page (${name}) not in import file (${file}).  Nothing to merge.`);
      if (resolve) {
        resolve();
      }
      return;
    }
    f = fileId;
  }

  console.log('Merge point (parent):', f, '. Merge in this item from file:', fileItem);
  console.log('itemID:', item.id, '. item:', item);

  try {
    const login = yield select(selectLogin);
    let nextId = yield select(selectNextId);
    let nextChildId = nextId + 1;
    let newTodo = [];
    let newParents = [];

    const add = (parent, newItem, items) => {
      let newItems = items;
      console.log(' add', parent, newItem, items);
      console.log('   newParents', newParents);
      console.log('   newTodo', newTodo);
      const newChildren = [];

      // Update children indices...
      newItem?.items.forEach((id) => {
        newChildren.push(nextChildId);
        newParents.push(nextId);
        newTodo.push(id);
        nextChildId += 1;
      });

      // Insert updated item
      const insertItem = {
        [nextId]: {
          ...newItem,
          id: nextId,
          parent,
          items: newChildren,
        },
      };
      console.log('  insertItem', insertItem);
      newItems = {
        ...newItems,
        ...insertItem,
      };
      nextId += 1;
      newTodo = newTodo.slice(1);
      newParents = newParents.slice(1);
      return newItems;
    };

    const process = (todo, items) => {
      console.log('process', todo, items);
      let newItems = items;
      if (todo.length === 0) {
        return newItems;
      }

      console.log(' todo:', todo);
      todo.forEach((todoId, index) => {
        console.log(' todo.forEach: todoId, index, todo', todoId, index, todo);
        newItems = add(newParents[0], allFileItems[todoId], newItems);
      });
      newItems = process(newTodo, newItems);
      console.log('returning newItems:', newItems);
      return newItems;
    };

    // Build up items:
    let items = {};
    const names = item?.items.map((i) => allItems[i]?.Name?.value);
    console.log('names', names);
    // Add each child from file
    for (let i = 0; i < allFileItems[f].items.length; i += 1) {
      fileId = allFileItems[f].items[i];
      fileItem = allFileItems[fileId];
      const name = fileItem?.Name?.value;
      console.log('Parsing item from state:', name, fileItem);
      if (!names.includes(name)) {
        newParents = [parentId];
        newTodo = [fileItem.id];
        items = process(newTodo, items);
        nextChildId += 1;
      } else {
        console.log(`Skipping ${name} -- already exists (do not clobber)`);
      }
    }

    console.log('login', login, 'items', items);
    yield call(achievehubAPI.do, 'add_items', {
      ...login,
      items,
    });
    console.log('login', login, 'items', items);
    yield syncFromDatabaseSaga({
      ...action,
      item: 1,
      privateItem: 1,
      publicItem: 1,
      data: login,
      type: SYNC_FROM_DATABASE_SAGA,
    });
    navigate('/');
    if (resolve) {
      resolve();
    }
  } catch (e) {
    if (reject) {
      reject();
    }
    console.warn('error', e);
  }
}

export function* watchMergeSagas() {
  yield takeLatest(MERGE_SAGA, mergeSaga);
}
