// (C) Copyright 2020 MediaWink, LLC

import { createWorker } from 'tesseract.js';

export const processReceiptText = (input) => {
  const obj = {};
  input.toLowerCase().split('\n').forEach((line) => {
    // console.log('>', line);
    if (!obj.total && !obj.subtotal && (line.match(/.*total/) || (line.match(/.*gasoline/)))) {
      const numberString = line.replace(/(.*)( \$?[\d]+[ ]*\.[ ]*\d\d)(.*)/i, '$2');
      const numberStringReduced = numberString.replaceAll('$', '').replaceAll(' ', '');
      const number = Number(numberStringReduced);
      if (!Number.isNaN(number)) {
        obj.subtotal = number;
      }
    } else if (line.match(/.*tax/) && obj.subtotal) {
      const reducedLine = line.replace(/(.*)(@? ?[\d]+[.:][\d]+%)(.*)/, '$1$3')
        .replace(':', '.');
      const numberStrings = reducedLine.replace(/([^\d]*)([\d]+[ ]*[.][ ]*\d\d)(.*)/i, '$1,$2,$3');
      const numberString = numberStrings.split(',')[1];
      const numberStringReduced = numberString.replaceAll('$', '').replaceAll(' ', '');
      const number = Number(numberStringReduced);
      if (!Number.isNaN(number)) {
        obj.tax = number;
      }
    } else if (line.match(/.*total/) && obj.subtotal) {
      const numberString = line.replace(/([^\d]*)([\d]+[ ]*\.[ ]*\d\d)(.*)/i, '$2');
      const numberStringReduced = numberString.replaceAll('$', '').replaceAll(' ', '');
      const number = Number(numberStringReduced);
      if (!Number.isNaN(number)) {
        obj.total = number;
      }
    }
  });
  if (!obj?.total && obj?.subtotal > 0 && obj?.tax > 0) {
    obj.total = obj.subtotal + obj.tax;
  } else if (obj.subtotal && !obj.total) {
    obj.total = obj.subtotal;
    obj.subtotal = undefined;
  }
  if (obj.total) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    obj.total = formatter.format(obj.total).replace('$', '');
  }
  return obj;
};

// OCR
export const convertImageToText = async (imageData) => {
  const worker = createWorker({
    logger: () => {
      // console.log(m);
    },
  });

  console.log('convertImageToText()');
  if (imageData) {
    await worker.load();
    await worker.loadLanguage('eng');
    await worker.initialize('eng');
    const converted = await worker.recognize(imageData);
    const {
      data: { text },
    } = converted;
    console.log('TEXT', text);
    return text;
  }
  console.log('TEXT (null)');
  return null;
};
