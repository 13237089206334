// (C) Copyright 2020 MediaWink, LLC

import {
  SET_HTML,
} from '../action-types';

const html = (state = null, action = null) => {
  switch (action.type) {
    // Do not add IMPORT_JSON--dirty should not be saved
    case SET_HTML:
      return action.data;
    default:
      return state;
  }
};

export default html;
