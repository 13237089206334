// (C) Copyright 2020 MediaWink, LLC

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  IconButton,
  TextField,
} from '@material-ui/core';
import {
  NextPageIcon,
  NextColumnIcon,
  LastColumnIcon,
  PreviousPageIcon,
  PreviousColumnIcon,
  FirstColumnIcon,
} from '../constants/icons';
import {
  triggerSetColumnsPerPageSaga,
  triggerSetFirstVisibleColumnSaga,
} from '../actions/items';
import { selectPage } from '../selectors/items';
import AddItemToBottom from '../containers/AddItemToBottom';
import { setFirstVisibleColumnSaga } from '../sagas/items';

function TableControls() {
  const page = useSelector((state) => selectPage(state));
  const dispatch = useDispatch();
  const setColumnsPerPage = (data) => dispatch(triggerSetColumnsPerPageSaga(data));
  const setFirstVisibleColumn = (data) => dispatch(triggerSetFirstVisibleColumnSaga(data));
  // console.log('page.length', page.items.length);
  // console.log('page', page);
  // console.log('page.firstVisibleColumn', page.firstVisibleColumn);
  useEffect(() => {
    if (page.firstVisibleColumn === undefined) {
      setFirstVisibleColumnSaga({ id: page.id, firstVisibleColumn: 0 });
    }
  }, [page]);
  // console.log('page', page);
  return (
    <Box
      display='flex'
      flexDirection='row'
      width='100%'
    >
      {page.tableView && (
        <Box
          display='flex'
          flexDirection='row'
          className='tour-table-view-controls'
          width='100%'
        >
          <Box display='flex' flexDirection='row' width='100%'>
            {(page.firstVisibleColumn === undefined
              || page.firstVisibleColumn > 0) && (
              <IconButton
                aria-label='first column'
                onClick={() => setFirstVisibleColumn({
                  id: page.id,
                  firstVisibleColumn: 0,
                })}
                size='small'
              >
                <FirstColumnIcon />
              </IconButton>
            )}
            {page.firstVisibleColumn - page.columnsPerPage >= 0
              && page.columnsPerPage > 1 && (
                <IconButton
                  aria-label='previous page'
                  onClick={() => setFirstVisibleColumn({
                    id: page.id,
                    firstVisibleColumn:
                        page.firstVisibleColumn - page.columnsPerPage,
                  })}
                >
                  <PreviousPageIcon />
                </IconButton>
            )}
            {page.firstVisibleColumn - 1 >= 0 && (
              <IconButton
                onClick={() => setFirstVisibleColumn({
                  id: page.id,
                  firstVisibleColumn: page.firstVisibleColumn - 1,
                })}
              >
                <PreviousColumnIcon />
              </IconButton>
            )}
            <Box width={100}>
              <TextField
                onChange={(event) => setColumnsPerPage({
                  id: page.id,
                  columnsPerPage: parseInt(event.target.value, 10) || 0,
                })}
                placeholder='Columns to hide'
                type='number'
                value={page.columnsPerPage}
              />
            </Box>
            {page.firstVisibleColumn + 1 + page.columnsPerPage + 1
              <= page.items.length && (
              <IconButton
                onClick={() => setFirstVisibleColumn({
                  id: page.id,
                  firstVisibleColumn: page.firstVisibleColumn + 1,
                })}
              >
                <NextColumnIcon />
              </IconButton>
            )}
            {page.firstVisibleColumn
              + page.columnsPerPage
              + page.columnsPerPage
              + 1
              <= page.items.length
              && page.columnsPerPage > 1 && (
                <IconButton
                  onClick={() => setFirstVisibleColumn({
                    id: page.id,
                    firstVisibleColumn:
                        page.firstVisibleColumn + page.columnsPerPage,
                  })}
                >
                  <NextPageIcon />
                </IconButton>
            )}
            {page.firstVisibleColumn < page.items.length
              - 1 - page.columnsPerPage && (
              <IconButton
                onClick={() => {
                  const data = {
                    id: page.id,
                    firstVisibleColumn:
                        page.items.length - 1 - page.columnsPerPage,
                  };
                  setFirstVisibleColumn(data);
                }}
              >
                <LastColumnIcon />
              </IconButton>
            )}
          </Box>
          <Box
            display='flex'
            flexDirection='row'
            className='tour-table-add-item'
            width={800}
          >
            <AddItemToBottom parent={page} />
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default TableControls;
