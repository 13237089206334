// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import {
  Box,
  Typography,
} from '@material-ui/core';

function About() {
  return (
    <Box>
      <Box>
        <h1>Cookies</h1>
        <Box>
          <Typography>
            Cookies are just a way to store a little bit of information on your computer.
            It is just a few &quot;bytes,&quot; and thus the cookie name.
            When you log in, if you want to keep signed in, check
            the &quot;Keep signed in&quot; check box.  This will save this information on your
            computer so that you will not need to sign back in next time you
            come back, on the same computer.
          </Typography>
          <Typography>
            If you want to change this, uncheck the &quot;Keep signed in&quot; check box
            and log in, then log out. This will remove any cookie,
            and you will not be automatically logged in next time.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default About;
