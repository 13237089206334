// (C) Copyright 2020 MediaWink, LLC

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import uniqueId from 'lodash/uniqueId';
import {
  Box,
  MenuItem,
  TextField,
} from '@material-ui/core';
import Download from './Download';
import { selectTemplateOutput, selectTypeValues } from '../selectors';
import { TYPE_ICONS } from '../constants';

function EditFieldValue({
  item,
  keyName,
  updateItemWatcher,
}) {
  const templateOutput = useSelector((state) => selectTemplateOutput(state));
  const getTypeValues = useSelector(
    (state) => (type, keyNameIn) => selectTypeValues(state, type, keyNameIn),
  );
  const [value, setValue] = useState({
    [keyName]: item[keyName],
  });
  useEffect(() => {
    setValue({
      ...value,
      [keyName]: {
        ...value[keyName],
        ...item[keyName],
        type: item[keyName]?.type,
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, keyName]);
  const updateValue = (e) => {
    // console.log('updateValue(e)');
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const newValue = {
      ...value,
      [keyName]: {
        value: value[keyName].value.trim(),
        type: item[keyName].type,
        keys: value[keyName].keys || [uniqueId()],
      },
    };
    const payload = {
      item: {
        ...item,
        ...newValue,
      },
      keyName: keyName || 'Name',
      value: {
        ...value,
        ...e,
      },
    };
    // console.log('updateItemWatcher(payload)', payload);
    updateItemWatcher(payload);
  };
  const onBlur = (e) => {
    // console.log('onBlur', e);
    updateValue(e);
  };
  const type = item[keyName]?.type || 'Text';
  const lowerType = type.toLowerCase();
  return (
    <Box
      direction='row'
    >
      <Box basis='full'>
        <form
          // validate='blur'
          onSubmit={onBlur}
        >
          {lowerType && (lowerType === 'date' || lowerType === 'text' || lowerType === 'list')
                    && (
                    <TextField
                      key={keyName}
                      style={{ padding: '2px', color: 'dark-3' }}
                      type={(item[keyName]?.type || 'Text').toLowerCase()}
                      className='input'
                      value={typeof value[keyName] === 'object'
                        ? value[keyName].value || ''
                        : value[keyName]}
                      onChange={(e) => {
                        e.preventDefault();
                        setValue({
                          [keyName]: {
                            value: e.nativeEvent.target.value,
                          },
                        });
                      }}
                    />
                    )}

          {lowerType && lowerType === 'actions' && (
          <Box>
            <Download text={templateOutput} title={item.Name.value} />
          </Box>
          )}

          {lowerType
                    && lowerType !== 'date'
                    && lowerType !== 'list'
                    && lowerType !== 'text'
                    && lowerType !== 'actions'
                    && (
                      <>
                        <TextField
                          id={keyName}
                          select={true}
                          key={keyName}
                          label={keyName}
                          style={{ padding: '2px', color: 'dark-3' }}
                          value={typeof value[keyName] === 'object'
                            ? value[keyName].value || ''
                            : value[keyName]}
                          onChange={(e) => {
                            const newValue = {
                              [keyName]: {
                                value: e.target.value,
                              },
                            };
                            setValue(newValue);
                            // updateValue(newValue);
                          }}
                        >
                          {getTypeValues(type, keyName).map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                        {TYPE_ICONS[lowerType]}
                      </>
                    )}
        </form>
      </Box>
    </Box>
  );
}

EditFieldValue.propTypes = {
  item: PropTypes.object.isRequired,
  keyName: PropTypes.string.isRequired,
  updateItemWatcher: PropTypes.func.isRequired,
};

export default EditFieldValue;
