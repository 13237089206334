// (C) Copyright 2020 MediaWink, LLC

import { all } from 'redux-saga/effects';
import {
  watchBreadcrumbSagas,
} from './breadcrumb';
import {
  watchSessionSagas,
} from './session';
import {
  watchImportSagas,
} from './importSaga';
import {
  watchMergeSagas,
} from './mergeSaga';
import {
  watchItemSagas,
} from './items';
import {
  watchExportSagas,
} from './exportSaga';
import {
  watchUnsplashSagas,
} from './unsplash';
import {
  watchPlayAppSagas,
} from './playApp';

export default function* rootSaga() {
  yield all([
    watchBreadcrumbSagas(),
    watchImportSagas(),
    watchMergeSagas(),
    watchItemSagas(),
    watchExportSagas(),
    watchSessionSagas(),
    watchUnsplashSagas(),
    watchPlayAppSagas(),
  ]);
}
