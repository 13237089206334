// (C) Copyright 2020 MediaWink, LLC

import React, { useCallback, useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import { useDispatch } from 'react-redux';
import { Switch } from '@mantine/core';
// https://tabler.io/icons
import {
  IconListCheck,
  IconIndentDecrease,
  IconIndentIncrease,
  IconTablePlus,
  IconColumnInsertLeft,
  IconColumnInsertRight,
  IconColumnRemove,
  IconRowInsertTop,
  IconRowInsertBottom,
  IconRowRemove,
  IconTableMinus,
  IconArrowMergeAltLeft,
  IconArrowsSplit,
  IconLayoutBoardSplit,
  IconTableColumn,
  IconTableRow,
  IconTableFilled,
  // IconArrowMergeAltRight, // mergeOrSplit
  IconPaint, // setCellAttribute
  IconHammer, // fixTables
  IconArrowRight, // goToNextCell
  IconArrowLeft, // goToPreviousCell
} from '@tabler/icons-react';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';

// eslint-disable-next-line import/no-unresolved
import '@mantine/tiptap/styles.css';

import './Editor.scss';
import {
  triggerUpdateContentSaga,
} from '../actions/items';

// const content = '<h2 style="text-align: center;">Welcome to Mantine rich text editor</h2><p><code>RichTextEditor</code> component focuses on usability and is designed to be as simple as possible to bring a familiar editing experience to regular users. <code>RichTextEditor</code> is based on <a href="https://tiptap.dev/" rel="noopener noreferrer" target="_blank">Tiptap.dev</a> and supports all of its features:</p><ul><li>General text formatting: <strong>bold</strong>, <em>italic</em>, <u>underline</u>, <s>strike-through</s> </li><li>Headings (h1-h6)</li><li>Sub and super scripts (<sup>&lt;sup /&gt;</sup> and <sub>&lt;sub /&gt;</sub> tags)</li><li>Ordered and bullet lists</li><li>Text align&nbsp;</li><li>And all <a href="https://tiptap.dev/extensions" target="_blank" rel="noopener noreferrer">other extensions</a></li></ul>';

const DEBOUNCE_MS = 2000;

export const tableHTML = `
  <table style="width:100%">
    <tr>
      <th>-</th>
      <th>-</th>
      <th>-</th>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  </table>
`;

// https://tiptap.dev/docs/editor/guide/custom-extensions#priority
const CustomLink = Link.extend({
  priority: 1000,
});

const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      // extend the existing attributes …
      ...this.parent?.(),

      // and add a new one …
      backgroundColor: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-background-color'),
        renderHTML: (attributes) => ({
          'data-background-color': attributes.backgroundColor,
          'style': `background-color: ${attributes.backgroundColor}`,
        }),
      },
    };
  },
});

CustomLink.configure({
  HTMLAttributes: {
    // Change rel to different value
    // Allow search engines to follow links(remove nofollow)
    // rel: 'noopener noreferrer',
    // Remove target entirely so links open in current tab
    target: null,
  },
});

function Editor({
  items,
  pageId,
}) {
  const [editable, setEditable] = useState(false);

  const item = items[pageId];
  const dispatch = useDispatch();
  const updateContent = (data) => dispatch(triggerUpdateContentSaga(data));
  const content = item?.$content || '';
  const save = (text) => {
    // Save text into item.$content
    const payload = {
      id: item.id,
      content: text,
    };
    console.log(text);
    console.log({ 'updateContent': 1, payload });
    updateContent(payload);
  };
  const editor = useEditor({
    editable: false,
    extensions: [
      StarterKit,
      Underline,
      CustomLink,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      CustomTableCell,
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
    ],
    onUpdate: useCallback(
      debounce(({ editor: myEditor }) => {
        if (myEditor?.isEditable) {
          save(myEditor.getJSON());
        }
      }, DEBOUNCE_MS),
      [content],
    ),
    content,
  }, [pageId]);
  // editor?.setEditable(false);
  useEffect(() => {
    if (!editor) {
      return undefined;
    }
    return editor.setEditable(editable);
  }, [editor, editable]);

  // const handleAddLink = () => {
  //   console.log({ handleAddLink: 1, editor });
  //   if (editor?.isActive('link')) {
  //     console.log({ active: 1 });
  //     // Your custom logic to handle link creation
  //     // Use the editor commands to toggle the link
  //     editor.chain().focus().toggleLink({ href: '/example' }).run();
  //   }
  // };

  return (
    <RichTextEditor editor={editor}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        {/* {' 👓 '} */}
        <Switch
          id='editable'
          label=' ✏️ '
          checked={editable}
          onChange={(event) => setEditable(event.target.checked)}
        />
        <Box visibility={editable ? 'visible' : 'hidden'}>
          <RichTextEditor.Toolbar sticky={true} stickyOffset={60}>
            <RichTextEditor.ControlsGroup>
              {/* <button type='button' onClick={handleAddLink}>
                Add Link
              </button> */}
              <button
                type='button'
                onClick={() => editor?.chain()?.focus()?.toggleTaskList()?.run()}
                className={`mantine-focus-auto m-c2207da6 mantine-RichTextEditor-control m-87cf2631
mantine-UnstyledButton-root ${editor?.isActive('taskList') ? 'is-active' : ''}}`}
              >
                <IconListCheck
                  strokeWidth={1.5}
                  style={{
                    width: 'calc(1rem * var(--mantine-scale))',
                    height: 'calc(1rem * var(--mantine-scale))',
                  }}
                />
              </button>
              <RichTextEditor.BulletList />
              <RichTextEditor.OrderedList />
              <button
                type='button'
                onClick={() => editor?.chain()?.focus()?.sinkListItem('taskItem')?.run()}
                disabled={!editor?.can()?.sinkListItem('taskItem')}
                className={`mantine-focus-auto m-c2207da6 mantine-RichTextEditor-control m-87cf2631
mantine-UnstyledButton-root`}
              >
                <IconIndentIncrease
                  strokeWidth={1.5}
                  style={{
                    width: 'calc(1rem * var(--mantine-scale))',
                    height: 'calc(1rem * var(--mantine-scale))',
                  }}
                />
              </button>
              <button
                type='button'
                onClick={() => editor?.chain()?.focus()?.liftListItem('taskItem')?.run()}
                disabled={!editor?.can()?.liftListItem('taskItem')}
                className={`mantine-focus-auto m-c2207da6 mantine-RichTextEditor-control m-87cf2631
mantine-UnstyledButton-root`}
              >
                <IconIndentDecrease
                  strokeWidth={1.5}
                  style={{
                    width: 'calc(1rem * var(--mantine-scale))',
                    height: 'calc(1rem * var(--mantine-scale))',
                  }}
                />
              </button>
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Bold />
              <RichTextEditor.Italic />
              <RichTextEditor.Underline />
              <RichTextEditor.Strikethrough />
              <RichTextEditor.ClearFormatting />
              <RichTextEditor.Highlight />
              <RichTextEditor.Code />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.H1 />
              <RichTextEditor.H2 />
              <RichTextEditor.H3 />
              <RichTextEditor.H4 />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Blockquote />
              <RichTextEditor.Hr />
              <RichTextEditor.Subscript />
              <RichTextEditor.Superscript />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.AlignLeft />
              <RichTextEditor.AlignCenter />
              <RichTextEditor.AlignJustify />
              <RichTextEditor.AlignRight />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Link />
              <RichTextEditor.Unlink />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <button
                type='button'
                onClick={() => editor?.chain()?.focus()?.insertContent(tableHTML, {
                  parseOptions: {
                    preserveWhitespace: false,
                  },
                }).run()}
              >
                <IconTablePlus
                  strokeWidth={1.5}
                  style={{
                    width: 'calc(1rem * var(--mantine-scale))',
                    height: 'calc(1rem * var(--mantine-scale))',
                  }}
                />
              </button>
              <button
                type='button'
                onClick={() => editor?.chain()?.focus()?.fixTables().run()}
                disabled={!editor?.can()?.fixTables()}
              >
                <IconHammer
                  strokeWidth={1.5}
                  style={{
                    width: 'calc(1rem * var(--mantine-scale))',
                    height: 'calc(1rem * var(--mantine-scale))',
                  }}
                />
              </button>
              <button
                type='button'
                onClick={() => editor?.chain()?.focus()?.addColumnBefore().run()}
                disabled={!editor?.can()?.addColumnBefore()}
                style={{
                  visibility: !editor?.can()?.addColumnBefore() ? 'hidden' : 'visible',
                }}
              >
                <IconColumnInsertLeft
                  strokeWidth={1.5}
                  style={{
                    width: 'calc(1rem * var(--mantine-scale))',
                    height: 'calc(1rem * var(--mantine-scale))',
                  }}
                />
              </button>
              <button
                type='button'
                onClick={() => editor?.chain()?.focus()?.addColumnAfter().run()}
                disabled={!editor?.can()?.addColumnAfter()}
                style={{
                  visibility: !editor?.can()?.addColumnAfter() ? 'hidden' : 'visible',
                }}
              >
                <IconColumnInsertRight
                  strokeWidth={1.5}
                  style={{
                    width: 'calc(1rem * var(--mantine-scale))',
                    height: 'calc(1rem * var(--mantine-scale))',
                  }}
                />
              </button>
              <button
                type='button'
                onClick={() => editor?.chain()?.focus()?.deleteColumn().run()}
                disabled={!editor?.can()?.deleteColumn()}
                style={{
                  visibility: !editor?.can()?.deleteColumn() ? 'hidden' : 'visible',
                }}
              >
                <IconColumnRemove
                  strokeWidth={1.5}
                  style={{
                    width: 'calc(1rem * var(--mantine-scale))',
                    height: 'calc(1rem * var(--mantine-scale))',
                  }}
                />
              </button>
              <button
                type='button'
                onClick={() => editor?.chain()?.focus()?.addRowBefore().run()}
                disabled={!editor?.can()?.addRowBefore()}
                style={{
                  visibility: !editor?.can()?.addRowBefore() ? 'hidden' : 'visible',
                }}
              >
                <IconRowInsertTop
                  strokeWidth={1.5}
                  style={{
                    width: 'calc(1rem * var(--mantine-scale))',
                    height: 'calc(1rem * var(--mantine-scale))',
                  }}
                />
              </button>
              <button
                type='button'
                onClick={() => editor?.chain()?.focus()?.addRowAfter().run()}
                disabled={!editor?.can()?.addRowAfter()}
                style={{
                  visibility: !editor?.can()?.addRowAfter() ? 'hidden' : 'visible',
                }}
              >
                <IconRowInsertBottom
                  strokeWidth={1.5}
                  style={{
                    width: 'calc(1rem * var(--mantine-scale))',
                    height: 'calc(1rem * var(--mantine-scale))',
                  }}
                />
              </button>
              <button
                type='button'
                onClick={() => editor?.chain()?.focus()?.deleteRow().run()}
                disabled={!editor?.can()?.deleteRow()}
                style={{
                  visibility: !editor?.can()?.deleteRow() ? 'hidden' : 'visible',
                }}
              >
                <IconRowRemove
                  strokeWidth={1.5}
                  style={{
                    width: 'calc(1rem * var(--mantine-scale))',
                    height: 'calc(1rem * var(--mantine-scale))',
                  }}
                />
              </button>
              <button
                type='button'
                onClick={() => editor?.chain()?.focus()?.deleteTable().run()}
                disabled={!editor?.can()?.deleteTable()}
                style={{
                  visibility: !editor?.can()?.deleteTable() ? 'hidden' : 'visible',
                }}
              >
                <IconTableMinus
                  strokeWidth={1.5}
                  style={{
                    width: 'calc(1rem * var(--mantine-scale))',
                    height: 'calc(1rem * var(--mantine-scale))',
                  }}
                />
              </button>
              <button
                type='button'
                onClick={() => editor?.chain()?.focus()?.mergeCells().run()}
                disabled={!editor?.can()?.mergeCells()}
                style={{
                  visibility: !editor?.can()?.mergeCells() ? 'hidden' : 'visible',
                }}
              >
                <IconArrowMergeAltLeft
                  strokeWidth={1.5}
                  style={{
                    width: 'calc(1rem * var(--mantine-scale))',
                    height: 'calc(1rem * var(--mantine-scale))',
                  }}
                />
              </button>
              <button
                type='button'
                onClick={() => editor?.chain()?.focus()?.splitCell().run()}
                disabled={!editor?.can()?.splitCell()}
                style={{
                  visibility: !editor?.can()?.splitCell() ? 'hidden' : 'visible',
                }}
              >
                <IconArrowsSplit
                  strokeWidth={1.5}
                  style={{
                    width: 'calc(1rem * var(--mantine-scale))',
                    height: 'calc(1rem * var(--mantine-scale))',
                  }}
                />
              </button>
              <button
                type='button'
                onClick={() => editor?.chain()?.focus()?.toggleHeaderColumn().run()}
                disabled={!editor?.can()?.toggleHeaderColumn()}
                style={{
                  visibility: !editor?.can()?.toggleHeaderColumn() ? 'hidden' : 'visible',
                }}
              >
                <IconLayoutBoardSplit
                  strokeWidth={1.5}
                  style={{
                    width: 'calc(1rem * var(--mantine-scale))',
                    height: 'calc(1rem * var(--mantine-scale))',
                  }}
                />
              </button>
              <button
                type='button'
                onClick={() => editor?.chain()?.focus()?.toggleHeaderRow().run()}
                disabled={!editor?.can()?.toggleHeaderRow()}
                style={{
                  visibility: !editor?.can()?.toggleHeaderRow() ? 'hidden' : 'visible',
                }}
              >
                <IconTableColumn
                  strokeWidth={1.5}
                  style={{
                    width: 'calc(1rem * var(--mantine-scale))',
                    height: 'calc(1rem * var(--mantine-scale))',
                  }}
                />
              </button>
              <button
                type='button'
                onClick={() => editor?.chain()?.focus()?.toggleHeaderCell().run()}
                disabled={!editor?.can()?.toggleHeaderCell()}
                style={{
                  visibility: !editor?.can()?.toggleHeaderCell() ? 'hidden' : 'visible',
                }}
              >
                <IconTableRow
                  strokeWidth={1.5}
                  style={{
                    width: 'calc(1rem * var(--mantine-scale))',
                    height: 'calc(1rem * var(--mantine-scale))',
                  }}
                />
              </button>
              <button
                type='button'
                onClick={() => editor?.chain()?.focus()?.mergeOrSplit().run()}
                disabled={!editor?.can()?.mergeOrSplit()}
                style={{
                  visibility: !editor?.can()?.mergeOrSplit() ? 'hidden' : 'visible',
                }}
              >
                <IconTableFilled
                  strokeWidth={1.5}
                  style={{
                    width: 'calc(1rem * var(--mantine-scale))',
                    height: 'calc(1rem * var(--mantine-scale))',
                  }}
                />
              </button>
              <button
                type='button'
                onClick={() => editor
                  ?.chain()
                  ?.focus()
                  ?.setCellAttribute('backgroundColor', '#FAF594').run()}
                disabled={!editor?.can()?.setCellAttribute('backgroundColor', '#FAF594')}
                style={{
                  visibility: !editor?.can()?.setCellAttribute('backgroundColor', '#FAF594')
                    ? 'hidden' : 'visible',
                }}
              >
                <IconPaint
                  strokeWidth={1.5}
                  style={{
                    width: 'calc(1rem * var(--mantine-scale))',
                    height: 'calc(1rem * var(--mantine-scale))',
                  }}
                />
              </button>
              <button
                type='button'
                onClick={() => editor?.chain()?.focus()?.goToNextCell().run()}
                disabled={!editor?.can()?.goToNextCell()}
                style={{
                  visibility: !editor?.can()?.goToNextCell() ? 'hidden' : 'visible',
                }}
              >
                <IconArrowRight
                  strokeWidth={1.5}
                  style={{
                    width: 'calc(1rem * var(--mantine-scale))',
                    height: 'calc(1rem * var(--mantine-scale))',
                  }}
                />
              </button>
              <button
                type='button'
                onClick={() => editor?.chain()?.focus()?.goToPreviousCell().run()}
                disabled={!editor?.can()?.goToPreviousCell()}
                style={{
                  visibility: !editor?.can()?.goToPreviousCell() ? 'hidden' : 'visible',
                }}
              >
                <IconArrowLeft
                  strokeWidth={1.5}
                  style={{
                    width: 'calc(1rem * var(--mantine-scale))',
                    height: 'calc(1rem * var(--mantine-scale))',
                  }}
                />
              </button>
            </RichTextEditor.ControlsGroup>
          </RichTextEditor.Toolbar>
        </Box>
      </Box>
      <hr />
      <RichTextEditor.Content />
    </RichTextEditor>
  );
}

Editor.propTypes = {
  items: PropTypes.object.isRequired,
  pageId: PropTypes.number.isRequired,
};

export default Editor;
