// (C) Copyright 2020 MediaWink, LLC

import { create, all } from 'mathjs';

const math = create(all);
math.config({
  number: 'BigNumber',
});
let defaultValue = '';

const getRandomIntInclusive = (min, max) => {
  const minInt = Math.ceil(min);
  const maxInt = Math.floor(max);
  // The maximum is inclusive and the minimum is inclusive
  const rnd = Math.random();
  const rndInt = Math.floor(rnd * (maxInt - minInt + 1) + minInt);
  return rndInt;
};

/*
 * @param input = a string to replace variables in
 * @param variables = an object containing variable substitutions
 * @param data = MAPPING in the db to use for substitiutions
*/
export const substituteVariables = (input, variables, data) => {
  // console.log({
  //   subsituteVariables: 'called', input, variables, data,
  // });
  let equation = String(input);
  if (!data || !equation || !variables) {
    return equation;
  }
  // "INPUT" ==> INPUT -- no substitution
  if (equation[0] === '"') {
    return equation.slice(1, -1);
  }
  // VARIABLE substitution
  // VAL=VARIABLE
  const output = variables[equation];
  if (output !== undefined) {
    return output;
  }

  let done = false;
  // LONGEST, LONG, L... (don't check L before LONG or LONGEST)
  Object.keys(data).sort((a, b) => b.length - a.length).map((k) => {
    if (done) {
      return null;
    }

    // ORDER DEPENDENT

    // VAL=VARIABLE KEY
    if (equation.includes(`${k} KEY`)) {
      const [replacementVariables] = variables[k].split('=');
      const replacement = replacementVariables;
      equation = equation.replaceAll(`${k} KEY`, replacement);
    }

    // ALPHABETICAL

    // VAL=PICK (NEW) VARIABLE
    if (equation.match(`(.*)?PICK\\s(NEW\\s)?${k}(\\s.*)?`)) {
      let mapping = data?.[k];
      // console.log({ mapping, data, k });
      if (equation.match(`(.*)?PICK\\s(NEW\\s)?${k}\\[.*\\](\\s.*)?`)) {
        const match = equation.match(`(.*)?PICK\\s(NEW\\s)?(?<map>${k}\\[.*\\])(\\s.*)?`);
        // console.log({ PICK_ARRAY: true, match });
        const { groups } = match;
        const { map } = groups;
        const [key, value] = map.replaceAll(']', '').split('[');
        const mappings = data[key];
        // console.log({
        //   mappings, data, key, value, equation,
        // });
        mappings?.items?.forEach((item) => {
          if (item.Name.value === value) {
            // console.log({ 'found value': value, item });
            // TODO: select item.items, map from items[#,#,#] => items: {}
            // TODO: load these in so mapping = below works
            // mapping = item.items    data[key][value];
            // console.log({ mapping, item });
            mapping = item;
            // console.log({ mapping, item });
          }
        });
        if (!mapping) {
          // console.log({
          //   mapping, data, key, value,
          // });
          mapping = data[key][value];
          // console.log({
          //   mapping, data, key, value,
          // });
        }
        // console.log({
        //   mapping, key, value, match, data, items: mapping.items,
        // });
        // console.log({
        //   new: false, mapping, k, equation,
        // });
        // MAPPINGS[k]?
        if (!mapping.items) {
          mapping = mapping?.[value];
        }
      }
      // console.log({ k, mapping, equation });
      if (mapping.items && (!mapping.bag || mapping.bag.length === 0)) {
        // mapping.set = new Set(Object.keys(mapping?.items));
        // mapping.set = new Set(Object.values(mapping?.items).map((i) => i.Name.value));
        mapping.bag = Object.values(mapping?.items).map((i) => i.Name.value);
      }
      if (mapping.bag.length === 0) {
        return '';
      }
      // let index = set.length;
      let index = getRandomIntInclusive(0, mapping.bag.length - 1);
      let grabbed = mapping.bag[index]; // index; // equation;
      let lookFor = [''];
      let lookingForIndex = 0;
      if (equation.includes(' WITH ')) {
        const firstLength = equation.indexOf(' WITH ') + ' WITH '.length;
        lookFor = equation.substring(firstLength).split(' OR ');
        if (equation.includes(' THEN ')) {
          lookFor = equation.substring(firstLength).split(' THEN ');
        }
        // TODO: Handle OR
        // Substitute ALL variables
        const { length } = lookFor;
        for (let i = 0; i < length; i += 1) {
          if (lookFor[i] === lookFor[i].toUpperCase()) {
            // SUBSTITUTE ALL UPPER CASE
            const whatToLookFor = substituteVariables(lookFor[i], variables, data);
            // .split('=')[0];
            lookFor[i] = whatToLookFor.toLowerCase();
            // equation = equation.substring(0, firstLength) + sub;
          }
        }
      }
      lookFor[lookingForIndex] = lookFor[lookingForIndex].toLowerCase();
      // console.log({ lookFor, lookingForIndex }); //
      let looped = false;
      while (index >= 0) {
        if (!lookFor[lookingForIndex]) {
          index = getRandomIntInclusive(0, mapping.bag.length - 1);
          grabbed = mapping.bag[index]; // .Name.value;
          break;
        }
        grabbed = mapping.bag[index]; // ?.Name?.value;
        // console.log({
        //   mapping, items: mapping.items, index, grabbed,
        // });
        if (grabbed.toLowerCase().includes(lookFor[lookingForIndex])) {
          break;
        }
        index -= 1;
        if (index < 0) {
          if (looped) {
            // Try the next TYPE...lookingForIndex
            lookingForIndex += 1;
            if (lookingForIndex >= lookFor.length) {
              // Exhausted all options, return the default value if OR default
              grabbed = defaultValue;
              // console.log({
              //   defaultValue, break: 3, grabbed, lookFor,
              // });
              break;
            } else {
              looped = false;
              index = getRandomIntInclusive(0, mapping.bag.length - 1);
            }
          } else {
            looped = true;
            // console.log({ looped, bag });
            index = mapping.bag.length - 1;
          }
        }
      }
      // console.log({ index, grabbed });
      equation = grabbed;
      // save equation in data
      mapping.value = equation;
      // console.log({ index, mapping, grabbed });
      // mapping.bag.delete(index.toString());
      // const newMapping = mapping.bag.splice(index, 1);
      // console.log({ mapping, newMapping });
      // [mapping.bag] =
      // console.log({ bag: mapping.bag });
      mapping.bag.splice(index, 1); // delete index item from bag
      // console.log({ smallerSet: mapping.bag, grabbed, index });
      done = true;
      return null;
    }
    // VAL=${VARIABLE} VALUE
    if (equation.includes(`${k} VALUE`)) {
      const value = data[k].value.split('=').slice(1).join('=');
      equation = equation.replaceAll(`${k} VALUE`, value);
      done = true;
      return null;
    }
    // VAL=VARIABLE
    if (equation.includes(k)) {
      let replacement = data[k].value.toString();
      if (replacement.toString().includes('.')) {
        data[k].items.forEach((item) => {
          const value = item?.Name?.value;
          if (value.startsWith('Decimals=')) {
            const number = value.replace('Decimals=', '');
            replacement = Number(replacement).toFixed(number);
          }
        });
      }
      replacement = replacement === '' ? '0' : replacement;
      // console.log({ replacement });
      if (/* typeof replacement === 'string' && */ replacement.startsWith(`${k}=`)) {
        replacement = replacement.replace(`${k}=`, '');
      }
      // console.log({ replaceAll: true, equation });
      equation = equation.replaceAll(k, replacement);
      // console.log({ replaceAll2: true, equation });
    }
    if (equation.includes(' HAS ')) {
      let left;
      let localDefaultValue;
      if (equation.includes(' DEFAULT ')) {
        [left, localDefaultValue] = equation.split(' DEFAULT ');
        defaultValue = localDefaultValue || '';
      } else if (equation.includes(' THEN ')) {
        [left, localDefaultValue] = equation.split(' THEN ');
        defaultValue = localDefaultValue || '';
      }
      left = left ?? equation;
      defaultValue = defaultValue.replaceAll('"', '');
      const [leftHas, rightHas] = left.split(' HAS ');
      let toParse = rightHas; // .split(']')[0];
      let array;
      try {
        array = JSON.parse(toParse);
      } catch (_) {
        console.warn(
          'WARNING: Your HAS statement was misformatted.  ',
          'After "]" you need "DEFAULT <value>" or nothing:',
          toParse,
        );
        [toParse] = rightHas.split(']');
        try {
          array = JSON.parse(`${toParse}]`);
        } catch (_err) {
          console.error(
            'ERROR: After stripping everything after "]", it is still not understood:',
            toParse,
          );
        }
      }
      // TODO: Wrap JSON.parse in try catch and handle this for app developers
      array.forEach((item) => {
        const hey = leftHas;
        const needle = item.replaceAll('*', '.*')
          .replaceAll('(', '\\(')
          .replaceAll(')', '\\)');
        const match = hey.match(needle);
        if (match) {
          [equation] = match; // `${item}`;
          done = true;
        }
      });
    }

    // EVALUATIONS
    // eg VAL=SCORE+MIN+1
    let replacement = data[k]?.value.toString().replaceAll('-undefined', '')
      .replaceAll('+undefined', '') || '';
    if (replacement.indexOf('=') >= 0) {
      [, replacement] = replacement.split('=');
    }
    let replacement2 = replacement === '' ? 0 : replacement;
    replacement2 = replacement2 === 'undefined' ? 0 : replacement2;
    replacement2 = replacement2 === undefined ? 0 : replacement2;
    // console.log({
    //   final: true, equation, k, value: data[k]?.value, replacement, replacement2,
    // });
    equation = equation?.replaceAll(k, replacement2 || 0);
    try {
      equation = String(math.evaluate(equation));
      // TODO: $#.##
    } catch (e) {
      // if not an expression, don't change it
    }
    return null;
  });

  if (equation.includes(' DEFAULT ')) {
    // console.log({ 'OUT DEFAULT': true, equation, defaultValue });
    return defaultValue;
  }
  // HAS but not found, and no DEFAULT, return ''
  if (equation.includes(' HAS ')) {
    return '';
  }
  // console.log({ 'OUT': equation });
  return equation;
};
