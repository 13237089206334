// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import {
  DeleteIcon,
  BuildIcon,
} from '../constants/icons';
import ItemListWithAdd from './ItemListWithAdd';
import {
  VisibilityFilters,
  triggerRemoveItemSaga,
//  triggerSetFirstVisibleColumnSaga,
} from '../actions/items';

import {
  selectCurrentTableItems,
  selectVisibilityFilter,
} from '../selectors/items';

function ItemListsTableGroups({
  columnsPerPage,
  debug,
  firstVisibleColumn,
  hideAddTop,
  hideAddBottom,
  hideDelete,
  hideEdit,
  hideCheckmark,
}) {
  const allItems = useSelector((state) => state.undo.present.private.entities.items);
  const items = useSelector((state) => selectCurrentTableItems(state));
  const visibilityFilter = useSelector((state) => selectVisibilityFilter(state));
  const dispatch = useDispatch();
  const removeItem = (data) => dispatch(
    triggerRemoveItemSaga(data),
  );
  // console.log('ItemLists items', items);
  // console.log('ItemLists allItems', allItems);
  const sortedItems = Object.entries(items).sort((a, b) => ((a[1].order > b[1].order) ? 1 : -1));
  // console.log('sortedItems', sortedItems);
  const groups = sortedItems.length > 0 ? sortedItems.slice(0, 1)[0][1].items : [];
  const remainingItems = sortedItems.slice(1);
  const groupHeaderName = sortedItems.length > 0 ? sortedItems.slice(0, 1)[0][1].Name.value : [];
  const groupHeaderId = sortedItems.length > 0 ? sortedItems.slice(0, 1)[0][1].id : [];
  const strMapToObj = (strMap) => {
    const obj = Object.create(null);
    strMap.forEach(([k, v]) => { obj[k] = v; });
  };
  /*
  const setFirstVisibleColumn = data => dispatch(
    triggerSetFirstVisibleColumnSaga(data),
  );
  */
  const getVisibleItems = (itemsIn, filter) => {
    // console.log("itemsIn", itemsIn);
    // console.log("Object.entries", Object.entries(itemsIn));
    switch (filter) {
      case VisibilityFilters.SHOW_ALL:
        return itemsIn;
      case VisibilityFilters.SHOW_COMPLETED:
        return strMapToObj(Object.entries(itemsIn).filter((t) => t[1].completed));
      case VisibilityFilters.SHOW_ACTIVE:
        return strMapToObj(Object.entries(itemsIn).filter((t) => !t[1].completed));
      default:
        throw new Error(`Unknown filter: ${filter}`);
    }
  };
  const day = (name) => {
    const weekday = moment(name, 'MM/DD/YYYY')?.format('dddd');
    return weekday === 'Invalid date' ? null : weekday;
  };

  return (
    <div className='grid'>
      <Table size='small' className='tour-grouped-table'>
        <colgroup>
          {groups && groups.map((group) => (
            <col key={group.key} style={{ width: '20%' }} />))}
          {remainingItems && remainingItems.map((item) => (
            <col key={item.key} style={{ width: '20%' }} />))}
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell key='groupHeaderName' scope='col' border='bottom'>
              <strong>
                {groupHeaderName}
                {debug && ` {${groupHeaderId}}`}
              </strong>
            </TableCell>
            {remainingItems ? remainingItems.slice(
              firstVisibleColumn,
              (columnsPerPage > 0 ? columnsPerPage + firstVisibleColumn : Infinity),
            ).map(
              ([key, value]) => (
                <TableCell key={key} scope='col' border='bottom'>
                  <strong>
                    <Box display='flex' flexDirection='row'>
                      {!hideDelete && (
                        <IconButton
                          aria-label='delete'
                          onClick={() => {
                            // const parent = allItems[value.parent];
                            // console.log('parent', parent);
                            // console.log('remove', value);
                            removeItem({ id: value.id });
                            // TODO: If no item visible, make it visible
                            /*
                            setFirstVisibleColumn({
                              id: value.parent,
                              firstVisibleColumn:
                                parent.items.length - 1 - parent.columnsPerPage + 1,
                            });
                            */
                          }}
                          size='small'
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                      {' '}
                      {value.Name.value}
                      {debug && ` {${value.id}}`}
                    </Box>
                    <Typography variant='h4'>
                      {day(value.Name.value)}
                    </Typography>
                  </strong>
                </TableCell>
              ),
            ) : undefined}
          </TableRow>
        </TableHead>
        <TableBody>
          {groups ? groups.map((groupId) => (
            <TableRow
              key={groupId}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell scope='row' border='all'>
                <Typography>
                  <IconButton
                    onClick={() => {
                      console.log('READ ME');
                      const msg = new SpeechSynthesisUtterance(allItems[groupId].Name.value);
                      window.speechSynthesis.speak(msg);
                    }}
                  >
                    <BuildIcon />
                  </IconButton>
                  {allItems[groupId] && allItems[groupId].Name.value}
                  {debug && ` {${allItems[groupId].id}}`}
                </Typography>
              </TableCell>
              {remainingItems ? remainingItems.slice(
                firstVisibleColumn,
                (columnsPerPage > 0 ? columnsPerPage + firstVisibleColumn : Infinity),
              ).map(
                ([key, value]) => (
                  <TableCell key={key} border='all'>
                    <ItemListWithAdd
                      hideAddBottom={hideAddBottom}
                      hideAddTop={hideAddTop}
                      hideDelete={hideDelete}
                      hideEdit={hideEdit}
                      hideCheckmark={hideCheckmark}
                      debug={debug}
                      group={allItems[groupId] && allItems[groupId].id}
                      item={value}
                      publicItems={{}}
                      privateItems={getVisibleItems(
                        (value.items || [])
                          .map((i) => allItems[i])
                          .reduce((acc, cur) => {
                            if (cur) {
                              if ((allItems[groupId] && cur.group === allItems[groupId].id)
                                || (cur.group === 0 && groupId === groups[0])) {
                                acc[cur.id] = cur;
                              }
                            }
                            return acc;
                          }, {}),
                        visibilityFilter,
                      )}
                    />
                  </TableCell>
                ),
              ) : undefined}
            </TableRow>
          )) : undefined}
        </TableBody>
      </Table>
    </div>
  );
}

ItemListsTableGroups.propTypes = {
  columnsPerPage: PropTypes.number,
  firstVisibleColumn: PropTypes.number,
  debug: PropTypes.bool.isRequired,
  hideAddBottom: PropTypes.bool,
  hideAddTop: PropTypes.bool,
  hideDelete: PropTypes.bool,
  hideEdit: PropTypes.bool,
  hideCheckmark: PropTypes.bool,
};

ItemListsTableGroups.defaultProps = {
  columnsPerPage: 0, // unlimited
  firstVisibleColumn: 0,
  hideAddBottom: false,
  hideAddTop: false,
  hideDelete: false,
  hideEdit: false,
  hideCheckmark: false,
};

export default ItemListsTableGroups;
