// (C) Copyright 2020 MediaWink, LLC

import { connect } from 'react-redux';
import {
  addItemToTopAsync,
} from '../actions/items';
import AddItem from '../components/AddItem';

const mapDispatchToProps = (dispatch) => ({
  func: (data) => dispatch(addItemToTopAsync(data)),
});

export default connect(
  () => ({}),
  mapDispatchToProps,
)(AddItem);
