// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditFieldName from './EditFieldName';
import EditFieldValue from './EditFieldValue';
import DeleteField from './DeleteField';
import {
  triggerUpdateItemSaga,
  triggerUpdateFieldSaga,
  setSelectedField,
} from '../actions/items';
import {
  selectSelectedField,
} from '../selectors';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function EditItem({
  id,
}) {
  const classes = useStyles();
  const login = useSelector((state) => state.undo.present.private.login);
  // const [selectedField, setSelectedField] = useState('');
  const item = useSelector((state) => state.undo.present.private.entities.items[id]);
  const selectedField = useSelector(selectSelectedField);
  const dispatch = useDispatch();
  const updateItem = (data) => dispatch(
    triggerUpdateItemSaga(data),
  );
  const updateField = (data) => dispatch(
    triggerUpdateFieldSaga(data),
  );
  // console.log({ item });
  return !item
    ? <Box />
    : (
      <Box width='100%'>
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableBody>
              {Object.keys(item).map(
                (k) => (k[0].toLowerCase() === k[0] /* && k[0].toUpperCase() === k[0] */
                  ? undefined
                  : (
                    <TableRow key={k}>
                      <TableCell padding='none'>
                        <Box>
                          <DeleteField item={item} name={k} />
                        </Box>
                      </TableCell>
                      <TableCell padding='none'>
                        <EditFieldName
                          key={k}
                          keyName={k}
                          item={item}
                          updateItemWatcher={updateItem}
                          login={login}
                          selectedField={selectedField || ''}
                          setSelection={() => dispatch(setSelectedField(k))}
                          updateField={updateField}
                        />
                        {/* k === 'SyncFile' && 'X' */}
                      </TableCell>
                      <TableCell padding='none'>
                        <EditFieldValue
                          key={k}
                          keyName={k}
                          item={item}
                          updateItemWatcher={updateItem}
                          login={login}
                          selectedField={selectedField || ''}
                          setSelection={() => dispatch(setSelectedField(k))}
                          updateField={updateField}
                        />
                      </TableCell>
                    </TableRow>
                  )),
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
}

EditItem.propTypes = {
  id: PropTypes.number.isRequired,
};

export default EditItem;
