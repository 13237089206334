// (C) Copyright 2020 MediaWink, LLC

export const getNowString = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
  const hr = String(today.getHours()).padStart(2, '0');
  const min = String(today.getMinutes()).padStart(2, '0');
  const yyyy = today.getFullYear();
  const nowString = `${yyyy}-${mm}-${dd}_${hr}${min}`;
  return nowString;
};
