// (C) Copyright 2020 MediaWink, LLC

/* eslint-disable no-await-in-loop */
/* eslint-disable new-cap */

import React, { useEffect, useState } from 'react';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import {
  CheckmarkIcon,
  PauseIcon,
  PlayArrowIcon,
  QuizIcon,
  SkipNextIcon,
  SkipPreviousIcon,
//  StopIcon,
} from '../constants/icons';
import { selectPage } from '../selectors/items';
import { selectImage } from '../selectors/images';
import {
  triggerToggleItemSaga,
} from '../actions/items';
import { stringCompare } from '../utils/text';

function TextToSpeechControls() {
  const [showMic, setShowMic] = useState(false);
  const dispatch = useDispatch();
  const toggleItem = (data) => dispatch(triggerToggleItemSaga(data));
  const page = useSelector((s) => selectPage(s));
  const image = useSelector(
    (state) => (word) => selectImage(state, word),
  );
  const [markedText, setMarkedText] = useState('');
  const [checked, setChecked] = useState(false);
  //  const [showImagesChecked, setShowImagesChecked] = useState(true);
  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  /*
  const handleToggleShowImages = () => {
    setShowImagesChecked(prev => !prev);
  };
  */
  const [state, setState] = useState({
    // cmd: 'skip',
    cmd: false,
    currentOrderIndex: -1,
    finalSpeed: 0.2,
    initialSpeed: 0.8,
    iteration: 0,
    initializationPhase: 3,
    order: [],
    pageToRead: false,
    pauseSeconds: 1,
    readsPerQuote: 10,
    replace: false,
    singing: true,
    sort: true,
    stack: [],
    startedListening: false,
    talking: false,
    text: '',
    words: [],
    wordsSwapped: [],
  });
  // console.log('TextToSpeechControls(), currentOrderIndex', state.currentOrderIndex);
  const allItems = useSelector((s) => s.undo.present.private.entities.items);

  const utterThis = new SpeechSynthesisUtterance();
  // For Android
  // https://talkrapp.com/speechSynthesis.html
  function getVoicesWithLangSubstring(langSubstr) {
    return speechSynthesis.getVoices().filter((v) => v.lang.replace('_', '-')
      .substring(0, langSubstr.length) === langSubstr);
  }
  const voices = getVoicesWithLangSubstring('en');
  if (voices.length >= 1) {
    // console.log('voices', voices);
    [utterThis.voice] = voices;
    // console.log('utterThis.voice', utterThis.voice);
    utterThis.lang = utterThis.voice.lang;
  }

  const synth = window.speechSynthesis;
  /*
   // https://stackoverflow.com/a/8843915
  // same page also shows a library
  // pronouncingjs: https://stackoverflow.com/a/66823243
  /*
  const countSyllables = (input) => {
    pronouncing.syllableCount(pronouncing.phonesForWord(input)[0]);
  };
*/

  const countSyllables = (input) => {
    let word = input;
    word = word.toLowerCase(); // word.downcase!
    // word.sub!(/(?:[^laeiouy]es|ed|[^laeiouy]e)$/, '')
    // word = word.replace(/(?:[^laeiouy]es|ed|[^laeiouy]e)$/, '');
    word = word.replace(/(?:[^laeiouy]e)$/, '');
    word = word.replace(/^y/, ''); // word.sub!(/^y/, '')
    const count = word.match(/[aeiouy]{1,2}/g)?.length || 1; // word.scan(/[aeiouy]{1,2}/).size
    // console.log(input, 'has', count, 'syllables.');
    return count;
  };

  const sleepMs = (milliseconds) => new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
  const sleepSeconds = (seconds) => sleepMs(seconds * 1000);
  // let newPitch = 1;

  const handleBoundary = async (event) => {
    // highlighting: https://codepen.io/lonekorean/pen/QWEegPV
    if (event.name === 'sentence' || event.currentTarget.text === state.text) {
      // we only care about word boundaries
      return;
    }
    // console.log('state', state);
    const wordStart = event.charIndex;
    let wordLength = event.charLength;
    if (wordLength === undefined) {
      // Safari doesn't provide charLength, so fall back to a regex
      // to find the current word and its length
      // (probably misses some edge cases, but good enough for this demo)
      const match = utterThis.text.substring(wordStart).match(/^[a-z\d']*/i);
      wordLength = match[0].length;
    }
    // wrap word in <mark> tag
    const wordEnd = wordStart + wordLength;
    const word = utterThis.text.substring(wordStart, wordEnd);
    // setMarkedText(`${utterThis.text.substring(0, wordStart)}
    // <Box fontWeight='fontWeightMedium' display='inline'>${word}
    // </Box>${utterThis.text.substring(wordEnd)}`);

    // console.log('HIGHLIGHT', markedText);
    setMarkedText(
      <Box sx={{ display: 'flex', flexDirection: 'columnn' }}>
        <Box sx={{ display: 'flex', flexDirection: 'columnn' }}>
          {/*
          <Box>
            {word.replace(/[']/g, '')}
          </Box>
          */}
          <Box>
            <img
              alt={word}
              src={image(word)}
              style={{
                'maxWidth': '100vw',
                'maxHeight': '100vh',
              }}
            />
          </Box>
        </Box>
        <Typography component='div'>
          {utterThis.text.substring(0, wordStart).replace(/[']/g, '')}
          <Box
            style={{
              color: '#000',
              backgroundColor: '#f4d03f',
            }}
            display='inline'
          >
            {word.replace(/[']/g, '')}
          </Box>
          {utterThis.text.substring(wordEnd).replace(/[']/g, '')}
        </Typography>
      </Box>,
    );

    /*
    console.log('boundary:', event);
    console.log('Text---:', event.currentTarget.text);
    console.log(' pitch-:', event.currentTarget.pitch);

    // eslint-disable-next-line no-debugger
    debugger;

    // console.log(' rate', event.currentTarget.rate);
    currentPitchIndex = currentPitchIndex > 3 ? 0 : currentPitchIndex + 1;
    // cancel current text...
    // await sleepMs(1000);
    window.speechSynthesis.pause();
    newPitch = pitches[currentPitchIndex];
    utterThis.pitch = newPitch;
    event.utterance.pitch = newPitch;
    console.log(' pitch to>>', newPitch);
    // and start speaking the rest of the sentence...
    window.speechSynthesis.cancel();
    utterThis.text = utterThis.text.substring(wordEnd);
    synth.speak(utterThis);
    */
  };

  const speak = (
    input,
    saySpeed = 1,
    sayPitch = 1,
    highlight = true,
    force = false,
  ) => new Promise((resolve) => {
    const { cmd, talking } = state;
    if (cmd && !force) {
      console.log(`Skip sing (${input}) because cmd =`, cmd);
      return;
    }
    if (talking === false) {
      console.log('talking === false. Returning from say().');
      /*
      setMarkedText(
        <Typography component='div'>{utterThis.text}</Typography>,
      );
      */
      return;
    }
    console.log(`SPEAK (cmd: ${cmd} pitch: ${sayPitch})> ${input}`);
    const toSpeak = input.replace(':', ' ');

    utterThis.pitch = sayPitch;
    utterThis.text = toSpeak;
    utterThis.rate = saySpeed;

    // Alter pitches
    /*
    let currentPitchIndex = 0;
    const pitches = [0, 1.6, 2.0, 2.0, 1.6];
    */
    /*
    utterThis.addEventListener('pause', async (event) => {
      console.log('pause event', event);
      event.utterance.pitch = newPitch;
    });
    */
    if (!highlight) {
      utterThis.removeEventListener('boundary', handleBoundary, true);
    } else {
      utterThis.addEventListener('boundary', handleBoundary, true);
    }
    // https://stackoverflow.com/questions/53573568/synchronous-execution-in-javascript-with-speechsynthesis-speak/53573824
    synth.speak(utterThis);
    // utterThis.onend = resolve;
    utterThis.addEventListener('end', () => {
      resolve(/* cmd */);
    });
  });

  const forceSay = async (
    input,
    saySpeed = 1,
    sayPitch = 1,
  ) => {
    await speak(input, saySpeed, sayPitch, false, true);
  };

  const say = async (
    input,
    saySpeed = 1,
    sayPitch = 1,
  ) => {
    await speak(input, saySpeed, sayPitch, false, false);
  };

  const highlight = async (input, saySpeed = 1, sayPitch = 1) => {
    await speak(input, saySpeed, sayPitch, true);
  };

  const clearTranscript = (input) => {
    if (input?.resetTranscript) {
      input.resetTranscript();
    }
  };

  const doBack = async (input) => {
    console.log('doBack');
    clearTranscript(input);
    let pageToRead;
    let { currentOrderIndex } = state;
    let count = 0;
    do {
      currentOrderIndex = currentOrderIndex === 0
        ? state.order.length - 1
        : currentOrderIndex - 1;
      pageToRead = allItems[state.order[currentOrderIndex]];
      count += 1;
    } while (pageToRead.completed && count < state.order.length + 1);
    // TODO: If count > state.order.length + 1, all items COMPLETED
    setState({
      ...state,
      currentOrderIndex,
      iteration: 0,
      pageToRead: allItems[state.order[currentOrderIndex]],
    });
  };

  const doNext = async (input) => {
    console.log('doNext');
    clearTranscript(input);
    let pageToRead;
    let { currentOrderIndex } = state;
    let count = 0;
    do {
      // console.log('doNext - currentOrderIndex', currentOrderIndex);
      currentOrderIndex = currentOrderIndex >= state.order.length - 1 ? 0 : currentOrderIndex + 1;
      // console.log('doNext - currentOrderIndex2', currentOrderIndex);
      pageToRead = allItems[state.order[currentOrderIndex]];
      // console.log('doNext - pageToRead', pageToRead);
      // console.log('doNext - state.order', state.order);
      count += 1;
    } while (pageToRead?.completed && count < state.order.length + 1);
    // TODO: If count > state.order.length + 1, all items COMPLETED
    setState({
      ...state,
      cmd: '',
      currentOrderIndex,
      iteration: 0,
      pageToRead,
      wordsSwapped: [],
    });
  };

  const doDone = async (input) => {
    // console.log('doDone', state.pageToRead);
    clearTranscript(input);
    // Mark current as done
    toggleItem({ id: state.pageToRead.id });
    await doNext();
  };

  const doKeepTrying = async (input) => {
    // console.log('doKeepTrying');
    clearTranscript(input);
    const cmd = '';
    setState({
      ...state,
      cmd,
    });
  };

  const doPause = async (input) => {
    // console.log('doPause');
    clearTranscript(input);
    window.speechSynthesis.pause();
    setState((s) => ({
      ...s,
      talking: false,
    }));
  };

  const doSetPause = async (pauseSeconds) => {
    // console.log('doSetPause', pauseSeconds);
    setState({
      ...state,
      pauseSeconds,
    });
  };

  const doStar = async (input) => {
    if (input && state.cmd === 'test') {
      // console.log('doStar, input', input);
      clearTranscript(input);
      const expected = allItems[
        state.pageToRead.items[0]]?.Name?.value
        .replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '');
      // console.log('expected', expected);
      if (input === expected) {
        await forceSay('Exactly!  Great job!');
      } else {
        // Remove identical parts...  Just read the deltas...
        const [expectedDelta, inputDelta] = stringCompare(expected, input);
        await forceSay(`Not quite. I heard "${inputDelta}" but it is actually "${expectedDelta}".`);
      }
      await forceSay('Do you want to mark this as done, keep trying, or go on to the next one?');
      const cmd = 'wait';
      setState({
        ...state,
        cmd,
      });
    }
  };

  const doTest = async (input) => {
    // console.log('doTest');
    clearTranscript(input);
    await forceSay('OK, say the quote to me.');
    const cmd = 'test';
    setState({
      ...state,
      cmd,
    });
  };

  // examples: https://www.npmjs.com/package/react-speech-recognition
  const commands = [
    {
      command: ['back', 'previous', '*back*', '*previous*'],
      callback: doBack,
    },
    {
      command: ['done', 'complete', '*done*', '*complete*'],
      callback: doDone,
    },
    {
      command: ['keep trying', 'continue', '*keep trying*', '*continue*'],
      callback: doKeepTrying,
    },
    {
      command: ['next', 'skip', 'advance', '*next*', '*skip*', '*advance*'],
      callback: doNext,
    },
    {
      command: 'Set pause (to) * (seconds)',
      callback: doSetPause,
    },
    {
      command: ['test', 'check', 'quiz', '*test*', '*check*', '*quiz*'],
      callback: doTest,
    },
    {
      command: '*',
      callback: doStar,
    },
  ];
  const {
    transcript,
    listening,
    // resetTranscript,
    browserSupportsSpeechRecognition,
    browserSupportsContinuousListening,
  } = useSpeechRecognition({ commands });

  useEffect(() => {
    // ALWAYS -- get permissions right at page load
    const {
      initializationPhase,
      sort,
      talking,
    } = state;

    window.speechSynthesis.cancel();
    const readPage = async () => {
      const {
        pageToRead,
        cmd,
        order,
        currentOrderIndex,
        pauseSeconds,
        readsPerQuote,
        replace,
        text,
        iteration,
        initialSpeed,
        finalSpeed,
        wordsSwapped,
      } = state;
      // console.log('readPage, pageToRead', pageToRead, 'iteration', iteration);
      const title = pageToRead?.Name?.value;

      console.log(
        'readPage',
        'visitedPage',
        title,
        'cmd',
        cmd,
        'text',
        text,
      );
      const i = order[currentOrderIndex];
      const newPage = allItems[i];
      if (newPage?.completed === true) {
        doNext();
        return;
      }

      //  CC GG AA  G
      //  C  D  E   F    G    A
      // .0  1  2   3    4    5
      // .5  .8  1.1  1.4  1.7  2.0

      // await say('Verily, Verily, I say unto you.', 1, 0.0);

      /*
      if (state.talking !== 'never gon') {
        return;
      }
      */
      // https://codersblock.com/blog/javascript-text-to-speech-and-its-many-quirks/
      /*
      await say('Verily', 1, 0.0);
      // await say('"C"', 1, 0.0);
      await say('Verily', 1, 1.6);
      await say('I say', 1, 2.0);
      // await say('say', 1, 2.0);
      await say('unto', 1, 1.6);
*/
      /*
      await say('Moses', 1, 0.5);
      await say('1', 1, 1.4);
      await say('39', 1, 1.6);

      await say('Moses', 1, 0.5);
      await say('1', 1, 1.4);
      await say('39', 1, 1.6);
      // await say('"G"', 1, 1.4);
      */
      /*

      await say('"A" "B"', 1, 0.0);
      // await say('"B"', 1, 0.0);
      await say('"C" "D"', 1, 1.6);
      // await say('"D"', 1, 1.6);
      await say('"E" "F"', 1, 2.0);
      // await say('"F"', 1, 2.0);
      await say('"G"', 1, 1.6);

      await say('"C"', 1, 0.0);
      await say('"C"', 1, 0.0);
      await say('"G"', 1, 1.6);
      await say('"G"', 1, 1.6);
      await say('"A"', 1, 2.0);
      await say('"A"', 1, 2.0);
      await say('"G"', 1, 1.6);

      await say('This is a pitch test. 0', 1, 0);
      await say('This is a pitch test. 0.1', 1, 0.1);
      await say('This is a pitch test. 0.2', 1, 0.2);
      await say('This is a pitch test. 0.3', 1, 0.3);
      await say('This is a pitch test. 0.4', 1, 0.4);
      await say('This is a pitch test. 0.5', 1, 0.5);
      await say('This is a pitch test. 0.6', 1, 0.6);
      await say('This is a pitch test. 0.7', 1, 0.7);
      await say('This is a pitch test. 0.8', 1, 0.8);
      await say('This is a pitch test. 0.9', 1, 0.9);

      await say('This is a pitch test. 1.0', 1, 1.0);
      await say('This is a pitch test. 1.1', 1, 1.1);
      await say('This is a pitch test. 1.2', 1, 1.2);
      await say('This is a pitch test. 1.3', 1, 1.3);
      await say('This is a pitch test. 1.4', 1, 1.4);
      await say('This is a pitch test. 1.5', 1, 1.5);
      await say('This is a pitch test. 1.6', 1, 1.6);
      await say('This is a pitch test. 1.7', 1, 1.7);
      await say('This is a pitch test. 1.8', 1, 1.8);
      await say('This is a pitch test. 1.9', 1, 1.9);
      await say('This is a pitch test. 2.0', 1, 2.0);
      */
      // await say('this is my wuh');
      // await say('Notwithstanding his hospitalization, he capitulated');
      // await say('"N", ,  ,"h" "h", , "h" "c", , , ,');
      // await say('"N", "h" "h", "h" "c",');
      // await say('"N"! . ! . ! "h" "h"! . ! . ! . ! . ! . ! "h" "c" . , . , . , . ,');

      // await say('Not . . . his hosp . . . . ., he cap . . . .');
      // await say('"N" blank blank blank "h" "h" hum hum hum hum hum, "h" "c" 1 2 3 4');
      // await say('"N" oo oo oo "h" "h" hum hum hum hum hum, "h" "c" aa aa aa aa');
      // await say('At . ., at- - , at23, at 2 3');

      let originalText = '';
      pageToRead.items.forEach((item) => {
        originalText += `${allItems[item]?.Name?.value} `;
      });

      // console.log('************ title:', pageToRead?.Name?.value);

      let words = originalText
        .replace(/\u2014/g, ';') // long-m: ...my work and my glory-to... (2 words)
        .replace(/[:]/g, ';') // phonetic (':' is ignored phonetically)
        .split(/\s+/);
      words = text.split(/\s+/);
      const wordCount = words.length;
      const replacementsPerRead = Math.floor(wordCount / readsPerQuote) || 1;
      // for (let i = 0; i < wordCount; i += 1) {
      const percent = (wordCount - iteration) / wordCount;
      const range = initialSpeed - finalSpeed;
      const percentOfRange = range * percent;
      const saySpeed = percentOfRange + finalSpeed;
      if (iteration === 0) {
        // await say(pageToRead?.Name?.value);
        // await say(originalText, saySpeed);
        setState({
          ...state,
          text: originalText,
          words,
          iteration: 1,
        });
        return;
      }
      if (text) {
        const more = Math.ceil(percent * readsPerQuote);
        await highlight(`${title}, ${text.replaceAll('^', '').replace(/,+/g, ',')}`, saySpeed);
        if (more) {
          await say(`about ${more} more time${more > 1 ? 's' : ''}.`, saySpeed);
        }
        await sleepSeconds(pauseSeconds);
      }

      const newWordsSwapped = wordsSwapped;
      if (replace) {
        for (let r = 0; r < replacementsPerRead; r += 1) {
          // Next replacement...
          let wordNumber = Math.floor(Math.random() * wordCount);
          let keyword = words[wordNumber];
          let loops = 0;
          while (iteration <= wordCount && wordsSwapped[wordNumber] === true && loops < 2) {
            console.log(wordNumber);
            wordNumber += 1;
            // wordNumber = Math.floor(Math.random() * wordCount);
            if (wordNumber > wordCount - 1) {
              wordNumber = 0;
              loops += 1;
            }
            keyword = words[wordNumber];
          }
          if (iteration < wordCount) {
            const firstLetter = keyword[0];
            const firstLetterFiller = firstLetter === 'p' // pee => puh
              || firstLetter === 'w' // double-U (3 syl) => wuh (1)
              ? `${firstLetter}uh`
              // : `'${firstLetter}'`;
              : `"${firstLetter}"`;
            // console.log('countSyllables(comforting)', countSyllables('comforting'));
            const syllables = countSyllables(keyword);
            let filler = '';
            // console.log('page', page);
            // const ms = 500;
            for (let j = 1; j < syllables; j += 1) {
              // filler += '.!'; // pause: https://stackoverflow.com/a/50944593/1117653
              filler += `^${page?.MemorizationFiller.value}`;
              // filler += `<silence msec="${ms}" />`;
              // filler += `<silence msec="${ms}" />`;
              // - filler += ' hum';
              // filler += `-${j}`;
            }
            const letters = keyword.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '');
            words[wordNumber] = words[wordNumber].replace(
              letters,
              `${firstLetterFiller}${filler}`,
            );
            newWordsSwapped[wordNumber] = true;
            console.log('newWordsSwapped', newWordsSwapped);
          }
        }
      }
      if (iteration < wordCount) {
        setState((s) => ({
          ...s,
          cmd: cmd === 'skip' || cmd === 'back' ? false : cmd,
          wordsSwapped: newWordsSwapped,
          text: words.join(' '),
          words,
          iteration: s.iteration + replacementsPerRead,
        }));
      } else {
        await say(
          // 'Done with that one.
          // Do you want to check this verse, or skip and go to the next one?',
          'Done with that one.  On to the next one.',
        );
        doNext();
      }
    };

    const getOrder = (orderMap, currentPage) => {
      console.log('getOrder()');
      let newOrder = orderMap;
      for (let i = 0; i < currentPage.items.length; i += 1) {
        const index = currentPage.items[i];
        const newPage = allItems[index];
        console.log('getOrder() newPage', newPage);
        if (newPage?.items.length > 0) {
          console.log(' call getOrder()');
          newOrder = { ...newOrder, ...getOrder(newOrder, newPage) };
          // newOrder = getOrder(newOrder, newPage);
          console.log(' gotBack newOrder', newOrder);
        } else {
          // console.log('add', allItems[newPage?.parent]?.Name?.value);
          console.log(' return [{}]', newPage.parent);
          if (!newOrder[newPage.parent]) {
            newOrder[newPage.parent] = {
              id: newPage.parent,
              length: newPage?.Name?.value?.length,
            };
          }
          // return newOrder;
        }
      }
      console.log('returning newOrder', newOrder);
      return newOrder;
    };

    const { cmd, currentOrderIndex, pageToRead } = state;
    if (currentOrderIndex < 0 && pageToRead) {
      console.log('page', pageToRead);
      const orderMapObject = getOrder({}, pageToRead);
      const orderMap = new Map(Object.entries(orderMapObject));
      console.log('getOrder (map) GOT', orderMap);
      let order = Array.from(orderMap, ([, value]) => (value));
      console.log('order from orderMap', order);
      if (sort) {
        order = order.sort((a, b) => {
          if (a.length > b.length) {
            return 1;
          }
          if (b.length > a.length) {
            return -1;
          }
          return 0;
        });
      }

      console.log('order after potential sort', order);
      order = order.map((a) => Number(a.id));
      setState((s) => ({
        ...s,
        currentOrderIndex: 0,
        order,
        pageToRead: allItems[order[0]],
      }));
      return;
    }
    if (cmd === 'skip') {
      // Go to next page
      // const newPage = 123?;
      setState((s) => ({
        ...s,
        // pageToRead: newPage,
        iterator: 0,
        cmd: false,
      }));
      return;
    }
    // console.log('((( useEffect. pageToRead:', pageToRead?.Name?.value);
    if (pageToRead === false) {
      // console.log('pageToRead === false. Return.');
      return;
    }

    const initialize = async (phase) => {
      console.log('******** initializationPhase', phase);
      await sleepMs(100);
      let newTalking = talking;
      if (phase === 3) {
        window.speechSynthesis.resume();
        newTalking = true;
      }
      if (phase === 2) {
        window.speechSynthesis.pause();
        newTalking = false;
      }
      if (phase === 1) {
        window.speechSynthesis.resume();
        newTalking = true;
      }
      setState((s) => ({
        ...s,
        initializationPhase: s.initializationPhase - 1,
        talking: newTalking,
      }));
    };

    if (initializationPhase > 0) {
      initialize(initializationPhase);
      return;
    }

    try {
      readPage();
    } catch (err) {
      console.log('err', err);
    }
    console.log('))) end useEffect.');
    // eslint-disable-next-line
  }, [state]);

  const step = (stepRate) => {
    if (stepRate > 1) {
      return 1;
    }
    if (stepRate > 0.1) {
      return 0.1;
    }
    if (stepRate > 0.01) {
      return 0.01;
    }
    return 0.001;
  };
  if (!browserSupportsSpeechRecognition) {
    return <span>Browser does not support speech recognition.</span>;
  }
  const {
    cmd, initialSpeed, talking, finalSpeed, pauseSeconds, readsPerQuote,
  } = state;
  const handleToggleMic = () => {
    setShowMic((prev) => {
      if (!prev) {
        SpeechRecognition.startListening({ continuous: true });
      } else if (state.startedListening && prev) {
        SpeechRecognition.stopListening();
      }
      setState((s) => ({
        ...s,
        startedListening: !prev,
      }));
      return !prev;
    });
  };
  return (
    <>
      <Box>
        <FormControlLabel
          control={<Switch checked={showMic} onChange={handleToggleMic} />}
          id='Mic'
          key='Mic'
          label='Mic'
        />
      </Box>
      {page?.MemorizationFiller && (
        <>
          <div>
            <p>
              <Typography>
                Microphone:
                {' '}
                {listening ? 'on' : 'off'}
              </Typography>
            </p>
            <p>{transcript}</p>
          </div>
          {!browserSupportsContinuousListening && (
            <Typography>
              Continuos Listening NOT supported on this browser.
            </Typography>
          )}
          <Typography>
            cmd:
            {' '}
            {cmd}
          </Typography>
          <TextField
            pad={20}
            label='Initial Speed'
            InputLabelProps={{
              shrink: true,
            }}
            type='number'
            value={initialSpeed}
            variant='outlined'
            inputProps={{
              maxLength: 3,
              step: step(initialSpeed),
            }}
            onChange={(e) => {
              const newValue = parseFloat(e.target.value);
              // console.log('newValue', newValue);
              setState((s) => ({
                ...s,
                initialSpeed: newValue,
              }));
            }}
          />
          <TextField
            pad={20}
            label='Final Speed'
            InputLabelProps={{
              shrink: true,
            }}
            type='number'
            value={finalSpeed}
            variant='outlined'
            inputProps={{
              maxLength: 3,
              step: step(finalSpeed),
            }}
            onChange={(e) => {
              const newValue = parseFloat(e.target.value);
              // console.log('newValue', newValue);
              setState((s) => ({
                ...s,
                finalSpeed: newValue,
              }));
            }}
          />
          <TextField
            pad={20}
            label='Pause (in Seconds)'
            InputLabelProps={{
              shrink: true,
            }}
            type='number'
            value={pauseSeconds}
            variant='outlined'
            inputProps={{
              maxLength: 3,
              step: step(pauseSeconds),
            }}
            onChange={(e) => {
              const newValue = parseFloat(e.target.value);
              // console.log('newValue', newValue);
              setState((s) => ({
                ...s,
                pauseSeconds: newValue,
              }));
            }}
          />
          <TextField
            pad={20}
            label='Reads Per Quote'
            InputLabelProps={{
              shrink: true,
            }}
            type='number'
            value={readsPerQuote}
            variant='outlined'
            inputProps={{
              maxLength: 3,
              step: step(readsPerQuote),
            }}
            onChange={(e) => {
              const newValue = parseFloat(e.target.value);
              // console.log('newValue', newValue);
              setState((s) => ({
                ...s,
                readsPerQuote: newValue,
              }));
            }}
          />
          <div id='text' />
          <Button
            onClick={() => doBack()}
            startIcon={<SkipPreviousIcon />}
            variant='contained'
          >
            Back
          </Button>
          {!talking && (
            <Button
              onClick={() => {
                setState((s) => ({
                  ...s,
                  talking: true,
                  pageToRead: s.pageToRead || page,
                }));
              }}
              startIcon={<PlayArrowIcon />}
              variant='contained'
            >
              Play
            </Button>
          )}
          {talking && (
            <Button
              onClick={() => {
                doPause();
              }}
              startIcon={<PauseIcon />}
              variant='contained'
            >
              Pause
            </Button>
          )}
          <Button
            onClick={() => doNext()}
            startIcon={<SkipNextIcon />}
            variant='contained'
          >
            Next
          </Button>
          <Button
            onClick={() => doTest()}
            startIcon={<QuizIcon />}
            variant='contained'
          >
            Test
          </Button>
          <Button
            onClick={() => doDone()}
            startIcon={<CheckmarkIcon />}
            variant='contained'
          >
            Done
          </Button>
          {/* talking && (
            <Button
              onClick={() => {
                window.speechSynthesis.cancel();
                setState(s => ({
                  ...s,
                  pageToRead: false,
                  talking: false,
                }));
                // throw new Error('stopping audio');
              }}
              startIcon={<StopIcon />}
              variant='contained'
            >
              Stop
            </Button>
            ) */}
          <FormControlLabel
            control={(
              <Checkbox
                checked={state.sort}
                name='sort'
                color='primary'
                onChange={(event) => {
                  setState((s) => ({
                    ...s,
                    sort: event.target.checked,
                  }));
                }}
              />
            )}
            label='Shortest first'
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={state.replace}
                name='replace'
                color='primary'
                onChange={(event) => {
                  setState((s) => ({
                    ...s,
                    replace: event.target.checked,
                  }));
                }}
              />
            )}
            label='Replace words'
          />
          <FormControlLabel
            control={<Switch checked={checked} onChange={handleChange} />}
            id='Instructions'
            key='Instructions'
            label='Instructions'
          />
          <Collapse in={checked}>
            <Typography>
              NOTE: Be safe, especially while driving!
              Do all you can, like starting the speech, before you start driving.
              Instructions for memorizing: Go to a quote or group of
              quotes you want to memorize, then press the Play icon. I will read a
              quote to you, then keep repeating it, replacing a
              random word with the first letter (or sound) of that word,
              if that option is checked.
              I will then tell you how many more times we will go over the quote.
              When we get done with a quote, or at any time, you can
              say CHECK or TEST to see if you have it memorized,
              or say NEXT or BACK to skip to the next quote or go back.
              I will begin each quote at the Initial speed and slowly change the speed to
              the Final speed. In order for me to hear you on an iPhone,
              you will need to use Safari (the compass icon), and Chrome when not
              on an iPhone. Also, if you hear no sound when you press play, just
              pause it and try again. Enjoy!
            </Typography>
          </Collapse>
          {/*
          <FormControlLabel
            control={<Switch checked={showImagesChecked} onChange={handleToggleShowImages} />}
            label='Show Images'
          >
            <Collapse in={showImagesChecked}>
          */}
            {markedText}
          {/*
            </Collapse>
          </FormControlLabel>
          */}
        </>
      )}
    </>
  );
}

export default TextToSpeechControls;
