// (C) Copyright 2020 MediaWink, LLC

import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { trackPromise } from 'react-promise-tracker';

import Spinner from './spinner/spinner';
import ItemListsTree from './ItemListsTree';
import ItemListsTable from './ItemListsTable';
import ItemListsTableGroups from './ItemListsTableGroups';
import {
  selectPrivateBreadcrumbs,
  selectLastColumnOnCurrentPage,
  selectNextId,
  selectPage,
  selectPageType,
  selectSettings,
} from '../selectors/items';
import { selectLogin } from '../selectors/session';
import {
  triggerAddItemToBottomSaga,
  triggerSetFirstVisibleColumnSaga,
} from '../actions/items';
import ErrorBoundary from './ErrorBoundary';
import TableControls from './TableControls';
import { selectSuggestedNewNameForItem } from '../selectors';

// const MAX_NEW_DAYS = 7;
function ItemLists({
  debug,
}) {
  const step = useRef();
  const [status, setStatus] = useState('Loading...');
  const [lastId, setLastId] = useState(0);
  const page = useSelector(selectPage);
  const settings = useSelector(selectSettings);
  const last = useSelector(selectLastColumnOnCurrentPage);
  const newName = useSelector((state) => (item) => selectSuggestedNewNameForItem(state, item));
  const breadcrumbs = useSelector(selectPrivateBreadcrumbs);
  const login = useSelector(selectLogin);
  const type = useSelector(selectPageType);
  const nextId = useSelector(selectNextId);
  const dispatch = useDispatch();
  const addItemToBottom = (data, resolve, reject) => dispatch(
    triggerAddItemToBottomSaga(data, resolve, reject),
  );
  const setFirstVisibleColumn = (data, resolve, reject) => dispatch(
    triggerSetFirstVisibleColumnSaga(data, resolve, reject),
  );

  const BYPASS_AUTOMATIC_NEW_DATES = true;

  const isLast = (value) => {
    if (BYPASS_AUTOMATIC_NEW_DATES) {
      return true;
    }
    let done = false;
    if (!value) {
      return false;
    }
    const todayDateString = new Date().toLocaleDateString();
    const words = value.split(' ');
    words.forEach((word) => {
      if (word.includes('/')) {
        const newDateWord = word;
        const newDate = new Date(newDateWord);
        const newDayOfWeek = moment(newDateWord)?.format('dddd').toLowerCase();
        // console.log('newDayOfWeek', newDayOfWeek);
        const todayDate = new Date(todayDateString);
        // console.log('settings?.automation', settings?.automation);
        // Last?
        // console.log('todayDate', todayDate);
        // console.log('todayDate + 7', todayDate + 7);
        // console.log('todayDate.getDate() + 7', todayDate.getDate() + 7);
        // console.log('newDate', newDate);
        // console.log('newDate > todayDate + 7', newDate > todayDate + 7);
        if (newDate >= todayDate) {
          // https://stackoverflow.com/a/563442/11962478
          const inAWeek = new Date(todayDate);
          inAWeek.setDate(inAWeek.getDate() + 7);
          if (newDate > inAWeek) {
            // console.log("Don't autocreate beyond a week out");
            done = true; // Don't autocreate beyond a week out
          }
          if (settings?.automation?.toLowerCase
            && settings?.automation?.toLowerCase() === newDayOfWeek) {
            // console.log(' newDayOfWeek -> done');
            done = true;
          }
          if (settings?.automation?.toLowerCase
            && settings?.automation?.toLowerCase() === 'today') {
            // console.log(' today -> done');
            done = true;
          }
          if (!settings?.automation?.toLowerCase) {
            // console.log(' today -> done');
            done = true;
          }
        }
      }
      return undefined;
    });
    return done;
  };

  useEffect(() => {
    const value2 = newName(page);
    step.current = `ADDING ${value2}...`;
  });

  useEffect(() => {
    const sleepMs = (milliseconds) => new Promise((resolve) => {
      setTimeout(resolve, milliseconds);
    });
    let done = false;
    let lastDayAdded = false;
    // let count = 0;
    if (page.firstVisibleColumn === undefined) {
      setFirstVisibleColumn({ id: page.id, firstVisibleColumn: 0 });
    }
    // See what the last item is--see if today is in it--while not, create new day
    // console.log('page', page);
    // console.log('last', last);
    const lastName = last && last?.Name?.value;
    // console.log('lastName', lastName);
    // console.log('settings?.automation', settings?.automation);
    if (settings && settings.automation && lastName
      && lastName?.includes('/') && !/[a-zA-Z]/i.test(lastName)) {
      // const todayDayOfWeek = moment(todayDateString).format('dddd').toLowerCase();
      // console.log('todayDayOfWeek', todayDayOfWeek);
      // console.log('count', count);
      // if (count <= MAX_NEW_DAYS /* && !lastName.includes(todayDateString) */) {
      // Add new day...
      const value = newName(page);
      // console.log(`> Adding ${value}...`);
      setStatus(`Adding ${value}...`);
      step.current = `Adding ${value}...`;
      sleepMs(1);
      // console.log('add new day', value, 'after last', last?.Name?.value);
      done = isLast(last?.Name?.value);
      // console.log('done', done);
      lastDayAdded = isLast(value);
      // console.log('lastDayAdded', lastDayAdded);
      // done = true; // TEMP
      if (done) {
        // console.log('DONE--stop.');
        return;
      }
      const tail = breadcrumbs.slice(-1) !== [page.id] ? [page.id] : undefined;
      const newCrumbs = [
        ...breadcrumbs,
        ...(tail || []),
      ];
      const data = {
        ...login,
        item_id: nextId,
        parent: page.id,
        breadcrumbs: newCrumbs,
        nextId,
        value,
        item: {
          Name: {
            type: 'Text',
            value,
          },
          type: type === 'All' ? value : type,
          group: undefined,
        },
      };
      // console.log('nextId', nextId);
      // console.log('lastId', lastId);
      if (nextId !== lastId) {
        let p;
        trackPromise(
          p = new Promise((resolve, reject) => {
            // new Promise(resolve => setTimeout(resolve, milliseconds));
            addItemToBottom(data, resolve, reject);
            const shiftData = {
              id: page.id,
              firstVisibleColumn:
                  page.items.length - 1 - page.columnsPerPage + 1,
            };
            // if (value.includes(todayDateString)) {
            if (lastDayAdded) {
              // console.log('SET FIRST VISIBLE COLUMN-value', value);
              setFirstVisibleColumn(shiftData); // , resolve, reject);
            }
          }),
        );
        p.finally(() => {
          console.log('Finally', value);
          setStatus(`Loading...
If this takes longer than usual (10 seconds or so) you may need to refresh your browser...`);
          step.current = `Adding ${value}...`;
        });
      // } else {
        // console.log('SKIPPED because lastId === nextId', lastId, nextId);
      }
      setLastId(nextId);
      // count += 1;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, status]);
  return (
    <ErrorBoundary>
      <Spinner text={status} />
      {/*
      STATUS:
      {step.current}
      */}
      <TableControls />
      {!page.tableView && (
      <ItemListsTree
        hideAddTop={page.hideAddTop}
        hideAddBottom={page.hideAddBottom}
        hideDelete={page.hideDelete}
        hideEdit={page.hideEdit}
        hideCheckmark={page.hideCheckmark}
        debug={debug}
      />
      )}
      {page.tableView && !page.tableViewGroups && (
      <ItemListsTable
        hideAddTop={page.hideAddTop}
        hideAddBottom={page.hideAddBottom}
        hideDelete={page.hideDelete}
        hideEdit={page.hideEdit}
        hideCheckmark={page.hideCheckmark}
        debug={debug}
      />
      )}
      {page.tableView && page.tableViewGroups && (
      <ItemListsTableGroups
        hideAddTop={page.hideAddTop}
        hideAddBottom={page.hideAddBottom}
        hideDelete={page.hideDelete}
        hideEdit={page.hideEdit}
        hideCheckmark={page.hideCheckmark}
        firstVisibleColumn={page.firstVisibleColumn}
        columnsPerPage={page.columnsPerPage}
        debug={debug}
      />
      )}
    </ErrorBoundary>
  );
}

ItemLists.propTypes = {
  debug: PropTypes.bool.isRequired,
};

export default ItemLists;
