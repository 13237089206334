// (C) Copyright 2020 MediaWink, LLC

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import AppMenu from './AppMenu';
import {
  triggerPlayAppSaga,
  triggerSetPublicBreadcrumbsSaga,
} from '../actions/items';
import {
  selectBreadcrumbs,
  selectHtml,
  selectScreenListItemsArray,
  selectScreenListId,
} from '../selectors';

function PlayApp({
  setShowList,
  setShowApp,
}) {
  // console.log('playApp');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [input, setInput] = useState('0.00');
  const html = useSelector(selectHtml);
  const screenItems = useSelector(selectScreenListItemsArray);
  const screenListId = useSelector(selectScreenListId);
  const breadcrumbs = useSelector(selectBreadcrumbs);
  const startApp = (data) => dispatch(
    triggerPlayAppSaga(data, navigate),
  );
  const setPublicBreadcrumbsSaga = (breadcrumbsIn, navigateIn) => dispatch(
    triggerSetPublicBreadcrumbsSaga({ breadcrumbs: breadcrumbsIn, navigate: navigateIn }),
  );
  const buttonHandler = (val) => {
    console.log('buttonHanlder() val -- TBD:', val);

    /*
    console.log('textField', textField);
    setTarget(val);
    vars = setAndSaveVariable('DEPOSIT_AMOUNT', textField.DEPOSIT_AMOUNT || 0);
    vars = setAndSaveVariable('PURCHASE_AMOUNT', textField.PURCHASE_AMOUNT || 0);
    setTick(prev => prev + 1);
    // eslint-disable-next-line arrow-body-style
    textField = { ...textField, 'PURCHASE_AMOUNT': '', 'DEPOSIT_AMOUNT': '' };
  */

    // FIXes Button press, may break BudgetHub (SPA)
    const s = screenListId;
    // console.log({ screenItems });
    // console.log({ s });
    for (let n = 0; n < screenItems.length; n += 1) {
      const i = screenItems[n];
      if (i.Name.value === val) {
        const newCrumbs = [...breadcrumbs.slice(0, -2), s, i.id];
        // const nav = select(selectPrivateBreadcrumbsPathFromArray, newCrumbs);
        console.log({
          newCrumbs,
          location,
          // nav,
          navigate,
        });
        setPublicBreadcrumbsSaga(newCrumbs, navigate);
        //        setPrivateBreadcrumbsSaga(newCrumbs);
        console.log({ return: true });
        return;
      }
    }
  };

  const tf = (
    <TextField
      id='value'
      key='tf'
      label='title'
      defaultValue='list'
      initialValue={input}
      value={input}
    />
  );
  useEffect(() => {
    // console.log('playApp useEffect()');
    // if (window.location.pathname === '/app_MOVED')
    // {
    console.log({
      'startApp': true, dispatch, input, setInput,
    });
    startApp({
      dispatch, inputObj: { input }, setInput, tfObj: { tf },
    });
    // } else {
    // console.log('NOT starting app.  Location:', window.location.path);
    // }
    // console.log({ html });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbs]);
  // console.log({ html });
  // console.log('playApp return:');
  // console.log({ tf });
  // console.log({ html });
  return (
    <Box key='PlayApp'>
      <AppMenu
        goTo={buttonHandler}
        setShowList={setShowList}
        setShowApp={setShowApp}
      />
      {html?.props ? html : 'Loading...'}
    </Box>
  );
}

PlayApp.propTypes = {
  setShowList: PropTypes.func.isRequired,
  setShowApp: PropTypes.func.isRequired,
};

export default PlayApp;
