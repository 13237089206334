// (C) Copyright 2020 MediaWink, LLC

import { call, takeLatest } from 'redux-saga/effects';

import achievehubAPI from '../apis/achievehubAPI';
import {
  UNSPLASH_SAGA,
} from '../action-types';

export function* unsplashSaga(action) {
  console.log('unsplash saga', action);
  const { data, resolve, reject } = action;
  const { setter } = data;
  try {
    const payload = {
      word: data.word,
    };

    const images = yield call(achievehubAPI.do, 'unsplash_find', payload);
    console.log('images', images);
    if (resolve) {
      resolve(images);
    }
    setter(images.data);
  } catch (e) {
    if (reject) {
      reject();
    }
    console.warn('error', e);
  }
}

export function* watchUnsplashSagas() {
  yield takeLatest(UNSPLASH_SAGA, unsplashSaga);
}
