// (C) Copyright 2020 MediaWink, LLC

import {
  ADD_CHILD,
  ADD_CHILD_SAGA,
  ADD_DIRTY,
  ADD_FIELD_PRIVATE,
  ADD_FIELD_SAGA,
  ADD_ITEM_PRIVATE,
  ADD_ITEM_TO_BOTTOM_SAGA,
  ADD_ITEM_TO_TOP_SAGA,
  ADJUST,
  CLEAR_DIRTY_BIT_FOR_ITEM,
  DELETE_FIELD_PRIVATE,
  DELETE_FIELD_SAGA,
  DELETE_DIRTY,
  DOWN_ITEM,
  DOWN_ITEM_SAGA,
  GET_IMAGE_SAGA,
  PLAY_APP_SAGA,
  POP_PRIVATE_BREADCRUMB,
  POP_PUBLIC_BREADCRUMB,
  POP_PRIVATE_BREADCRUMB_SAGA,
  POP_PUBLIC_BREADCRUMB_SAGA,
  POP_RESULT,
  PUSH_PUBLIC_BREADCRUMB,
  PUSH_PRIVATE_BREADCRUMB,
  PUSH_PUBLIC_BREADCRUMB_SAGA,
  PUSH_PRIVATE_BREADCRUMB_SAGA,
  PUSH_BREADCRUMB_SAGA,
  PUSH_RESULT,
  PUT_AND_UPDATE_SAGA,
  MOVE_ITEM_PRIVATE,
  MOVE_PAGE_PRIVATE,
  MOVE_ITEM_SAGA,
  MOVE_PAGE_SAGA,
  REMOVE_ITEM_PRIVATE,
  REMOVE_ITEM_SAGA,
  RENAME_ITEM,
  SET_AND_SAVE_VARIABLE_SAGA,
  SET_HTML,
  SET_PRIVATE_BREADCRUMBS_SAGA,
  SET_PUBLIC_BREADCRUMBS_SAGA,
  SET_PRIVATE_BREADCRUMBS,
  SET_PUBLIC_BREADCRUMBS,
  SET_SELECTION,
  SET_SELECTED_FIELD,
  SET_COLUMNS_PER_PAGE_SAGA,
  SET_FIRST_VISIBLE_COLUMN_SAGA,
  SET_SHOW_VIEW_SETTINGS_SAGA,
  SET_NEXT_ID,
  SET_HIDE_ADD_BOTTOM_SAGA,
  SET_HIDE_ADD_TOP_SAGA,
  SET_SETTINGS,
  SET_TABLE_VIEW_GROUPS_SAGA,
  SET_TABLE_VIEW_SAGA,
  SET_VISIBILITY_FILTER,
  SHOW_ALL,
  SHOW_COMPLETED,
  SHOW_ACTIVE,
  SYNC_ITEMS_FROM_GOOGLE_DOC_SAGA,
  TOGGLE_ITEM_PRIVATE,
  TOGGLE_ITEM_SAGA,
  UP_ITEM,
  UP_ITEM_SAGA,
  UPDATE_ITEM,
  UPDATE_PUBLIC_ITEM,
  UPDATE_ITEM_SAGA,
  UPDATE_CONTENT_SAGA,
  UPDATE_FIELD,
  UPDATE_FIELD_SAGA,
  UPDATE_ITEM_ID,
} from '../action-types/items';

export const addChild = (breadcrumbs, id, Name) => ({
  type: ADD_CHILD, breadcrumbs, id, Name,
});
export const addField = (data, resolve, reject) => ({
  type: ADD_FIELD_PRIVATE, payload: data, resolve, reject,
});
export const addFieldAsync = (data, resolve, reject) => ({
  type: ADD_FIELD_SAGA, payload: data, resolve, reject,
});
export const addItem = (breadcrumbs, Name, nextId) => ({
  type: ADD_ITEM_PRIVATE, breadcrumbs, Name, nextId,
});
export const triggerAddItemToBottomSaga = (data, resolve, reject) => ({
  type: ADD_ITEM_TO_BOTTOM_SAGA, data, resolve, reject,
});
export const addItemToTopAsync = (data) => ({ type: ADD_ITEM_TO_TOP_SAGA, data });
export const adjustNextIdAfterRemove = (removedId) => ({ type: ADJUST, removedId });
export const clearDirtyBitForItem = (data) => ({ type: CLEAR_DIRTY_BIT_FOR_ITEM, data });
export const deleteField = (data, resolve, reject) => ({
  type: DELETE_FIELD_PRIVATE, payload: data, resolve, reject,
});
export const deleteFieldAsync = (data, resolve, reject) => ({
  type: DELETE_FIELD_SAGA, payload: data, resolve, reject,
});
export const downItem = (data) => ({ type: DOWN_ITEM, data });
export const popPrivateBreadcrumb = (count) => ({ type: POP_PRIVATE_BREADCRUMB, count });
export const popPublicBreadcrumb = (count) => ({ type: POP_PUBLIC_BREADCRUMB, count });
export const popResult = (count) => ({ type: POP_RESULT, count });
export const addDirty = (item) => ({ type: ADD_DIRTY, item });
export const deleteDirty = (item) => ({ type: DELETE_DIRTY, item });
export const pushPrivateBreadcrumb = (newItem) => ({ type: PUSH_PRIVATE_BREADCRUMB, newItem });
export const pushPublicBreadcrumb = (newItem) => ({ type: PUSH_PUBLIC_BREADCRUMB, newItem });
export const pushResult = (newItem) => ({ type: PUSH_RESULT, newItem });
export const moveItem = (data) => ({ type: MOVE_ITEM_PRIVATE, data });
export const movePage = (data, resolve, reject) => ({
  type: MOVE_PAGE_PRIVATE, data, resolve, reject,
});
export const removeItem = (data) => ({ type: REMOVE_ITEM_PRIVATE, data });
export const setPrivateBreadcrumbs = (data) => ({ type: SET_PRIVATE_BREADCRUMBS, data });
export const setPublicBreadcrumbs = (data) => ({ type: SET_PUBLIC_BREADCRUMBS, data });
export const setHtml = (data) => ({ type: SET_HTML, data });
export const setSettings = (data) => ({ type: SET_SETTINGS, data });
export const triggerAddChildSaga = (breadcrumbs, id, Name) => ({
  type: ADD_CHILD_SAGA, breadcrumbs, id, Name,
});
export const triggerMoveItemSaga = (data) => ({ type: MOVE_ITEM_SAGA, data });
export const triggerMovePageSaga = (data, resolve, reject) => ({
  type: MOVE_PAGE_SAGA, data, resolve, reject,
});
export const triggerRemoveItemSaga = (data) => ({ type: REMOVE_ITEM_SAGA, data });
export const triggerDownItemSaga = (data) => ({ type: DOWN_ITEM_SAGA, data });
export const triggerPopPrivateBreadcrumbSaga = (data) => (
  { type: POP_PRIVATE_BREADCRUMB_SAGA, data });
export const triggerPlayAppSaga = (data, navigate) => ({ type: PLAY_APP_SAGA, data, navigate });
export const triggerPopPublicBreadcrumbSaga = (data) => (
  { type: POP_PUBLIC_BREADCRUMB_SAGA, data });
export const triggerSetAndSaveVariableSaga = (data) => ({ type: SET_AND_SAVE_VARIABLE_SAGA, data });
export const triggerPushBreadcrumbSaga = (data) => (
  { type: PUSH_BREADCRUMB_SAGA, data });

export const triggerPushPrivateBreadcrumbSaga = (data) => (
  { type: PUSH_PRIVATE_BREADCRUMB_SAGA, data });

export const triggerPushPublicBreadcrumbSaga = (data) => (
  { type: PUSH_PUBLIC_BREADCRUMB_SAGA, data });
export const triggerPutAndUpdateSaga = (data) => ({ type: PUT_AND_UPDATE_SAGA, data });
export const triggerSetColumnsPerPageSaga = (data) => ({ type: SET_COLUMNS_PER_PAGE_SAGA, data });
export const triggerSetShowViewSettingsSaga = (data) => (
  { type: SET_SHOW_VIEW_SETTINGS_SAGA, data });
export const triggerSetFirstVisibleColumnSaga = (data, resolve, reject) => ({
  type: SET_FIRST_VISIBLE_COLUMN_SAGA, data, resolve, reject,
});
export const triggerSetPrivateBreadcrumbsSaga = (data) => (
  { type: SET_PRIVATE_BREADCRUMBS_SAGA, data });
export const triggerSetPublicBreadcrumbsSaga = (data) => (
  { type: SET_PUBLIC_BREADCRUMBS_SAGA, data });
export const triggerSetHideAddBottomSaga = (data) => ({ type: SET_HIDE_ADD_BOTTOM_SAGA, data });
export const triggerSetHideAddTopSaga = (data) => ({ type: SET_HIDE_ADD_TOP_SAGA, data });
export const triggerSetTableViewGroupsSaga = (data) => ({ type: SET_TABLE_VIEW_GROUPS_SAGA, data });
export const triggerSetTableViewSaga = (data) => ({ type: SET_TABLE_VIEW_SAGA, data });
export const triggerGetImageSaga = (url, func) => ({
  type: GET_IMAGE_SAGA, url, func,
});
export const triggerSyncItemsFromGoogleDocSaga = (breadcrumbs, id, Name, SyncFile, navigate) => ({
  type: SYNC_ITEMS_FROM_GOOGLE_DOC_SAGA, breadcrumbs, id, Name, SyncFile, navigate,
});
export const triggerToggleItemSaga = (data) => ({ type: TOGGLE_ITEM_SAGA, data });
export const triggerUpItemSaga = (data) => ({ type: UP_ITEM_SAGA, data });
export const triggerUpdateItemSaga = (data) => ({ type: UPDATE_ITEM_SAGA, data });
export const triggerUpdateContentSaga = (data) => ({ type: UPDATE_CONTENT_SAGA, data });
export const triggerUpdateFieldSaga = (data) => ({ type: UPDATE_FIELD_SAGA, data });
export const renameItem = (id, Name, newName) => ({
  type: RENAME_ITEM, id, Name, newName,
});
export const setSelection = (breadcrumbs, id) => ({ type: SET_SELECTION, breadcrumbs, id });
export const setSelectedField = (field) => ({ type: SET_SELECTED_FIELD, field });
export const setNextId = (nextId) => ({ type: SET_NEXT_ID, id: nextId });
export const setVisibilityFilter = (filter) => ({ type: SET_VISIBILITY_FILTER, filter });
export const toggleItem = (data) => ({ type: TOGGLE_ITEM_PRIVATE, data });
export const updateItemId = (breadcrumbs, oldId, newId) => ({
  type: UPDATE_ITEM_ID, breadcrumbs, oldId, newId,
});
export const updateField = (data) => ({ type: UPDATE_FIELD, data });
export const updateItem = (data) => ({ type: UPDATE_ITEM, data });
export const updatePublicItem = (data) => ({ type: UPDATE_PUBLIC_ITEM, data });
export const upItem = (data) => ({ type: UP_ITEM, data });
export const VisibilityFilters = { SHOW_ALL, SHOW_COMPLETED, SHOW_ACTIVE };
