// (C) Copyright 2020 MediaWink, LLC

// eg:
// https://policies.google.com/privacy

import React from 'react';
import {
  Box,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

function Privacy() {
  return (
    <Box>
      <h1>Privacy Policy</h1>
      <Typography>
        This is the privacy policy for AchiveHub.com.
      </Typography>
      <br />
      <Typography>
        Effective January 1, 2022
      </Typography>
      <br />
      <Typography>
        When you use our services, you’re trusting us with your information.
        We understand this is a big responsibility and work hard to protect your information.
        To that end:
      </Typography>
      <br />
      <Typography component='span'>
        <ul>
          <li>
            No personal information is sold or given to 3rd parties.
          </li>
          <li>
            We only use
            <Link to='/cookies'>Cookies</Link>
            {' '}
            if you choose to remember your email address and password for
            quicker access next time you use AchieveHub, and do not use them for other purposes.
          </li>
          <li>
            <a href='https://mouseflow.com'>Mouseflow</a>
            {' '}
            is a tool used to record
            the actions of users to help better the product.  If you do not agree
            to this, do not use the product, or go to
            <a href='https://mouseflow.com'>Mouseflow.com</a>
            {' '}
            and opt-out.
          </li>
        </ul>
      </Typography>
    </Box>
  );
}

export default Privacy;
