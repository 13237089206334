// (C) Copyright 2020 MediaWink, LLC

import {
  CLEAR_COOKIE_SAGA,
  COMPRESS_DATABASE_SAGA,
  CONNECT_SAGA,
  CREATE_ACCOUNT_SAGA,
  IMPORT_JSON_PRIVATE,
  LOGIN,
  LOGIN_SAGA,
  LOGOUT_SAGA,
  LOGOUT,
  RESET_PASSWORD_SAGA,
  DELETE_ACCOUNT_SAGA,
  SET_KEEP_SIGNED_IN,
  SET_AUTOMATION_SETTING_SAGA,
  SET_BACKUP_SETTING_SAGA,
  SET_ALLOWLIST_SETTING_SAGA,
  SYNC_FROM_DATABASE_SAGA,
  SWITCH_USER_SAGA,
} from '../action-types';

export const importJson = (json) => ({ type: IMPORT_JSON_PRIVATE, json });
export const setKeepSignedIn = (keepSignedIn) => ({ type: SET_KEEP_SIGNED_IN, keepSignedIn });
export const login = (data) => ({ type: LOGIN, data });
export const logout = (data) => ({ type: LOGOUT, data });

// Trigger sagas
export const connectAsync = (session, resolve, reject) => ({
  type: CONNECT_SAGA, payload: session, resolve, reject,
});
export const triggerLoginSaga = (session, resolve, reject, navigate) => ({
  type: LOGIN_SAGA, payload: session, resolve, reject, navigate,
});
export const resetPasswordAsync = (data, resolve, reject, nav) => ({
  type: RESET_PASSWORD_SAGA, data, resolve, reject, nav,
});
export const triggerDeleteAccountSaga = (data, resolve, reject) => ({
  type: DELETE_ACCOUNT_SAGA, data, resolve, reject,
});
export const triggerSetAutomationSettingSaga = (data, resolve, reject, nav) => ({
  type: SET_AUTOMATION_SETTING_SAGA, data, resolve, reject, nav,
});
export const triggerSetAllowlistSettingSaga = (data, resolve, reject) => ({
  type: SET_ALLOWLIST_SETTING_SAGA, data, resolve, reject,
});
export const triggerSetBackupSettingSaga = (data, resolve, reject) => ({
  type: SET_BACKUP_SETTING_SAGA, data, resolve, reject,
});
export const triggerClearCookiesSaga = (session, resolve, reject) => ({
  type: CLEAR_COOKIE_SAGA, payload: session, resolve, reject,
});
export const triggerCompressDatabase = (data, resolve, reject) => ({
  type: COMPRESS_DATABASE_SAGA, data, resolve, reject,
});
export const triggerCreateAccountSaga = (data, resolve, reject) => ({
  type: CREATE_ACCOUNT_SAGA, data, resolve, reject,
});
export const triggerLogoutSaga = (session, resolve, reject) => ({
  type: LOGOUT_SAGA, payload: session, resolve, reject,
});
export const triggerSwitchUserSaga = (data, resolve, reject) => ({
  type: SWITCH_USER_SAGA, data, resolve, reject,
});
export const triggerSyncFromDatabase = (data, resolve, reject, navigate) => ({
  type: SYNC_FROM_DATABASE_SAGA,
  data,
  item: data.item,
  privateItem: data.privateItem,
  publicItem: data.publicItem,
  insert: data.insert,
  resolve,
  reject,
  navigate,
});
