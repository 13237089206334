// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import ReactFileReader from 'react-file-reader';
import {
  Button,
} from '@material-ui/core';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';

import Spinner from './spinner/spinner';
import { triggerImportSaga } from '../actions/imports';
import migrate from '../utils/migrate';
import { getNowString } from '../utils/time';

function FileImport() {
  const navigate = useNavigate();
  const { promiseInProgress } = usePromiseTracker();
  const exportJson = useSelector((state) => state.undo.present.private);
  const dispatch = useDispatch();
  const importSaga = (data, resolve, reject) => dispatch(
    triggerImportSaga(data, resolve, reject, navigate),
  );
  return (
    <>
      {promiseInProgress && <Spinner text='Importing from file (this may take a few minutes)...' />}
      <ReactFileReader
        handleFiles={
                (files) => {
                  const fileReader = new FileReader();
                  fileReader.onload = () => {
                    let p;
                    trackPromise(
                      p = new Promise((resolve, reject) => {
                        const content = fileReader.result;
                        const json = JSON.stringify(migrate(JSON.parse(content)));
                        importSaga(json, resolve, reject, navigate);
                      }),
                    );
                    // p.catch((e) => {
                    p.catch(() => {
                      // setHideError(true);
                      // console.log(e);
                    });
                  };
                  fileReader.readAsText(files[0]);
                }
            }
        fileTypes={['.ah']}
      >
        <Button
          color='primary'
          onClick={() => {
            const blob = new Blob(
              [JSON.stringify(exportJson)],
              { type: 'text/json;charset=utf-8' },
            );
            saveAs(blob, `${getNowString()}_just_before_import_achieveHub.ah`);
          }}
          variant='contained'
        >
          Import
        </Button>
      </ReactFileReader>
    </>
  );
}

export default FileImport;
