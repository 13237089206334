// (C) Copyright 2020 MediaWink, LLC

const createBreadcrumbsReducer = (itemsType) => (state = null, action = null) => {
  // console.log({ 'createBreadcrumbsReducer': true, itemsType, action });
  switch (action.type) {
    // NO LONGER RESTORE SAVED CRUMBS -- use the path instead (eg / ==> [1])
    case `IMPORT_JSON_${itemsType}`:
      // console.log(`breadcrumbs: IMPORT_JSON_${itemsType}, json:`, JSON.parse(action.json));
      if (itemsType === 'PUBLIC') {
        return JSON.parse(action.json).public.breadcrumbs;
        // return [1];
      }
      return JSON.parse(action.json).private.breadcrumbs;
      // return [1];
    case `PUSH_${itemsType}_BREADCRUMB`:
      return [
        ...state,
        action.newItem,
      ];
    case `POP_${itemsType}_BREADCRUMB`:
      return action.count > 0 ? state.slice(0, -action.count) : state;
    case `SET_${itemsType}_BREADCRUMBS`:
      console.log('SET', { action });
      return action.data;
    default:
      return state;
  }
};

export default createBreadcrumbsReducer;
