// (C) Copyright 2020 MediaWink, LLC

// INPUT: received, expected
// OUTPUT: received delta, vs expected delta
//  eg: instead of received delta, should have been expected delta
export const stringCompare = (expected, received) => {
  if (received === expected) {
    return ['', ''];
  }
  let receivedDelta = '';
  let expectedDelta = '';
  const receivedWords = received.split(' ');
  const expectedWords = expected.split(' ');
  const receivedCount = receivedWords.length;
  const expectedCount = expectedWords.length;
  const minCount = receivedCount <= expectedCount ? receivedCount : expectedCount;
  let different = false;
  for (let i = 0; i < minCount; i += 1) {
    if (different || receivedWords[i] !== expectedWords[i]) {
      if (!different && i > 0) {
        receivedDelta += `${receivedWords[i - 1]} `;
        expectedDelta += `${expectedWords[i - 1]} `;
      }
      receivedDelta += `${receivedWords[i]} `;
      expectedDelta += `${expectedWords[i]} `;
      different = true;
    }
  }
  return [
    expectedDelta.trim(),
    receivedDelta.trim(),
  ];
};
