// (C) Copyright 2020 MediaWink, LLC

import React from 'react';

export const basicSteps = [
  {
    target: '.help',
    content: `Welcome! During this tour, I will show you around.
    Let me do the clicking.
    If you click anywhere during the tour, it will just
    go to the next step.
    After the tour you can try things out on your own.
    Now let's get started!
    The help page includes tours of AchieveHub, like this one.
    Press Enter or click next to continue.`,
    disableBeacon: true,
    placement: 'right',
  },
  {
    target: '.privacy',
    content: `From the Privacy Policy page,
    you can read about our privacy policy.`,
    disableBeacon: true,
    placement: 'right',
  },
  {
    target: '.terms',
    content: `From the Terms page,
    you can read the terms of this software.`,
    disableBeacon: true,
    placement: 'right',
  },
  {
    target: '.signin',
    content: `From the sign in page you can sign in to your account (or accounts).
    I suggest leaving the "Keep signed in" checked so that next time you come here
    on this device you will not need to sign in again,
    unless you are concerned about other people accessing this
    (including if you are on a public computer, for example).`,
    disableBeacon: true,
    placement: 'right',
  },
  {
    target: '.signout',
    content: 'From the sign out page you can sign out of your account (or accounts).',
    disableBeacon: true,
    placement: 'right',
  },
  {
    target: '.file',
    content: `The file export/import page allows you to export all your content to a file
for a backup, for example. You can also import a backed up file you exported earlier.`,
    disableBeacon: true,
    placement: 'right',
  },
  {
    target: '.usersettings',
    content: 'The user settings page lets you change settings like your password.',
    disableBeacon: true,
    placement: 'right',
  },
  {
    target: '.home',
    content: `The home page is where you view and modify your items such as contacts, todos, etc.
    If you currently see the Welcome screen, it is because you are not signed in.
    To fix this, if you have an account, click Skip, then sign in
    and restart this tour.`,
    disableBeacon: true,
    placement: 'right',
  },
  {
    target: '.tour-list',
    content:
  <>
    This is a list of items.  Each item can be another list,
    making this list of lists a tree,
    similar to how files are organized into folders or diretories.
    If an item has items inside it, the item
    will show how many items it has.
    For example,
    {' '}
    <b>todo (3)</b>
    {' '}
    would be a list of
    todo items, and the list would have 3 items inside it.
    Clicking on an item will
    open the item and allow you to add sub-items.
    The tree can be viewed in different ways, such as a tree or table.
  </>,
    disableBeacon: true,
    placement: 'top',
  },
  {
    target: '.tour-breadcrumbs',
    content: `These "breadcrumbs" at the top show you where you are in the tree.
    "All" is always the beginning of the tree.
    You can click on links in the breadcrumb to return to areas of the tree.
    The end of the breadcrumbs show where you are currently in the tree.`,
    disableBeacon: true,
    placement: 'bottom-start',
  },
  {
    target: '.tour-eye',
    content: `Click the eye to open or close the view menu.
    This will show different settings you can change that affect the current view.`,
    disableBeacon: true,
    placement: 'bottom-start',
  },
  {
    target: '.tour-view-settings',
    content: `When the eye is opened, you can view and modify these view settings,
    such as showing and hiding things, and changing the view between tree and table views.
    These settings are for the current view, and will persist.`,
    disableBeacon: true,
    placement: 'bottom-start',
  },
  {
    target: '.tour-add-top-bottom',
    content: `Use these check boxes to hide or show the add item entry field
    on the top or bottom of the lists.`,
    disableBeacon: true,
    placement: 'bottom-start',
  },
  {
    target: '.tour-tree-view',
    content: 'Show the list in a tree view.',
    placement: 'bottom-start',
  },
  {
    target: '.tour-table-view',
    content: 'Show the list in a table view.',
    placement: 'bottom-start',
  },
  {
    target: '.help',
    content: 'Thanks for taking the basic tour!',
    disableBeacon: true,
    placement: 'right',
  },
];
