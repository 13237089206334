// (C) Copyright 2020 MediaWink, LLC

// Empty - this is shown when loading DB
const defaultData = {
  'breadcrumbs': [
    1,
  ],
  'entities': {
    'items': {
      '1': {
        'Name': {
          'value': 'All',
          'type': 'Text',
        },
        'id': 1,
        'order': 1,
        'type': 'All',
        'Description': {
          'type': 'Text',
          'value': 'All',
        },
        'parent': 0,
        'items': [],
        'firstVisibleColumn': null,
      },
    },
  },
  'dirty': new Set(),
  'tour': {},
  'lastmodtime': '2020-11-05 02:05:08',
  'nextId': 19,
  'selectedId': null,
  'visibilityFilter': 'SHOW_ALL',
  'version': 7,
};
/*
const OldDefaultData = {
  'breadcrumbs': [
    1,
  ],
  'entities': {
    'items': {
      '1': {
        'Name': {
          'value': 'All',
          'type': 'Text',
        },
        'id': 1,
        'order': 1,
        'type': 'All',
        'Description': {
          'type': 'Text',
          'value': 'All',
        },
        'parent': 0,
        'items': [
          2,
        ],
        'firstVisibleColumn': null,
      },
      '2': {
        'Name': {
          'type': 'Text',
          'value': 'My First List (rename this by clicking on the pencil)',
        },
        'type': 'All',
        'order': 1,
        'items': [],
        'name': {
          'type': 'Text',
        },
        'parent': 1,
        'Description': {
          'value': 'All',
          'type': 'Text',
        },
        'id': 2,
      },
    },
  },
  'dirty': new Set(),
  'tour': {},
  'lastmodtime': '2020-11-05 02:05:08',
  'nextId': 9,
  'selectedId': null,
  'visibilityFilter': 'SHOW_ALL',
  'version': 7,
};
*/
export default defaultData;
