// (C) Copyright 2020 MediaWink, LLC

// https://material-ui.com/components/dialogs/#api

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

function ErrorDialog({
  showError,
  setShowError,
}) {
  useEffect(() => {
    setShowError(showError);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setShowError(0);
  };

  const messages = {
    '1': `Oh dear.  It looks like something went wrong.
     Please contact support with the error code to get things working.  Thanks!`,
    '401': 'Please check your email and password and try again.  Be sure CAPS LOCK is not on.',
    '507': `Oh dear.  It looks like AchieveHub is down.
      Please contact support with the error code to get things working.  Thanks!`,
    '1001': `Oh dear.  Try again.  If after trying again you are still unable to log in,
    then your Google account does not have access to AchieveHub and you will need to contact
    support at mediawink@gmail.com with the reason you would like an AchieveHub account.  Thanks!`,
  };

  return (
    <div>
      <Dialog
        open={showError > 0}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Login Failed</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {messages[showError] || messages[1]}
            {' '}
            {`Error code: ${showError}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus={true}
            color='primary'
            onClick={handleClose}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ErrorDialog.propTypes = {
  showError: PropTypes.number,
  setShowError: PropTypes.func.isRequired,
};

ErrorDialog.defaultProps = {
  showError: 1,
};

export default ErrorDialog;
