// (C) Copyright 2020 MediaWink, LLC

import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  IconButton,
  TextField,
} from '@material-ui/core';

import { Context } from './Context';
import Image from './Image';
import {
  triggerAddChildSaga,
  triggerPushBreadcrumbSaga,
  triggerPlayAppSaga,
  triggerSyncItemsFromGoogleDocSaga,
} from '../actions/items';
import {
  BuildIcon,
  CheckmarkIcon,
  DeleteIcon,
  EditIcon,
  PublicIcon,
  PrivateIcon,
  SharedIcon,
  SyncIcon,
} from '../constants/icons';
import {
  selectPrivateBreadcrumbs,
  selectPage,
  selectSelectedId,
} from '../selectors';

function Item({
  debug,
  toggleCompleteItem,
  removeItem,
  name,
  id,
  childrenCount,
  setSelection,
  hideDelete,
  hideEdit,
  hideCheckmark,
  updateItem,
  item,
  publicVisibility,
  privateVisibility,
  sharedVisibility,
}) {
  // console.log({ item });
  const [context] = useContext(Context);
  const [input, setInput] = useState('0.00'); // temp
  const dispatch = useDispatch();
  const privateBreadcrumbs = useSelector(selectPrivateBreadcrumbs);
  const navigate = useNavigate();
  const location = useLocation();
  const startApp = (data) => dispatch(
    triggerPlayAppSaga(data, navigate),
  );
  const pushBreadcrumbSaga = (idValue, nameValue, pv, recursive, nav) => dispatch(
    triggerPushBreadcrumbSaga({
      id: idValue,
      name: nameValue,
      privateVisibility: pv,
      recursive,
      nav,
      navigate,
    }),
  );
  const syncItemsFromGoogleDocSaga = (breadcrumbs, idValue, nameValue, syncFile, nav) => dispatch(
    triggerSyncItemsFromGoogleDocSaga(breadcrumbs, idValue, nameValue, syncFile, nav),
  );
  const addChildSaga = (breadcrumbs, idValue, nameValue) => dispatch(
    triggerAddChildSaga(breadcrumbs, idValue, nameValue),
  );
  const selectedId = useSelector(selectSelectedId);
  const page = useSelector(selectPage);

  const enableEdit = () => {
    if (selectedId !== id) {
      setSelection(privateBreadcrumbs, id);
    }
  };

  const endEdit = () => {
    if (selectedId === id) {
      setSelection(null, null);
    }
  };

  const updateItemValue = (i, value, keys) => {
    // console.log('*** updateItemValue', i, value, keys);
    endEdit();
    if (typeof value !== 'string') {
      return;
    }
    if (!value.trim()) {
      return;
    }
    const payload = {
      'item_id': i.id,
      'Name': value,
      'item': {
        ...i,
        'Name': {
          'type': 'Text',
          'value': value.trim(),
          ...keys,
        },
      },
    };
    updateItem(payload);
  };

  const [value, setValue] = useState(name);
  useEffect(() => {
    // setValue(item.Name.value);
  }, [item?.Name?.value]);
  // console.log('item', item);
  let itemText = '';
  let count;
  try {
    count = item?.items?.length > 0 ? ` (${item?.items?.length})` : '';
  } catch (err) {
    count = '';
  }
  if (debug) {
    itemText = `[${item?.order}] ${item?.Name?.value} {${item?.id}}`;
  } else {
    itemText = `${item?.Name?.value}`;
  }
  return (
    <Box>
      <Box
        display='flex'
        flexDirection='row'
      >
        {!hideDelete
          && (
            <Box>
              <IconButton
                aria-label='delete'
                onClick={() => removeItem({ data: { id } })}
                size='small'
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          )}
        {!hideCheckmark
          && (
            <Box>
              <IconButton
                aria-label='checkmark'
                onClick={() => toggleCompleteItem()}
                size='small'
              >
                <CheckmarkIcon />
              </IconButton>
            </Box>
          )}
        {!hideEdit
          && (
            <Box>
              <IconButton
                aria-label='edit'
                onClick={() => {
                  enableEdit();
                }}
                size='small'
              >
                <EditIcon />
              </IconButton>
            </Box>
          )}
        {publicVisibility && <PublicIcon htmlColor='lightgrey' />}
        {sharedVisibility && <SharedIcon htmlColor='lightgrey' />}
        {privateVisibility && <PrivateIcon htmlColor='lightgrey' />}
        <Box
          style={{
            width: '100%',
            display: 'flex',
            textDecoration: item.completed ? 'line-through' : 'none',
          }}
        >
          {selectedId === id
            ? (
              <form
                style={{
                  width: '100%',
                }}
                onSubmit={(e) => {
                  e.preventDefault();
                  updateItemValue(item, value.trim());
                }}
              >
                <TextField
                  style={{
                    width: '100%',
                    padding: '0px',
                    color: 'dark-3',
                    textDecoration: item.completed ? 'line-through' : 'none',
                  }}
                  type='text'
                  className='input'
                  onBlur={() => {
                    updateItemValue(item, value);
                  }}
                  value={value}
                  onChange={(e) => {
                    // e.preventDefault();
                    setValue(e.target.value);
                  }}
                />
              </form>
            )
            : (
              <>
                <Box display='flex' alignItems='center'>
                  <Box
                    onClick={
                      () => {
                        if (page.DispenseCharacters) {
                          if (name === page?.EmptyCharacter?.value) {
                            const list = page?.DispenseCharacters?.value;
                            const length = list.length / 2;
                            const num = Math.floor(Math.random() * length);
                            // let codePoint of list;
                            const random = [...list][num];
                            updateItemValue(item, random);
                            return;
                          }
                          // TODO: animate
                          // Update even if it isn't in the list,
                          // because the list might have changed (eg for Christmas)
                          updateItemValue(item, '❓');
                          return;
                        }
                        const playApp = page?.Name?.value === 'App List'
                          || item?.Type?.value === 'App'
                          || location.pathname.startsWith('/App List');
                        if (playApp) {
                          const tf = (
                            <TextField
                              id='value'
                              key='tf'
                              label='title'
                              defaultValue='list'
                              initialValue={input}
                              value={input}
                            />
                          );
                          startApp({
                            dispatch, inputObj: { input }, setInput, tfObj: { tf },
                          });
                          context.setShowList(false);
                          context.setShowApp(true);
                        }
                        if (childrenCount >= 0) {
                          const recursive = playApp;
                          // const nav = page?.Name?.value === 'App+List' ? 'app' : null;
                          const newPath = location.pathname === '/'
                            ? name
                            : `${location.pathname}/${name}`;
                          const nav = playApp ? `${newPath}/Screen List/Start` : newPath;
                          pushBreadcrumbSaga(id, name, privateVisibility, recursive, nav);
                        } else {
                          addChildSaga(privateBreadcrumbs, id, name);
                        }
                      }
                    }
                  >
                    {itemText}
                    <b>
                      {count}
                    </b>
                  </Box>
                  {page.Name.value === 'App+List'
                    && (
                      <Button>
                        <BuildIcon
                          onClick={() => pushBreadcrumbSaga(id, name, privateVisibility, true)}
                        />
                      </Button>
                    )}
                </Box>
                {item?.SyncFile
                  && (
                    <IconButton
                      aria-label='Sync'
                      onClick={() => {
                        try {
                          syncItemsFromGoogleDocSaga(
                            privateBreadcrumbs,
                            id,
                            name,
                            item.SyncFile,
                            navigate,
                          );
                        } catch (e) {
                          console.error('Caught (need to re-sign-in?):', e);
                        }
                      }}
                      size='small'
                    >
                      <SyncIcon />
                    </IconButton>
                  )}
              </>
            )}
        </Box>
      </Box>
      {itemText.startsWith('http') && (
        <Image
          width={200}
          height={200}
          src={itemText}
          alt='2'
          referrerPolicy='no-referrer'
        />
      )}
      {itemText.includes('=http') && (
        <Image
          width={200}
          height={200}
          src={itemText.split('=').slice(1).join('=')}
          alt='3'
          referrerPolicy='no-referrer'
        />
      )}
      {itemText.includes('=data:image') && (
        <Image
          width={200}
          height={200}
          src={itemText.split('=').slice(1).join('=')}
          alt='4'
          referrerPolicy='no-referrer'
        />
      )}
    </Box>
  );
}

Item.propTypes = {
  debug: PropTypes.bool.isRequired,
  toggleCompleteItem: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  completed: PropTypes.bool,
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  childrenCount: PropTypes.number.isRequired,
  setSelection: PropTypes.func.isRequired,
  hideDelete: PropTypes.bool,
  hideEdit: PropTypes.bool,
  hideCheckmark: PropTypes.bool,
  publicVisibility: PropTypes.bool,
  privateVisibility: PropTypes.bool,
  sharedVisibility: PropTypes.bool,
  item: PropTypes.object.isRequired,
  updateItem: PropTypes.func.isRequired,
};

Item.defaultProps = {
  publicVisibility: false,
  privateVisibility: false,
  sharedVisibility: false,
  completed: false,
  hideDelete: false,
  hideEdit: false,
  hideCheckmark: false,
};

export default Item;
