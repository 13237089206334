// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import { useDispatch } from 'react-redux';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import {
  Button,
} from '@material-ui/core';

import Spinner from './spinner/spinner';
import { triggerExportSaga } from '../actions/exports';

function FileExport() {
  const { promiseInProgress } = usePromiseTracker();
  const dispatch = useDispatch();
  const myExportSaga = (resolve, reject) => dispatch(
    triggerExportSaga(resolve, reject),
  );
  return (
    <>
      {promiseInProgress && <Spinner text='Exporting to file...' />}
      <Button
        color='primary'
        onClick={() => {
          let p;
          // console.log('new Promise: FileExport');
          trackPromise(
            p = new Promise((resolve, reject) => {
              myExportSaga(resolve, reject);
            }),
          );
          p.catch((e) => {
            // setHideError(true);
            console.error(e);
          });
        }}
        variant='contained'
      >
        Export (Backup)
      </Button>
    </>
  );
}

export default FileExport;
