// (C) Copyright 2020 MediaWink, LLC

import React, { useEffect, useState, useMemo } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { trackPromise } from 'react-promise-tracker';
import {
  Routes, Route, useNavigate, Link,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@material-ui/core';
// import { ThemeProvider } from '@material-ui/styles';
// import { createTheme } from '@material-ui/core/styles';
import { MantineProvider, createTheme } from '@mantine/core';

// eslint-disable-next-line import/no-unresolved
import '@mantine/core/styles.css';

import {
  HelpIcon,
  HomeIcon,
  PolicyIcon,
  TermsIcon,
  UserAdminIcon,
  UserSettingsIcon,

  DebugIcon,
  DatabaseIcon,
  FileIcon,
  LoginIcon,
  LogoutIcon,
} from '../constants/icons';
import * as COLORS from '../constants/colors';
import TourJoyride from './TourJoyride';
import { Context } from './Context';
import SignIn from '../screens/SignIn';
import SignOut from '../screens/SignOut';
import LocalhostBanner from './LocalhostBanner';
import Cookies from '../screens/Cookies';
import FileScreen from '../screens/FileScreen';
import PlayApp from '../screens/PlayApp';
import LoginInfo from './LoginInfo';

// import { ScrollToTop } from './ScrollToTop';
import RequireAuth from './RequireAuth.jsx';

import Help from '../screens/Help';
import Splat from '../screens/Splat';
import Privacy from '../screens/Privacy';
import Terms from '../screens/Terms';
import UserSettings from '../screens/UserSettings';
import UserAdmin from '../screens/UserAdmin';
import Welcome from '../screens/Welcome';
// import './App.css';
import {
  triggerSyncFromDatabase,
  triggerCompressDatabase,
} from '../actions/session';
import {
  triggerExportSaga,
} from '../actions/exports';
import ViewSettingsEye from './ViewSettingsEye';

// Default theme:
// https://material-ui.com/customization/default-theme/
const initialTheme = createTheme({
/*
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(99,0,0,0.2)',
      },
    },
  },
  PaperProps: {
    style: {
      backgroundColor: 'rgba(229,0,0,0.2)',
      boxShadow: 'none',
    },
  },
  MuiBackdrop: {
    backgroundColor: 'rgba(229,0,0,0.2)',
    root: {
      backgroundColor: 'rgba(99,0,0,0.2)',
    },
  },
*/
  // palette: {
  //   primary: { main: '#e91e63', contrastText: '#000' },
  //   secondary: { main: '#03a9f4', contrastText: '#000' },
  // },
});

/*
const darkTheme = createTheme({
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: '#e91e63',
      },
    },
  },
  PaperProps: {
    style: {
      backgroundColor: '#e91e63',
      boxShadow: 'none',
    },
  },
  MuiBackdrop: {
    backgroundColor: '#e91e63',
    root: {
      backgroundColor: '#e91e63',
    },
  },
  palette: {
    mode: 'dark',
    primary: { main: '#e91e63', contrastText: '#000' },
    secondary: { main: '#03a9f4', contrastText: '#000' },
  },
});
*/

const colortheme = initialTheme;
function App() {
  const navigate = useNavigate();
  let localhost = false;
  if (window.location.hostname === 'localhost') {
    localhost = true;
  }
  // const [debug, setDebug] = useState(window.location.hostname === 'localhost');
  const [debug, setDebug] = useState(false);
  const [showViewSettings, setShowViewSettings] = useState(false);
  const [showList, setShowList] = useState(true);
  const [showApp, setShowApp] = useState(false);
  const [context, setContext] = useState({
    run: false,
    stepIndex: 0,
    showViewSettings,
    setShowViewSettings,
    setShowList,
    setShowApp,
  });

  const loginState = useSelector((state) => state.undo.present.private.login);
  const settings = useSelector((state) => state.undo.present.private.settings);
  const [checkedNewDay, setCheckedNewDay] = useState(false);
  const dispatch = useDispatch();
  const compressDatabase = (loginStateIn, resolve, reject) => dispatch(
    triggerCompressDatabase(loginStateIn, resolve, reject),
  );

  useEffect(() => {
    const syncFromDatabase = (loginStateIn, resolve, reject) => dispatch(
      triggerSyncFromDatabase(loginStateIn, resolve, reject, navigate),
    );

    if (loginState.isLoggedIn) {
      // console.log('trackPromise -- loginState.isLoggedIn = true');
      trackPromise(
        new Promise((resolve, reject) => {
          // console.log('new Promise: syncFromDB start');
          const data = {
            ...loginState,
            item: 1,
          };
          syncFromDatabase(data, resolve, reject);
        }),
      );
    } else {
      // console.log('trackPromise -- loginState.isLoggedIn = false');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (settings && !checkedNewDay) {
      setCheckedNewDay(true);
      const { backups, lastBackup } = settings;
      const dateString = new Date().toLocaleDateString();
      if (!lastBackup || dateString !== lastBackup) {
        if (backups) {
          const exportSaga = (resolve, reject) => dispatch(
            triggerExportSaga(resolve, reject, navigate),
          );
          exportSaga();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);
  const contextProvider = useMemo(() => ([context, setContext]), [context]);
  return (
    <MantineProvider theme={colortheme}>
      <CssBaseline />
      <Context.Provider value={contextProvider}>
        <TourJoyride context={context} setContext={setContext} />
        <Box className='App'>
          <LocalhostBanner />
          <Box>
            <nav>
              <Box display='flex' flexDirection='row'>
                <Link to='/'>
                  <HomeIcon className='splat' style={{ fill: COLORS.GRAY40 }} />
                </Link>
                <Link to='help' className='help'>
                  <HelpIcon style={{ fill: COLORS.GRAY40 }} />
                </Link>
                <Link to='privacy' className='privacy'>
                  <PolicyIcon style={{ fill: COLORS.GRAY40 }} />
                </Link>
                <Link to='terms' className='terms'>
                  <TermsIcon style={{ fill: COLORS.GRAY40 }} />
                </Link>
                {!loginState.isLoggedIn
                    && (
                      <Link to='signin' className='signin'>
                        <LoginIcon />
                        {' '}
                        (Sign in)
                      </Link>
                    )}
                {loginState.isLoggedIn
                    && (
                      <>
                        <Link to='file'>
                          <FileIcon className='file' style={{ fill: COLORS.GRAY40 }} />
                        </Link>
                        <Link to='usersettings' className='usersettings'><UserSettingsIcon /></Link>
                        {loginState.account_role === 'Administrator'
                          && <Link to='useradmin'><UserAdminIcon color='red' /></Link>}
                        <Link to='signin' className='signin'><LoginIcon /></Link>
                        <Box display='flex' flexDirection='row'>
                          <LoginInfo />
                          <Link to='signout' className='signout'><LogoutIcon /></Link>
                        </Box>
                        <Button>
                          <ViewSettingsEye setShowApp={setShowApp} />
                        </Button>
                        <Button>
                          <DebugIcon onClick={() => setDebug(!debug)} />
                        </Button>
                        {localhost && (
                          <Button>
                            <DatabaseIcon
                              onClick={() => {
                                let p;
                                // console.log('new Promise: compressDatabase');
                                trackPromise(
                                  p = new Promise((resolve, reject) => {
                                    compressDatabase({ loginState }, resolve, reject);
                                  }),
                                );
                                p.catch(() => {
                                });
                              }}
                            />
                          </Button>
                        )}
                      </>
                    )}
              </Box>
            </nav>
          </Box>
          <Routes primary={false}>
            <Route path='help' element={<Help />} />
            {/* <ScrollToTop path='/'> */}
            <Route path='help' element={<Help />} />
            <Route path='welcome' element={<Welcome />} />
            <Route path='terms' element={<Terms />} />
            <Route path='privacy' element={<Privacy />} />
            <Route path='cookies' element={<Cookies />} />
            <Route path='signin' element={<SignIn />} keepSignedIn={loginState.keepSignedIn} />

            <Route element={<RequireAuth />}>
              <Route path='file' element={<FileScreen />} />
              <Route path='usersettings' element={<UserSettings />} />
              <Route path='useradmin' element={<UserAdmin />} />
              <Route
                path='app'
                element={(
                  <PlayApp
                    setShowList={setShowList}
                    setShowApp={setShowApp}
                    debug={debug}
                  />
                  )}
              />
            </Route>

            <Route path='signout' element={<SignOut />} />
            <Route
              path='*'
              element={(
                <Splat
                  showList={showList}
                  setShowList={setShowList}
                  showApp={showApp}
                  setShowApp={setShowApp}
                  debug={debug}
                />
  )}
            />
            {/* </ScrollToTop> */}
          </Routes>
        </Box>
      </Context.Provider>
    </MantineProvider>
  );
}

export default App;
