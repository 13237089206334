// (C) Copyright 2020 MediaWink, LLC

import {
  IMPORT_JSON,
  SET_SETTINGS,
} from '../action-types';

const settings = (state = null, action = null) => {
  switch (action.type) {
    case IMPORT_JSON:
      return JSON.parse(action.json).private.settings || {};
    case SET_SETTINGS:
      return action.data;
    default:
      return state;
  }
};

export default settings;
