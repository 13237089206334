// (C) Copyright 2020 MediaWink, LLC

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trackPromise } from 'react-promise-tracker';
import {
  Box,
  Select,
} from '@material-ui/core';
import {
  cacheItemToString,
  selectLogin,
  selectLoginCaches,
  selectCurrentLoginCache,
} from '../selectors/session';
import {
  triggerSwitchUserSaga,
} from '../actions/session';

import ErrorDialog from './ErrorDialog';

function LoginInfo() {
  const currentLoginCache = useSelector((state) => selectCurrentLoginCache(state));
  const loginCaches = useSelector((state) => selectLoginCaches(state));
  const loginState = useSelector((state) => selectLogin(state));
  const dispatch = useDispatch();
  const switchUser = (data, resolve, reject) => dispatch(
    triggerSwitchUserSaga(data, resolve, reject),
  );
  const [showError, setShowError] = React.useState(false);
  const [value, setValue] = useState(currentLoginCache.account_email);
  useEffect(() => {
    setValue(currentLoginCache.account_email);
  }, [loginState, currentLoginCache]);
  return (
    <Box display='flex' flexDirection='row' gap='small'>
      {loginState.cache && Object.keys(loginState.cache).length > 1 ? (
        <Box>
          <Select
            native={true}
            value={value}
            onChange={({ target: option }) => {
              setValue(option.value);
              const cache = loginCaches
                .filter((c) => c.account_email === option.value)[0];
              const data = {
                ...cache,
              };
              let p;
              if (data.account_email !== currentLoginCache.account_email) {
                // console.log('new Promise: LoginInfo');
                trackPromise(
                  (p = new Promise((resolve, reject) => {
                    switchUser(data, resolve, reject);
                  })),
                );
                p.catch((e) => {
                  console.log('error', e);
                  setShowError(true);
                });
              }
            }}
          >
            {loginCaches.map((cache) => (
              <option key={cache.account_email} value={cache.account_email}>
                {cache && cacheItemToString(cache)}
              </option>
            ))}
          </Select>
        </Box>
      ) : (
        loginState && cacheItemToString(loginState)
      )}
      {showError && <ErrorDialog />}
    </Box>
  );
}

export default LoginInfo;
