// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
} from '@material-ui/core';

import {
  selectPrivateBreadcrumbs,
  selectPublicBreadcrumbs,
  selectPrivateItems,
  selectIsPrivate,
} from '../selectors';
import { triggerSetPrivateBreadcrumbsSaga } from '../actions/items';

const GRID = 8;
function BreadcrumbsTailsHorizontal({
  debug,
}) {
  const dispatch = useDispatch();
  const privateBreadcrumbs = useSelector(selectPrivateBreadcrumbs);
  const publicBreadcrumbs = useSelector(selectPublicBreadcrumbs);
  const isPrivate = useSelector(selectIsPrivate);
  const breadcrumbs = isPrivate ? privateBreadcrumbs : publicBreadcrumbs;
  const items = useSelector((state) => {
    const entities = isPrivate
      ? state.undo.present.private.entities : state.undo.present.public.entities;
    const crumbs = breadcrumbs.map(
      (i) => entities.items[i],
    );
    return crumbs;
  });
  const allItems = useSelector((state) => selectPrivateItems(state));

  const tail = breadcrumbs.length > 0 ? items[breadcrumbs.length - 1]
    && (items[breadcrumbs.length - 1] || undefined) : undefined;
  const parentItem = useSelector((state) => selectPrivateItems(state)[tail?.parent]);
  const setPrivateBreadcrumbs = (breadcrumbsIn) => dispatch(
    triggerSetPrivateBreadcrumbsSaga({ breadcrumbs: breadcrumbsIn }),
  );
  const getItemStyle = () => ({
    display: 'flex',
    flex: 1,
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: GRID * 2,
    margin: `0 ${GRID}px 0 0`,

    // change background color
    background: 'lightgrey',
  });
  return (
    <Box
      display='flex'
      flexDirection='row'
      style={{ flexWrap: 'wrap' }}
    >
      {parentItem?.items?.sort((a, b) => allItems[a].order - allItems[b].order).map((i) => (
        <Box
          key={`${allItems[i]?.id}`}
          m={0}
          p={0}
          justify='center'
          style={getItemStyle()}
        >
          <Button
            m={0}
            p={0}
            style={{
              display: 'flex',
              textTransform: 'none',
              // padding: 0,
              // margin: 0,

              fontSize: tail.id === i ? 25 : 15,
              textDecorationLine: tail.id === i ? 'underline' : null,
              // alignSelf: 'center',
            }}
            onClick={() => {
              setPrivateBreadcrumbs([...breadcrumbs.slice(0, -1), i]);
            }}
          >
            {`${allItems[i]?.Name?.value}`}
            {debug && ` {${allItems[i]?.id}}`}
          </Button>
        </Box>
      ))}
    </Box>
  );
}

BreadcrumbsTailsHorizontal.propTypes = {
  debug: PropTypes.bool.isRequired,
};

export default BreadcrumbsTailsHorizontal;
