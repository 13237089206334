// (C) Copyright 2020 MediaWink, LLC

export const IMPORT_JSON = 'IMPORT_JSON';
export const IMPORT_JSON_PRIVATE = 'IMPORT_JSON_PRIVATE';
export const IMPORT_JSON_PUBLIC = 'IMPORT_JSON_PUBLIC';
export const INSERT_JSON = 'INSERT_JSON';
export const INSERT_JSON_PRIVATE = 'INSERT_JSON_PRIVATE';
export const INSERT_JSON_PUBLIC = 'INSERT_JSON_PUBLIC';
export const IMPORT_SAGA = 'IMPORT_SAGA';
export const MERGE_SAGA = 'MERGE_SAGA';
