// (C) Copyright 2020 MediaWink, LLC

import { createSelector } from 'reselect';

export const selectLogin = (state) => state.undo.present.private.login;
export const selectPrivateLogin = (state) => state.undo.present.private.login;
export const selectPublicLogin = (state) => state.undo.present.public.login;
export const selectIsPublicLogin = createSelector(
  selectPrivateLogin,
  (privateLogin) => privateLogin.account_id === '1',
);

export const cacheItemToString = (l) => `
${l.account_first_name} <${l.account_email || ''}> [${l.account_role || ''}]`;
export const selectLoginCaches = createSelector(
  selectLogin,
  (loginState) => (
    loginState.cache ? Object.keys(loginState.cache).map((k) => loginState.cache[k]) : []),
);
export const selectCurrentLoginCache = createSelector(
  selectLogin,
  (loginState) => loginState.cache[loginState.account_email],
);
export const selectLoginCookieString = createSelector(
  selectLogin,
  (loginState) => JSON.stringify(loginState),
);
