// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import {
  Box,
  Button,
} from '@material-ui/core';

import Spinner from './spinner/spinner';
import {
  triggerLogoutSaga,
} from '../actions/session';

function SignOut() {
  const { promiseInProgress } = usePromiseTracker();
  const loginState = useSelector((state) => state.undo.present.private.login);
  const dispatch = useDispatch();
  const logout = (data, resolve, reject) => dispatch(
    triggerLogoutSaga(data, resolve, reject),
  );
  return (
    <>
      {promiseInProgress && <Spinner text='Signing out...' />}
      {loginState.isLoggedIn // TODO: Remove loginstate.isLoggedIn check
      && (
      <Box gap='small' align='center'>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            let p;
            console.log('new Promise: Sihn out');
            trackPromise(
              p = new Promise((resolve, reject) => {
                logout(loginState, resolve, reject);
              }),
            );
            p.catch(() => {
            });
          }}
        >
          <Button
            color='primary'
            type='submit'
            variant='contained'
          >
            Sign Out
          </Button>
        </form>
      </Box>
      )}
    </>
  );
}

export default SignOut;
