// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import {
  Box,
} from '@material-ui/core';

import FileExport from '../components/FileExport';
import FileImport from '../components/FileImport';
import FileMerge from '../components/FileMerge';

function FileScreen() {
  return (
    <Box align='center'>
      <h1>File Export/Import</h1>
      <Box align='center'>
        <p style={{ 'color': 'red' }}>
          How long has it been since you last exported a backup file?
        </p>
        <h2>Export (Backup) to a file</h2>
        <p>
          Export all your current items to a file, to be imported later.
          This is a good way to backup your work!
          When you click the Export button, all your work will be
          exported to a file, and the file will be immediately downloaded for
          you to use later. When you want to import the file, click the import icon at the top,
          with the up arrow, to go to the import screen and follow the instructions.
          If no file is downloaded
          {' '}
          <b>close and reopen your browser</b>
          .
          {' '}
          Exports usually take just a few seconds.
        </p>
        <FileExport />
      </Box>
      <Box>
        <h2>Import (restore) from a file</h2>
        <p>
          Import data from a file that you have exported earlier.
          NOTE: This will
          {' '}
          <b>delete all</b>
          {' '}
          items you have currently
          and replace them with the imported items.
          For this reason, right when you press the Import button, your current items
          will be immediately exported and downloaded for you in a file to be used as a backup.
          If after the import you want to go back to what you had before,
          you can import that file.
          Imports can take several minutes.
        </p>
        <FileImport />
      </Box>
      <Box>
        <h2>Merge to your current location, from a file</h2>
        <p>
          Merge data from an exported file into the location that you visited last.
          For example, if someone exports a file containing items at
          All | Tasks | Work | Project A
          Then, someone else, in a different account, wants to merge those in
          to their account, they could create
          All | Tasks | Work | Project A,
          navigate to that location, then from this menu select
          Merge, and it will merge
          All | Tasks | Work | Project A,
          and everything in Project A
          to the currenly logged in account.
          If any items already exist in the account, the merge will
          skip over them.
          Right when you press the Import button, your current items
          will be immediately exported and downloaded for you in a file to be used as a backup.
          If after the merge you want to go back to what you had before,
          you can import that file.
          Imports and merges can both take several minutes.
        </p>
        <FileMerge />
      </Box>
      <Box align='center'>
        <img alt='Wagon Wheel' src='android-chrome-192x192.png' />
      </Box>
    </Box>
  );
}

export default FileScreen;
