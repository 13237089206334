// (C) Copyright 2020 MediaWink, LLC

/* eslint-disable no-await-in-loop */
/* eslint-disable new-cap */

import React, { useRef, useState, useEffect } from 'react';
import {
  Box,
  Button,
  Collapse,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { trackPromise } from 'react-promise-tracker';

import { selectLogin } from '../selectors/session';
import Spinner from './spinner/spinner';
// import { EMPTY_IMAGE } from '../constants';
import { selectImage, selectImages } from '../selectors/images';
import {
  triggerUnsplashSaga,
} from '../actions/unsplash';
import {
  triggerAddItemToBottomSaga,
} from '../actions/items';
import {
  selectNextId,
  selectPage,
  selectPrivateItemById,
} from '../selectors/items';

const MAX_IMAGES = 24;

function FindImagesControl() {
  const step = useRef();
  const dispatch = useDispatch();
  const unsplash = (data, resolve, reject) => dispatch(
    triggerUnsplashSaga(data, resolve, reject),
  );
  const [showFindWords, setShowFindWords] = useState(false);
  const handleToggleShowFindWords = () => {
    setShowFindWords((prev) => !prev);
  };
  const [wordNumber, setWordNumber] = useState(-1);
  const image = useSelector(
    (state) => (word) => selectImage(state, word),
  );
  const nextId = useSelector(selectNextId);
  const images = useSelector((state) => selectImages(state));
  const page = useSelector((state) => selectPage(state));
  const getItem = useSelector((state) => (id) => selectPrivateItemById(state, id));
  const [words, setWords] = useState([]);
  const [status, setStatus] = useState('Loading...');
  const [word, setWord] = useState('');
  const [urls, setUrls] = useState([]);
  const addItemToBottom = (data, resolve, reject) => dispatch(
    triggerAddItemToBottomSaga(data, resolve, reject),
  );
  const login = useSelector(selectLogin);

  const handleSaveImage = (wordToSave, url) => {
    // console.log('SAVE', wordToSave);
    // console.log('images', images);
    // const wordImage = image(wordToSave);
    // const exists = wordImage !== EMPTY_IMAGE;
    // console.log(wordImage, 'wordImage');
    // console.log('exists', exists);
    const breadcrumbs = [0, images.id]; // Assumption that Images is under All
    // console.log('breadcrumbs', breadcrumbs);

    // console.log(`> Adding ${word}...`);
    setStatus(`Adding ${word}...`);
    step.current = `Adding ${word}...`;
    const wordData = {
      ...login,
      item_id: nextId,
      parent: images.id,
      breadcrumbs,
      nextId,
      value: word,
      item: {
        Name: {
          type: 'Text',
          value: word,
        },
        type: 'Text',
        group: undefined,
      },
    };
    // console.log('wordData', wordData);
    const urlData = {
      ...login,
      item_id: nextId + 1,
      parent: nextId,
      breadcrumbs: [...breadcrumbs, nextId],
      nextId: nextId + 1,
      value: url,
      item: {
        Name: {
          type: 'Text',
          value: url,
        },
        type: 'Text',
        group: undefined,
      },
    };
    // console.log('urlData', urlData);
    let p;
    // console.log('new Promise: FindImage addItemToBottom');
    trackPromise(
      p = new Promise((resolve, reject) => {
        addItemToBottom(wordData, resolve, reject);
      }),
    );
    p.finally(() => {
      // console.log('Finally', word);
      const p2 = new Promise((resolve, reject) => {
        addItemToBottom(urlData, resolve, reject);
      });
      p2.finally(() => {
        // console.log('Done adding url', url);
      });
      setStatus('Loading...');
      step.current = `Adding ${word}...`;
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const { items } = page;
    let quotes = '';
    if (getItem(items[0])?.Description?.value !== 'All' && words.length === 0) {
      for (let i = 0; i < items.length; i += 1) {
        const item = getItem(items[i]);
        // console.log('item', item);
        quotes += `${item?.Name?.value} `;
      }
      setWords(quotes
        ?.replace(/[^a-zA-Z\d ]/g, '')
        ?.split(' '));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getItem, page.items, words.length]);

  return (
    <Box>
      <Spinner text={status} />
      <FormControlLabel
        control={<Switch checked={showFindWords} onChange={handleToggleShowFindWords} />}
        id='Find Images'
        key='Find Images'
        label='Find Images'
      />
      <Collapse in={showFindWords}>
        <Box>
          <TextField
            id='input'
            label='Word'
            value={word}
            onChange={(event) => setWord(event.target.value)}
            variant='standard'
          />
        </Box>
        <Button
          disabled={wordNumber === 0}
          onClick={() => {
            setWord(words[wordNumber - 1]);
            setWordNumber((n) => n - 1);
          }}
          variant='contained'
        >
          Previous Word
        </Button>
        <Button
          disabled={wordNumber >= words.length - 1}
          onClick={() => {
            setWord(words[wordNumber + 1]);
            setWordNumber((n) => n + 1);
          }}
          variant='contained'
        >
          Next Word
        </Button>
        <Button
          onClick={() => {
            // console.log('Find', word);
            let p;
            trackPromise(
              p = new Promise((resolve, reject) => {
                // console.log('start track unsplash find');
                unsplash(
                  {
                    word,
                    setter: (u) => setUrls(u.length === 1 ? [u] : u),
                  },
                  resolve,
                  reject,
                );
              }),
            );
            p.then(() => {
              // console.log('urls', urls);
              // console.log('finished track unsplash find, returning', value);
            });
          }}
          variant='contained'
        >
          Find Image
        </Button>
        {urls.length > 0 && (
          <>
            <Typography>
              Click an image to save it
            </Typography>
            <Box
              display='flex'
              flexDirection='row'
            >
              {urls.slice(0, urls.length > MAX_IMAGES ? MAX_IMAGES : urls.length - 1).map((url) => (
                <Box
                  key={url}
                  onClick={() => {
                    // console.log('event', event);
                    handleSaveImage(word, url);
                  }}
                >
                  <Button>
                    <img
                      alt={word}
                      style={{
                        'maxWidth': '20vw',
                        'maxHeight': '20vh',
                      }}
                      src={url}
                      referrerPolicy='no-referrer'
                    />
                  </Button>
                </Box>
              ))}
            </Box>
            <Box>
              <Typography>
                Current Image:
              </Typography>
            </Box>
            <Box>
              <img
                alt={word}
                style={{
                  'maxWidth': '100vw',
                  'maxHeight': '100vh',
                }}
                src={image(word)}
                referrerPolicy='no-referrer'
              />
            </Box>
          </>
        )}
        {urls.length === 0 && (
          <Typography>
            Press the Find Image button to search for the word.
            If no image is found, try a different work or check spelling.
          </Typography>
        )}
      </Collapse>
    </Box>
  );
}

export default FindImagesControl;
