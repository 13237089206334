// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  IconButton,
} from '@material-ui/core';

import { DeleteIcon } from '../constants/icons';
import { deleteFieldAsync } from '../actions/items';

function DeleteField({
  item,
  name,
}) {
  const dispatch = useDispatch();
  const deleteField = (payload, resolve, reject) => dispatch(
    deleteFieldAsync(payload, resolve, reject),
  );
  const handleDelete = () => {
    deleteField({
      id: item.id,
      name,
    });
  };
  return (
    <IconButton
      aria-label='delete field'
      onClick={handleDelete}
      size='small'
    >
      <DeleteIcon />
    </IconButton>
  );
}

DeleteField.propTypes = {
  item: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};

export default DeleteField;
