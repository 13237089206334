// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import {
  Box,
} from '@material-ui/core';

import SignIn from '../components/SignIn';

function SignInScreen() {
  return (
    <Box
      align='center'
    >
      <h1>Sign In</h1>
      <p>
        AchieveHub
      </p>
      <Box width={400}>
        Information entered while not signed in will not be saved.
        Note that you can sign in to multiple accounts and switch between them.
        <SignIn keepSignedIn={true} />
        <img alt='Wagon Wheel' src='android-chrome-192x192.png' />
      </Box>
    </Box>
  );
}

export default SignInScreen;
