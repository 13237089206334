// (C) Copyright 2020 MediaWink, LLC

import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Checkbox,
  FormGroup,
  FormControlLabel,
  IconButton,
  Typography,
} from '@material-ui/core';

import { Context } from './Context';
import FindImageControl from './FindImagesControl';
import TextToSpeechControls from './TextToSpeechControls';
import {
  AppShortcutIcon,
  GroupIcon,
  CheckmarkIcon,
  TaskIcon,
  DeleteIcon,
  TreeIcon,
  TableIcon,
  EditIcon,
  ListIcon,
  OpenEyeIcon,
  ClosedEyeIcon,
  CrumbsBodyIcon,
  CrumbsTailIcon,
} from '../constants/icons';
import {
  triggerPutAndUpdateSaga,
  triggerSetHideAddBottomSaga,
  triggerSetHideAddTopSaga,
  triggerSetTableViewSaga,
  triggerSetTableViewGroupsSaga,
} from '../actions/items';
import { selectPage } from '../selectors/items';
import {
  SET_HIDE_CHECKMARK,
  SET_HIDE_CRUMBS_BODY,
  SET_HIDE_CRUMBS_TAIL,
  SET_HIDE_DELETE,
  SET_HIDE_EDIT,
  SET_HIDE_FIELDS,
} from '../action-types';

function ViewSettings({
  setShowList,
  showList,
  setShowApp,
  showApp,
}) {
  const [context] = useContext(Context);
  const page = useSelector((state) => selectPage(state));
  const dispatch = useDispatch();
  const putAndUpdate = (data) => dispatch(triggerPutAndUpdateSaga(data));
  const setTableView = (data) => dispatch(triggerSetTableViewSaga(data));
  const setTableViewGroups = (data) => dispatch(triggerSetTableViewGroupsSaga(data));
  const setHideAddBottom = (data) => dispatch(triggerSetHideAddBottomSaga(data));
  const setHideAddTop = (data) => dispatch(triggerSetHideAddTopSaga(data));
  return (
    <Box display='flex' flexDirection='row' gap='small' align='center'>
      {context.showViewSettings && (
        <Box
          align='center'
          bgcolor='#e6e6e6'
          className='tour-open-eye'
          display='flex'
          flexDirection='row'
          p={2}
        >
          <Box
            display='flex'
            flexDirection='row'
            background='light-3'
            pad='small'
            className='tour-add-top-bottom'
          >
            <Box align='center' p={1}>
              <Typography>Add Item</Typography>
            </Box>
            <Box>
              <Box display='flex' flexDirection='row'>
                <FormGroup>
                  <FormControlLabel
                    control={
                      (
                        <Checkbox
                          checked={!page.hideAddTop}
                          onChange={(event) => {
                            console.log('target', event.target);
                            console.log('target.checked', event.target.checked);
                            console.log('target.value', event.target.value);
                            setHideAddTop({
                              id: page.id,
                              hideAddTop: !event.target.checked,
                            });
                          }}
                        />
                      )
                    }
                    label='top'
                  />
                  <FormControlLabel
                    control={
                      (
                        <Checkbox
                          checked={!page.hideAddBottom}
                          onChange={(event) => setHideAddBottom({
                            id: page.id,
                            hideAddBottom: !event.target.checked,
                          })}
                        />
                      )
                    }
                    label='bottom'
                  />
                  <Box>
                    <FindImageControl />
                  </Box>
                  <Box>
                    <TextToSpeechControls />
                  </Box>
                </FormGroup>
              </Box>
            </Box>
          </Box>
          <Box display='flex' flexDirection='column' className='tour-tree-view' pad='small'>
            <IconButton
              onClick={() => setTableView({ id: page.id, tableView: false })}
              size='small'
            >
              <TreeIcon />
            </IconButton>
          </Box>
          <Box className='tour-table-view' pad='small'>
            <IconButton
              onClick={() => setTableView({ id: page.id, tableView: true })}
              size='small'
            >
              <TableIcon />
            </IconButton>
          </Box>
          {page.tableView && (
            <FormGroup>
              <FormControlLabel
                control={
                  (
                    <Checkbox
                      checked={page.tableViewGroups}
                      onChange={(event) => setTableViewGroups({
                        id: page.id,
                        tableViewGroups: event.target.checked,
                      })}
                    />
                  )
                }
                label={<GroupIcon />}
              />
            </FormGroup>
          )}

          <Box>
            <Box display='flex' flexDirection='row' pad='small'>
              <IconButton
                aria-label='toggle show delete'
                onClick={() => setShowList(!showList)}
                size='small'
              >
                <Box display='flex' flexDirection='column'>
                  {!showList ? <OpenEyeIcon /> : <ClosedEyeIcon />}
                  <TaskIcon />
                </Box>
              </IconButton>

              <IconButton
                aria-label='toggle show delete'
                onClick={() => setShowApp(!showApp)}
                size='small'
              >
                <Box display='flex' flexDirection='column'>
                  {!showApp ? <OpenEyeIcon /> : <ClosedEyeIcon />}
                  <AppShortcutIcon />
                </Box>
              </IconButton>

              <IconButton
                aria-label='toggle show delete'
                onClick={() => putAndUpdate({
                  id: page.id,
                  hideDelete: !page.hideDelete,
                  type: SET_HIDE_DELETE,
                })}
                size='small'
              >
                <Box display='flex' flexDirection='column'>
                  {page.hideDelete ? <OpenEyeIcon /> : <ClosedEyeIcon />}
                  <DeleteIcon />
                </Box>
              </IconButton>

              <IconButton
                aria-label='toggle show checkmarks'
                onClick={() => putAndUpdate({
                  id: page.id,
                  hideCheckmark: !page.hideCheckmark,
                  type: SET_HIDE_CHECKMARK,
                })}
                size='small'
              >
                <Box display='flex' flexDirection='column'>
                  {page.hideCheckmark ? <OpenEyeIcon /> : <ClosedEyeIcon />}
                  <CheckmarkIcon />
                </Box>
              </IconButton>

              <IconButton
                aria-label='toggle show edit'
                onClick={() => putAndUpdate({
                  id: page.id,
                  hideEdit: !page.hideEdit,
                  type: SET_HIDE_EDIT,
                })}
                size='small'
              >
                <Box display='flex' flexDirection='column'>
                  {page.hideEdit ? <OpenEyeIcon /> : <ClosedEyeIcon />}
                  <EditIcon />
                </Box>
              </IconButton>

              <IconButton
                aria-label='toggle hide fields'
                onClick={() => putAndUpdate({
                  id: page.id,
                  hideFields: !page.hideFields,
                  type: SET_HIDE_FIELDS,
                })}
                size='small'
              >
                <Box display='flex' flexDirection='column'>
                  {page.hideFields ? <OpenEyeIcon /> : <ClosedEyeIcon />}
                  <ListIcon />
                </Box>
              </IconButton>

              <IconButton
                aria-label='toggle crumbs body'
                onClick={() => putAndUpdate({
                  id: page.id,
                  hideCrumbsBody: !page.hideCrumbsBody,
                  type: SET_HIDE_CRUMBS_BODY,
                })}
                size='small'
              >
                <Box display='flex' flexDirection='column'>
                  {page.hideCrumbsBody ? <OpenEyeIcon /> : <ClosedEyeIcon />}
                  <CrumbsBodyIcon />
                </Box>
              </IconButton>

              <IconButton
                aria-label='toggle crumbs tail'
                onClick={() => putAndUpdate({
                  id: page.id,
                  hideCrumbsTail: !page.hideCrumbsTail,
                  type: SET_HIDE_CRUMBS_TAIL,
                })}
                size='small'
              >
                <Box display='flex' flexDirection='column'>
                  {page.hideCrumbsTail ? <OpenEyeIcon /> : <ClosedEyeIcon />}
                  <CrumbsTailIcon />
                </Box>
              </IconButton>

            </Box>
          </Box>

        </Box>
      )}
    </Box>
  );
}

ViewSettings.propTypes = {
  showList: PropTypes.bool.isRequired,
  setShowList: PropTypes.func.isRequired,
  showApp: PropTypes.bool.isRequired,
  setShowApp: PropTypes.func.isRequired,
};

export default ViewSettings;
