// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import {
  DownloadIcon,
} from '../constants/icons';

function Download({ text, title }) {
  return (
    <DownloadIcon
      onClick={() => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
        const yyyy = today.getFullYear();
        const todayString = `${yyyy}-${mm}-${dd}`;
        const blob = new Blob([text], { type: 'text/plain;charset=utf-8' });
        saveAs(blob, `${todayString} ${title}.csv`);
      }}
    />
  );
}

Download.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Download;
