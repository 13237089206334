// (C) Copyright 2020 MediaWink, LLC

export const CLEAR_COOKIE_SAGA = 'CLEAR_COOKIE_SAGA';
export const CONNECT_SAGA = 'CONNECT_SAGA';
export const COMPRESS_DATABASE_SAGA = 'COMPRESS_DATABASE_SAGA';
export const CREATE_ACCOUNT_SAGA = 'CREATE_ACCOUNT_SAGA';
export const INJECT_ITEMS_INTO_REDUX_SAGA = 'INJECT_ITEMS_INTO_REDUX_SAGA';
export const LOGIN = 'LOGIN';
export const LOGIN_SAGA = 'LOGIN_SAGA';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_SAGA = 'LOGOUT_SAGA';
export const MIGRATE_DATABASE_SAGA = 'MIGRATE_DATABASE_SAGA';
export const RESET_PASSWORD_SAGA = 'RESET_PASSWORD_SAGA';
export const DELETE_ACCOUNT_SAGA = 'DELETE_ACCOUNT_SAGA';
export const SET_KEEP_SIGNED_IN = 'SET_KEEP_SIGNED_IN';
export const SET_AUTOMATION_SETTING_SAGA = 'SET_AUTOMATION_SETTING_SAGA';
export const SET_BACKUP_SETTING_SAGA = 'SET_BACKUP_SETTING_SAGA';
export const SET_ALLOWLIST_SETTING_SAGA = 'SET_ALLOWLIST_SETTING_SAGA';
export const SET_SETTING_SAGA = 'SET_SETTING_SAGA';
export const SET_LASTMODTIME = 'SET_LASTMODTIME';
export const SYNC_FROM_DATABASE_SAGA = 'SYNC_FROM_DATABASE_SAGA';
export const SWITCH_USER_SAGA = 'SWITCH_USER_SAGA';
