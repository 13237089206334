// (C) Copyright 2020 MediaWink, LLC

import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import undoable, { excludeAction } from 'redux-undo';
import { produce } from 'immer';

import createBreadcrumbsReducer from './breadcrumbs';
import dirty from './dirty';
import html from './html';
import settings from './settings';
import tour from './tour';
import createItemsReducer from './items';
import createNextIdReducer from './nextId';
import selectedId from './selectedId';
import selectedField from './selectedField';
import version from './version';
import login from './login';
import lastmodtime from './lastmodtime';
import visibilityFilter from './visibilityFilter';
import {
  UPDATE_ITEM_ID,
  ADJUST,
  SET_SELECTION,
  SET_NEXT_ID,
} from '../action-types/items';

const privateItemsReducer = createItemsReducer('PRIVATE');
const publicItemsReducer = createItemsReducer('PUBLIC');

const privateEntities = combineReducers({
  items: privateItemsReducer,
});

const publicEntities = combineReducers({
  items: publicItemsReducer,
});

const privateReducer = combineReducers({
  breadcrumbs: createBreadcrumbsReducer('PRIVATE'),
  dirty,
  entities: privateEntities,
  settings,
  tour,
  lastmodtime,
  login,
  nextId: createNextIdReducer('PRIVATE'),
  selectedId,
  selectedField,
  visibilityFilter,
  version,
});

const publicReducer = combineReducers({
  breadcrumbs: createBreadcrumbsReducer('PUBLIC'),
  dirty,
  entities: publicEntities,
  settings,
  tour,
  lastmodtime,
  login,
  nextId: createNextIdReducer('PUBLIC'),
  selectedId,
  selectedField,
  visibilityFilter,
  version,
});

const undo = combineReducers({
  private: privateReducer,
  public: publicReducer,
});

export default reduceReducers(
  combineReducers({
    html,
    undo: undoable(undo, {
      filter: excludeAction([
        SET_SELECTION,
        SET_NEXT_ID,
        ADJUST,
      ]),
    }),
  }),
  // cross-cutting concerns because here `state` is the whole state tree
  (state, action) => {
    switch (action.type) {
      // state.items and state.selectedID (updates this if needed)
      case UPDATE_ITEM_ID:
        return produce(state, (draftState) => {
          const { breadcrumbs: crumbs, oldId, newId } = action.data;
          draftState.undo.present.private.entities.items[newId] = {
            ...state.undo.present.private.entities.items[oldId],
            id: newId,
          };
          delete draftState.undo.present.private.entities.items[oldId];
          const { items: i } = draftState.undo.present.private.entities
            .items[crumbs[crumbs.length - 1]];
          draftState.undo.present.private.entities.items[crumbs[crumbs.length - 1]]
            .items = i.map((v) => (v === oldId ? newId : v));
          // IF old ID was selected, set selected to new ID
          // console.log('selectedId', state.undo.present.private.selectedId);
          if (state.undo.present.private.selectedId === oldId) {
            draftState.undo.present.private.selectedId = newId;
          }
        });
      default:
        return state;
    }
  },
);
