// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import {
  Box,
} from '@material-ui/core';

import SignOut from '../components/SignOut';

function SignOutScreen() {
  return (
    <Box
      align='center'
    >
      <h1>Sign Out</h1>
      <p>
        AchieveHub
      </p>
      <Box align='center'>
        Information entered while not signed in will not be saved.
        Note that if you are signed in to multiple accounts, this only signs you out
        of the currently selected account.
        <SignOut />
        <img alt='Wagon Wheel' src='android-chrome-192x192.png' />
      </Box>
    </Box>
  );
}

export default SignOutScreen;
