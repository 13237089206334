// (C) Copyright 2020 MediaWink, LLC

// https://stackoverflow.com/a/69592617
import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

function RequireAuth() {
  const loginState = useSelector((state) => state.undo.present.private.login);
  const location = useLocation();

  if (!loginState.isLoggedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to='/signin' state={{ from: location }} />;
  }

  return <Outlet />;
}

export default RequireAuth;
