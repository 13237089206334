// (C) Copyright 2020 MediaWink, LLC

// eg:
// https://policies.google.com/terms

import React from 'react';
import {
  Box,
  Typography,
} from '@material-ui/core';

function Terms() {
  return (
    <Box>
      <h1>Terms</h1>
      <Typography>
        These are the terms of use for AchiveHub.com.
      </Typography>
      <br />
      <Typography>
        Effective January 1, 2022
      </Typography>
      <h2>Warranty disclaimer</h2>
      <Typography>
        We built our reputation on providing useful, reliable tools and services.
        The law requires that we explain this using specific legal language and that we
        use capital letters to help make sure you see it, as follows:
      </Typography>
      <br />
      <Typography>
        TO THE EXTENT ALLOWED BY APPLICABLE LAW, WE PROVIDE OUR SERVICES “AS IS”
        WITHOUT ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING THE IMPLIED WARRANTIES
        OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
        FOR EXAMPLE, WE DON’T MAKE ANY WARRANTIES ABOUT THE CONTENT OR FEATURES OF THE SERVICES,
        INCLUDING THEIR ACCURACY, RELIABILITY, AVAILABILITY, OR ABILITY TO MEET YOUR NEEDS.
      </Typography>
    </Box>
  );
}

export default Terms;
