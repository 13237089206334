// (C) Copyright 2020 MediaWink, LLC

import axios from 'axios';

const PROTOCOL = window?.location?.hostname === 'localhost' ? 'http' : 'https';
// const PORT = window.location.hostname === 'localhost' ? ':8000' : '';
const PORT = ''; // window.location.hostname === 'localhost' ? ':8000' : '';
const URL = `${PROTOCOL}://${window.location.hostname}${PORT}/edsa-achievehub/api/AchieveHub.php`;

// endpoint root
// const root = "/";

export default class LayoutAPI {
  // static get = () => axios.get(root);
  // static edit = (payload) => axios.put(`${root}/edit/payload.id`, payload);
  static do(rquest, payload) {
    const request = `${URL}?rquest=${rquest}`;
    return axios.post(request, null, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: payload,
    });
  }
}
