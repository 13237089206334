// (C) Copyright 2020 MediaWink, LLC

// https://material-ui.com/components/menus/

import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Menu,
  MenuItem,
  ListItemText,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { MenuIcon } from '../constants';
import {
  selectMenuListItemsArray,
} from '../selectors';
import {
  triggerSetPublicBreadcrumbsSaga,
  triggerSetPrivateBreadcrumbsSaga,
} from '../actions/items';

function AppMenu({
  goTo,
  setShowApp,
  setShowList,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const setPublicBreadcrumbs = (breadcrumbsIn, index) => dispatch(
    triggerSetPublicBreadcrumbsSaga({ breadcrumbs: breadcrumbsIn, index }),
  );
  const setPrivateBreadcrumbs = (breadcrumbsIn, index) => dispatch(
    triggerSetPrivateBreadcrumbsSaga({ breadcrumbs: breadcrumbsIn, index }),
  );
  const publicBreadcrumbs = useSelector((state) => state.undo.present.public.breadcrumbs);
  const privateBreadcrumbs = useSelector((state) => state.undo.present.private.breadcrumbs);

  const items = useSelector(selectMenuListItemsArray);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    console.log('handleClose()');
    setAnchorEl(null);
  };

  const handleExit = async () => {
    console.log('handleExit()', window.location.pathname);
    await setShowApp(false);
    await setShowList(true);
    await setPrivateBreadcrumbs(privateBreadcrumbs.slice(0, 2));
    await setPublicBreadcrumbs(publicBreadcrumbs.slice(0, 2));
    navigate(`/${window.location.pathname.split('/')[1]}`);
  };
  return (
    <div>
      <Button
        aria-controls='customized-menu'
        aria-haspopup='true'
        variant='contained'
        color='primary'
        onClick={handleClick}
      >
        <MenuIcon />
      </Button>
      <Menu
        id='customized-menu'
        anchorEl={anchorEl}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items && items.length && items[0] && items.map((i) => (
          <MenuItem key={i?.id} onClick={handleClose}>
            <ListItemText
              onClick={() => {
                goTo(i.Name.value);
              }}
              primary={i?.Name?.value}
            />
          </MenuItem>
        ))}
        <MenuItem key='Exit' onClick={handleExit}>
          Exit
        </MenuItem>
      </Menu>
    </div>
  );
}

AppMenu.propTypes = {
  goTo: PropTypes.func.isRequired,
  setShowApp: PropTypes.func.isRequired,
  setShowList: PropTypes.func.isRequired,
};

export default AppMenu;
