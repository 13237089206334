// (C) Copyright 2020 MediaWink, LLC

import {
  IMPORT_JSON,
  SET_SELECTION,
} from '../action-types';

const selectedId = (state = 0, action = null) => {
  // console.log({ state, action });
  switch (action.type) {
    case IMPORT_JSON:
      return null;
    case SET_SELECTION:
      return action.id;
    default:
      return state;
  }
};

export default selectedId;
