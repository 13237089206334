// (C) Copyright 2020 MediaWink, LLC

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Box,
  TextField,
} from '@material-ui/core';
import {
  setSelectedField,
} from '../actions/items';

function EditField({
  item,
  keyName,
  setSelection,
  selectedField,
  updateField,
}) {
  const dispatch = useDispatch();
  const unsetSelection = () => dispatch(setSelectedField(null));
  const [name, setName] = useState(keyName);
  const updateFieldValue = (i, field, value, keys) => {
    if (!value.trim()) {
      return;
    }
    const { [field]: remove, ...rest } = i;
    const payload = {
      'item_id': i.id,
      'Name': i.Name.value,
      'item': {
        ...rest,
        'Name': {
          'type': 'Text',
          'value': i.Name.value.trim(),
        },
        ...keys,
        [value]: i[field],
      },
    };
    updateField(payload);
  };
  const [value, setValue] = useState({
    [keyName]: item[keyName],
  });
  useEffect(() => {
    setValue({
      ...value,
      [keyName]: {
        ...value[keyName],
        ...item[keyName],
        type: item[keyName]?.type,
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, keyName]);
  return (
    <Box>
      <Box>
        { selectedField === keyName && keyName !== 'Name'
          ? (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                updateFieldValue(item, keyName, name.trim());
              }}
            >
              <TextField
                style={{
                  padding: '0px',
                  color: 'dark-3',
                  textDecoration: item.completed ? 'line-through' : 'none',
                }}
                type='text'
                className='input'
                onSelect={() => {
                  setValue(name);
                }}
                onBlur={() => {
                  // updateItemValue(item, value);
                  // console.log('onBlur');
                  unsetSelection();
                }}
                value={name}
                onChange={(e) => {
                  e.preventDefault();
                  setName(e.target.value);
                }}
              />
            </form>
          )
          : (
            <Box
              onClick={keyName !== 'Name' ? setSelection : undefined}
            >
              {keyName}
            </Box>
          )}
      </Box>
    </Box>
  );
}

EditField.propTypes = {
  item: PropTypes.object.isRequired,
  keyName: PropTypes.string.isRequired,
  setSelection: PropTypes.func.isRequired,
  selectedField: PropTypes.string.isRequired,
  updateField: PropTypes.func.isRequired,
};

export default EditField;
