// (C) Copyright 2020 MediaWink, LLC

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  TextField,
} from '@material-ui/core';
import {
  addFieldAsync,
  triggerSetFirstVisibleColumnSaga,
} from '../actions/items';
import {
  selectPrivateBreadcrumbs,
  selectSelectedItem,
  selectSuggestedNewNameForItem,
  selectDispensersSuggestions,
  selectNextId,
  selectPageType,
} from '../selectors';

function AddItem({
  func,
  parent,
  group,
}) {
  const breadcrumbs = useSelector((state) => selectPrivateBreadcrumbs(state));
  const suggestedNewNameForId = useSelector(
    (state) => (ids) => selectSuggestedNewNameForItem(state, ids),
  );
  const getSelectedItem = useSelector((state) => () => selectSelectedItem(state));
  const login = useSelector((state) => state.undo.present.private.login);
  const type = useSelector((state) => selectPageType(state));
  const dispatch = useDispatch();
  const setFirstVisibleColumn = (data) => dispatch(
    triggerSetFirstVisibleColumnSaga(data),
  );
  const allSuggestions = useSelector(selectDispensersSuggestions);
  const nextId = useSelector(selectNextId);
  const [suggestions, setSuggestions] = React.useState(allSuggestions.slice(0, 30));
  const [value, setValue] = useState('');
  const addField = (payload, resolve, reject) => dispatch(addFieldAsync(payload, resolve, reject));

  const onSubmit = (e) => {
    // console.log({ e });
    let newValue = value;
    e.preventDefault();
    if (!value.trim()) {
      return;
    }

    if (value[0] !== '"' && value[0] !== ' ') {
      let separator = '';
      const SEPERATORS = [':'];
      // const SEPERATORS = [];
      SEPERATORS.forEach((s) => {
        if (value.includes(s)) {
          separator = s;
        }
      });
      if (separator) {
        console.log('Detected separator', separator);
        const [k, ...vArray] = value.split(separator);
        const v = vArray.join(':');
        addField({
          id: parent.id,
          name: k.trim(),
          value: v.trim(),
          keys: [],
          type: 'text',
        });
        setValue('');
        return;
      }
    } else {
      newValue = newValue.trim();
      if (newValue[0] === '"' && newValue[newValue.length - 1] === '"') {
        newValue = newValue.slice(1, newValue.length - 1);
      }
    }

    const tail = breadcrumbs.slice(-1) !== [parent.id] ? [parent.id] : undefined;
    const newCrumbs = [
      ...breadcrumbs,
      ...(tail || []),
    ];
    const data = {
      ...login,
      item_id: nextId,
      parent: parent.id,
      breadcrumbs: newCrumbs,
      nextId,
      value: newValue,
      item: {
        Name: {
          type: 'Text',
          value: newValue,
        },
        type: type === 'All' ? value : type,
        group,
      },
    };
    func(data);
    setValue('');
    setFirstVisibleColumn({
      id: parent.id,
      firstVisibleColumn: parent.items.length - 1 - parent.columnsPerPage + 1,
    });
  };
  const onChange = (event) => {
    const { value: v } = event.target;
    const lastChar = v[v.length - 1];
    setValue(v);
    if (lastChar === '\n') {
      onSubmit(event);
      return;
    }
    if (!v) {
      setSuggestions(allSuggestions);
    } else {
      try {
        const regexp = new RegExp(`^${v}`);
        setSuggestions(allSuggestions.filter((s) => regexp.test(s)));
      } catch (e) {
        setSuggestions(allSuggestions);
      }
    }
  };
  return (
    <form onSubmit={onSubmit}>
      <Box display='flex' flexDirection='row' m={1}>
        <TextField
          multiline={true}
          className='input'
          onChange={onChange}
          onClick={() => {
            const selectedItem = getSelectedItem();
            const newName = suggestedNewNameForId(parent);
            setValue(newName);
            try {
              // https://stackoverflow.com/a/4067488/11962478
              if (!selectedItem) {
                setTimeout(() => {
                  document.execCommand('selectall', null, false);
                }, 100);
              }
            } catch (e) {
              console.warn(e);
            }
          }}
          placeholder='Add item'
          suggestions={suggestions}
          type='text'
          value={value}
        />
      </Box>
    </form>
  );
}

AddItem.propTypes = {
  func: PropTypes.func.isRequired,
  group: PropTypes.number,
  item: PropTypes.shape({
    setNextId: PropTypes.func.isRequired,
    func: PropTypes.func,
    // addItemToTopAsync: PropTypes.func,
    breadcrumbs: PropTypes.array.isRequired,
    updateItemId: PropTypes.func,
  }),
  parent: PropTypes.object.isRequired,
};

AddItem.defaultProps = {
  group: undefined,
  item: undefined,
};

export default AddItem;
