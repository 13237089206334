// (C) Copyright 2020 MediaWink, LLC

import {
  IMPORT_JSON,
  SET_VISIBILITY_FILTER,
} from '../action-types';
import { VisibilityFilters } from '../actions/items';

const visibilityFilter = (state = VisibilityFilters.SHOW_ALL, action = null) => {
  switch (action.type) {
    case IMPORT_JSON:
      return JSON.parse(action.json).private.visibilityFilter;
    case SET_VISIBILITY_FILTER:
      return action.filter;
    default:
      return state;
  }
};

export default visibilityFilter;
