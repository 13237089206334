// (C) Copyright 2020 MediaWink, LLC

import React from 'react';

import {
//    BlockQuote as BlockQuoteIcon,
//     Calendar as CalendarIcon,
  ListIcon,
  CheckboxIcon,
  TaskIcon,
} from './icons';

export const TYPE_ICONS = {
  'date': undefined, // <CalendarIcon />,
  'list': <ListIcon />,
  'selection': <TaskIcon />,
  'text': undefined, // <BlockQuoteIcon />,
  'yesno': <CheckboxIcon />,
};

export const TYPE_DEFAULT_VALUES = {
  'date': undefined,
  'list': [],
  'selection': [],
  'text': '',
  'yesno': undefined,
};

export const DEFAULT_BREADCRUMBS = [1];

export const DEFAULT_SETTINGS = {
  'backups': false,
};

export const DEFAULT_FIELDS = {
  'Contacts': {
    // 'Name': '', // Added dynamically
    /*
    'Attending Reunion': {
      value: null, // bool
      type: 'YesNo',
    },
    */
    'Cell Number': {
      value: '',
      type: 'Text',
    },
    /*
    'Arriving': {
      value: '',
      type: 'Date',
    },
    */
    'Email': {
      value: '',
      type: 'Text',
    },
    /*
    'T-shirt Size': {
      value: '',
      type: 'Text',
    }
    */
    // 'Attending Reunion in ${CURRENT_YEAR}': undefined, // bool
  },
  'Items': {
    id: -1,
    order: 1, // top
    items: [],
  },
  'Tasks': {
    // 'Name': '', // Added dynamically
    'Description': {
      value: '',
      type: 'Text',
    },
    'Completed': {
      value: false,
      type: 'YesNo',
    },
    'Due Date': {
      value: '',
      type: 'Date',
    },
    'Complete Date': {
      value: '',
      type: 'Date',
    },
  },
  /*
  Email:
   e.g. jesse.d.olsen@gmail.com
   base: Text => String
   constraints/re: [A-Za-z0-9...]@[]
    * letters = A-Za-z*
    * numbers = 0-9*
    * ...
   minSize: 3
   maxSize: undefined or 128
  */
  'Types': {
    // Name: '', // dyanmic--e.g. T-shirt Sizes (Must be capital first letter)
    'Type': {
      value: '',
      type: 'Type',
      values: ['date', 'list', 'selection', 'text', 'yesno'],
    },
    'Default value': {
      value: null,
      type: 'Text',
    },
    'Description': {
      value: '', // e.g. What size T-shirt they wear
      type: 'Text',
    },
    // For LISTS ONLY:
    'Values': {
      value: '', // e.g. "Adult Large, Adult Medium, Adult Small"
      type: 'list',
    },
    'Bound': {
      value: null, // bool - e.g. can they enter any text instead of something in the list?
      type: 'YesNo',
    },
  },
};
