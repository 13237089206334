// (C) Copyright 2020 MediaWink, LLC

import React, { useContext } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
} from '@material-ui/core';
import { triggerPopPrivateBreadcrumbSaga, triggerPopPublicBreadcrumbSaga } from '../actions/items';
import { Context } from './Context';
import {
  selectPrivateBreadcrumbs,
  selectPublicBreadcrumbs,
  selectIsPrivate,
  selectBreadcrumbs,
  selectItemsForBreadcrumbs,
} from '../selectors';

const DIV = '/'; // was '≫';
const TOP = 'All';
const GRID = 2;
function BreadcrumbsLinks({ debug }) {
  const [context] = useContext(Context);
  const navigate = useNavigate();
  const privateBreadcrumbs = useSelector(selectPrivateBreadcrumbs);
  const publicBreadcrumbs = useSelector(selectPublicBreadcrumbs);
  const isPrivate = useSelector(selectIsPrivate);
  const breadcrumbs = useSelector(selectBreadcrumbs);
  // console.log('breadcrumbs', breadcrumbs);
  const items = useSelector(selectItemsForBreadcrumbs);
  const dispatch = useDispatch();
  const popPrivateBreadcrumb = (breadcrumbsIn, index, nav, navigateIn) => dispatch(
    triggerPopPrivateBreadcrumbSaga({
      breadcrumbs: breadcrumbsIn, index, nav, navigate: navigateIn,
    }),
  );
  const popPublicBreadcrumb = (breadcrumbsIn, index, nav, navigateIn) => dispatch(
    triggerPopPublicBreadcrumbSaga({
      breadcrumbs: breadcrumbsIn, index, nav, navigate: navigateIn,
    }),
  );
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: GRID * 2,
    margin: `0 ${GRID}px 0 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'floralwhite',
    alignItems: 'center',

    // styles we need to apply on draggables
    ...draggableStyle,
  });
  // console.log('items', items);
  const itemsLeft = items.length > 1 ? items.slice(0, -1) : items;
  // console.log('itemsLeft', itemsLeft);
  const navs = [];
  navs[0] = '';
  return (
    <Box
      display='flex'
      flexDirection='row'
      alignItems='center'
      style={{ flexWrap: 'wrap' }}
    >
      {itemsLeft
        .filter((i) => i !== undefined)
        .map((crumb, index) => {
          if (index > 0) {
            navs[index] = navs[index - 1] + DIV + (crumb?.Name?.value || '');
          }
          // console.log({ crumb, index });
          // const adjustedIndex = index > 1 ? index - 1 : index;
          if (!crumb) {
            return undefined;
          }
          // console.log({ crumb, index, navs });
          return (
            <React.Fragment key={crumb.id}>
              <Draggable
                key={crumb.id}
                draggableId={String(crumb.id)}
                alignItems='center'
                index={index}
              >
                {(dragProvided, dragSnapshot) => (
                  <Box
                    sx={{ flexWrap: 'wrap' }}
                    m={0}
                    p={0}
                    ref={dragProvided.innerRef}
                    justify='center'
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...dragProvided.draggableProps}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...dragProvided.dragHandleProps}
                    style={getItemStyle(
                      dragSnapshot.isDragging,
                      dragProvided.draggableProps.style,
                    )}
                  >
                    <Button
                      m={0}
                      p={0}
                      justify='center'
                      style={{
                        textTransform: 'none',
                        padding: 0,
                        margin: 0,
                      }}
                      href={navs[index]}
                      onClick={(e) => {
                        e.preventDefault();
                        context.setShowApp(false);
                        context.setShowList(true);
                        if (privateBreadcrumbs.length === publicBreadcrumbs.length) {
                          // console.log('isPrivate, equal lengths, popPublic too');
                          popPublicBreadcrumb(breadcrumbs, index, navs[index] || DIV, navigate);
                          // console.log('!isPrivate, equal lengths, popPrivate too');
                          popPrivateBreadcrumb(breadcrumbs, index, navs[index] || DIV, navigate);
                        } else if (isPrivate) {
                          // console.log('isPrivate');
                          popPrivateBreadcrumb(breadcrumbs, index, navs[index] || DIV, navigate);
                        } else {
                          // console.log('!isPrivate');
                          popPublicBreadcrumb(breadcrumbs, index, navs[index] || DIV, navigate);
                        }
                        return false;
                      }}
                    >
                      {index > 0 ? crumb.Name.value : TOP}
                      {debug && ` {${crumb.id}}`}
                    </Button>
                  </Box>
                )}
              </Draggable>
              {breadcrumbs.length > 1 && DIV}
            </React.Fragment>
          );
        })}
    </Box>
  );
}

BreadcrumbsLinks.propTypes = {
  debug: PropTypes.bool.isRequired,
};
export default BreadcrumbsLinks;
