// (C) Copyright 2020 MediaWink, LLC

import {
  EXPORT_SAGA,
} from '../action-types/exports';

// Trigger sagas
export const triggerExportSaga = (resolve, reject, navigate) => ({
  type: EXPORT_SAGA, resolve, reject, navigate,
});
