// (C) Copyright 2020 MediaWink, LLC

import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Camera
import {
  Button,
} from '@mui/material';
import Camera, { FACING_MODES } from './Camera';
// import 'react-html5-camera-photo/build/css/index.css';

// import './App.css';

function Photo({ handler }) {
  const [showCamera, setShowCamera] = useState(false);
  const [imageData, setImageData] = useState(null);
  // Camera
  function handleTakePhoto(dataUri) {
    // Do stuff with the photo...
    console.log('handleTakePhoto()');
    setImageData(/* imageDataUri */ dataUri);
    handler(dataUri);
  }

  function handleImageChange(e) {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageDataUri = reader.result;
        // console.log({ imageDataUri });
        setImageData(imageDataUri);
        handler(imageDataUri);
      };
      reader.readAsDataURL(file);
    }
  }
  const clickHandler = () => {
    setShowCamera(!showCamera);
  };
  return (
    <div className='Photo'>
      <div>
        <p>Upload an image or take a picture</p>
        <input
          type='file'
          name=''
          id=''
          onChange={handleImageChange}
          accept='image/*'
        />
        <Button
          variant='contained'
          style={{
            textTransform: 'none',
          }}
          onClick={clickHandler}
        >
          {showCamera ? 'Hide Camera' : 'Show Camera'}
        </Button>
      </div>
      {showCamera
        && (
        <>
          <Camera
            idealFacingMode={FACING_MODES.ENVIRONMENT}
            onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
          />
          {imageData && (
          <div className='display-flex'>
            <img
              alt='Snapshot'
              src={imageData}
              style={{
                width: '100%',
              }}
              srcSet=''
            />
          </div>
          )}
        </>
        )}
    </div>
  );
}

Photo.propTypes = {
  handler: PropTypes.func,
};

Photo.defaultProps = {
  handler: () => { },
};

export default Photo;
