// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
} from '@material-ui/core';
import ItemList from './ItemList';
import AddItemToBottom from '../containers/AddItemToBottom';
import AddItemToTop from '../containers/AddItemToTop';

function ItemListWithAdd({
  debug,
  item,
  publicItems,
  privateItems,
  group,
  hideAddBottom,
  hideAddTop,
  hideDelete,
  hideEdit,
  hideCheckmark,
}) {
  return (
    <Box className='item-list'>
      { !hideAddTop && !(!hideAddBottom && Object.keys(privateItems).length < 1)
      && (
      <Box>
        <Box>
          <AddItemToTop
            parent={item}
            group={group}
          />
        </Box>
        {/* <UndoRedo /> */}
      </Box>
      )}

      <ItemList
        debug={debug}
        publicItems={publicItems}
        privateItems={privateItems}
        hideDelete={hideDelete}
        hideEdit={hideEdit}
        hideCheckmark={hideCheckmark}
      />

      { !hideAddBottom
      && (
      <Box>
        <Box className='tour-add-item'>
          <AddItemToBottom
            parent={item}
            group={group}
          />
        </Box>
        {/* <UndoRedo /> */}
      </Box>
      )}
    </Box>
  );
}

ItemListWithAdd.propTypes = {
  debug: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  publicItems: PropTypes.object.isRequired,
  privateItems: PropTypes.object.isRequired,
  group: PropTypes.number,
  hideAddBottom: PropTypes.bool,
  hideAddTop: PropTypes.bool,
  hideDelete: PropTypes.bool,
  hideEdit: PropTypes.bool,
  hideCheckmark: PropTypes.bool,
};

ItemListWithAdd.defaultProps = {
  group: 0,
  hideAddBottom: false,
  hideAddTop: false,
  hideDelete: false,
  hideEdit: false,
  hideCheckmark: false,
};

export default ItemListWithAdd;
